import { memo } from "react";

import { Paragraph } from "@web/ui";
import { formatMoney, isDefined } from "@web/utils";

import {
  StocktakeReportItemEntityQuantityFieldPath,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";

import { useItemFormDetails } from "../hooks";

type Props = {
  initialEntityQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  currencyCode: string;
  entityQuantityFieldPath: StocktakeReportItemEntityQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
};

export const StocktakeProductTileRobValueDetails = memo(
  ({
    initialEntityQuantity,
    initialSingleUnitGrossPriceAmount,
    initialMeasurementUnit,
    currencyCode,
    entityQuantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
  }: Props) => {
    const { modifiedProductRobValue } = useItemFormDetails({
      initialEntityQuantity,
      initialSingleUnitGrossPriceAmount,
      initialMeasurementUnit,
      currencyCode,
      entityQuantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    });

    return (
      <>
        <Paragraph size="200" weight="heavy" color="text-textIcon-blackSecondary">
          Value:
        </Paragraph>
        <Paragraph size="200" weight="heavy" className="whitespace-nowrap">
          {isDefined(modifiedProductRobValue)
            ? formatMoney(modifiedProductRobValue)
            : "Not Provided"}
        </Paragraph>
      </>
    );
  }
);
StocktakeProductTileRobValueDetails.displayName = "StocktakeProductTileRobValueDetails";
