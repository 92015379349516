import { SkuCareAndUse } from "@web/common/models";
import { Label, Paragraph } from "@web/ui";

export const Description: React.FC<{
  label: string;
  value?: string | number;
}> = ({ label, value }) => {
  if (!value) {
    return null;
  }
  return (
    <div className="py-4 items-baseline">
      <div className="uppercase pb-1">
        <Label size="300" color="text-textIcon-blackSecondary">
          {label}
        </Label>
      </div>
      <Paragraph size="200" color="text-textIcon-blackSecondary">
        {value}
      </Paragraph>
    </div>
  );
};

export const Row: React.FC<{
  label: string;
  value?: boolean | string | number;
}> = ({ label, value }) => {
  if (!value) {
    return null;
  }
  return (
    <div className="flex justify-between pt-4 pb-2 border-b items-baseline border-neutral_300">
      <div className="uppercase">
        <Label size="300">{label}</Label>
      </div>
      <Label size="200" color="text-textIcon-blackSecondary">
        {String(value)}
      </Label>
    </div>
  );
};

export const RowGroup: React.FC<{
  title?: string;
  rows?: Record<string, boolean | number | string | undefined>;
}> = ({ title, rows }) => {
  if (!rows) {
    return null;
  }

  const rowGroupHasAnyValue = Object.values(rows).some((r) => Boolean(r));

  if (!rowGroupHasAnyValue) {
    return null;
  }

  return (
    <div>
      {!!title && (
        <div className="pt-6 pb-2">
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            {title}
          </Paragraph>
        </div>
      )}
      <>
        {Object.entries(rows).map(([key, value]) => {
          return <Row key={key} label={key} value={value} />;
        })}
      </>
    </div>
  );
};

export function getCareAndUsesRows(care: SkuCareAndUse) {
  const careAndUseRows: Record<string, string> = {};

  if (care.allergicIngredients) {
    careAndUseRows["Allergy Information"] = care.allergicIngredients;
  }
  if (care.alcoholPercentage) {
    careAndUseRows["Alcohol Percentage"] = `${care.alcoholPercentage}%`;
  }
  if (care.isHalal) {
    careAndUseRows["halal"] = "Yes";
  }
  if (care.isKosher) {
    careAndUseRows["kosher"] = "Yes";
  }
  if (care.isVegan) {
    careAndUseRows["vegan"] = "Yes";
  }
  if (care.isVegetarian) {
    careAndUseRows["vegetarian"] = "Yes";
  }

  return careAndUseRows;
}
