import {
  AttentionInfoItems,
  LocalLitePort,
  OrderTypeConfiguration,
  PortConfiguration,
  SupplierConfiguration,
} from "src/state/models";
import {
  LitePort as ApiPort,
  LiteAttentionInfoFleetsConfigEntry,
  LiteConfiguration,
  LiteOrderTypeConfiguration,
  LitePortConfiguration,
} from "src/typegens";

const LEGACY_toPorts = (
  ports: Record<string, Array<ApiPort>>,
  portConfiguration: Record<string, LitePortConfiguration>,
  attentionInfoConfiguration: LiteAttentionInfoFleetsConfigEntry[]
): Record<string, Array<LocalLitePort>> => {
  return Object.entries(ports).reduce((acc, [key, val]) => {
    return {
      ...acc,
      [key]: val.map((port) => {
        return {
          ...port,
          freeDelivery: portConfiguration?.[port.locationCode]?.freeDelivery,
          agentInformation: portConfiguration?.[port.locationCode]?.agentInformation,
          dutyFree: portConfiguration?.[port.locationCode]?.dutyFree,
          attentionInfo: LEGACY_getAttentionInfoItemsForPort(attentionInfoConfiguration, port),
          _search: `${port.locationCode} ${port.name}`,
        };
      }),
    };
  }, {});
};

const convertApiAttentionInfoItemsToLocalAttentionInfoItems = (
  attentionInfoItems: LiteAttentionInfoFleetsConfigEntry[] = []
): AttentionInfoItems =>
  attentionInfoItems.reduce((acc: AttentionInfoItems, cur: LiteAttentionInfoFleetsConfigEntry) => {
    const existingTypeEntry = acc.find((item) => item.type === cur.attentionInfoType);
    if (existingTypeEntry) {
      // Mutate element in `acc` array
      existingTypeEntry.entries.push({
        fileMetadata: cur.fileMetadata,
        imageMetadata: cur.imageMetadata,
      });
    } else {
      // Mutate `acc` array
      acc.push({
        type: cur.attentionInfoType,
        entries: [
          {
            fileMetadata: cur.fileMetadata,
            imageMetadata: cur.imageMetadata,
          },
        ],
      });
    }
    return acc;
  }, []);

const LEGACY_getAttentionInfoItemsForPort = (
  attentionInfoConfiguration: LiteAttentionInfoFleetsConfigEntry[],
  port: ApiPort
): AttentionInfoItems => {
  return convertApiAttentionInfoItemsToLocalAttentionInfoItems(
    attentionInfoConfiguration.filter((entry) =>
      entry.portLocationCodes.includes(port?.locationCode || "")
    )
  );
};

export const LocalConfigurationService = {
  canCreateRequisition: (configuration?: LiteConfiguration) => {
    if (!configuration) {
      return false;
    }

    return configuration.fleet.permissions.some(
      (permission) => permission === "CREATE_REQUISITION"
    );
  },
  canCreateOrder: (configuration?: LiteConfiguration) => {
    if (!configuration) {
      return false;
    }

    return (
      configuration.fleet.permissions.includes("CREATE_ORDER") ||
      configuration.fleet.permissions.includes("CREATE_AUTO_APPROVED_REQUISITION")
    );
  },
  isAutoApprovedRequisition: (configuration?: LiteConfiguration) => {
    if (!configuration) {
      return false;
    }

    return configuration.fleet.permissions.includes("CREATE_AUTO_APPROVED_REQUISITION");
  },
  LEGACY_toPorts,
  convertApiOrderTypeConfigsToLocalOrderTypeConfigs: (
    orderTypeConfigs: LiteOrderTypeConfiguration[]
  ): OrderTypeConfiguration[] =>
    orderTypeConfigs.map((orderTypeConfig) => ({
      ...orderTypeConfig,
      ports: orderTypeConfig.ports.map((portConfig) => ({
        ...portConfig,
        _search: `${portConfig.locationCode} ${portConfig.name}`,
        suppliers: portConfig.suppliers.map((supplierConfig) => ({
          ...supplierConfig,
          attentionInfo: convertApiAttentionInfoItemsToLocalAttentionInfoItems(
            supplierConfig.attentionInfo
          ),
        })),
      })),
    })),
  getSelectedOrderTypeConfig: ({
    orderTypeConfigs = [],
    orderType,
  }: {
    orderTypeConfigs: OrderTypeConfiguration[] | undefined;
    orderType: OrderTypeConfiguration["type"] | undefined;
  }): OrderTypeConfiguration | undefined =>
    orderTypeConfigs.find((orderTypeConfig) => orderTypeConfig.type === orderType),
  getSelectedPortConfig: ({
    orderTypeConfigs = [],
    orderType,
    portId,
  }: {
    orderTypeConfigs: OrderTypeConfiguration[] | undefined;
    orderType: OrderTypeConfiguration["type"] | undefined;
    portId: string | undefined;
  }): PortConfiguration | undefined =>
    orderTypeConfigs
      .find((orderTypeConfig) => orderTypeConfig.type === orderType)
      ?.ports.find((portConfig) => portConfig.id === portId),
  getSelectedSupplierConfig: ({
    orderTypeConfigs = [],
    orderType,
    portId,
    supplierId,
  }: {
    orderTypeConfigs: OrderTypeConfiguration[] | undefined;
    orderType: OrderTypeConfiguration["type"] | undefined;
    portId: string | undefined;
    supplierId: string | undefined;
  }): SupplierConfiguration | undefined =>
    orderTypeConfigs
      .find((orderTypeConfig) => orderTypeConfig.type === orderType)
      ?.ports.find((portConfig) => portConfig.id === portId)
      ?.suppliers.find((supplierConfig) => supplierConfig.id === supplierId),
};
