import DeleteOutline from "@mui/icons-material/DeleteOutline";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { Heading, MuiIcon, Paragraph, RegularButton } from "@web/ui";
import { formatDate } from "@web/utils";

type Props = {
  onClose: () => void;
  onContinueOffline: () => void;
  catalogLastUpdated: string;
};

export const OldCatalogDataModal = ({ onClose, onContinueOffline, catalogLastUpdated }: Props) => {
  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg p-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="flex items-center justify-between gap-2">
        <div className="flex-none flex items-center justify-center p-1 rounded-full border bg-primaryBackground">
          <MuiIcon Icon={InfoOutlined} className="w-5 text-primaryDefault" aria-hidden="true" />
        </div>
        <Heading size="200" className="flex-1">
          No internet connection detected
        </Heading>
        <button
          type="button"
          className="flex-none self-start bg-neutral_0 rounded-md text-textIcon-blackSecondary hover:text-textIcon-blackPrimary"
          onClick={onClose}
        >
          <span className="sr-only">Close modal</span>
          <MuiIcon Icon={DeleteOutline} className="w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-5">
        <div className="flex flex-col gap-4">
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            You are offline, so we cannot provide you with the latest catalog of products.
          </Paragraph>
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            The chosen catalog was last updated on your device on {formatDate(catalogLastUpdated)}.
          </Paragraph>
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            Since then, some products may have become unavailable or their prices may have changed.
            This will be verified when you place your order.
          </Paragraph>
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            We recommend updating the catalog when you have an internet connection to see the latest
            prices and item availability.
          </Paragraph>
          <Paragraph size="200" color="text-textIcon-blackSecondary">
            By clicking the &quot;Understood&quot; button, you will start browsing the non-updated
            catalog.
          </Paragraph>
        </div>
      </div>
      <div className="mt-5 flex flex-row-reverse">
        <RegularButton
          variant="primary"
          size="large"
          className="ml-2"
          label="Understood"
          onClick={onContinueOffline}
        />
      </div>
    </div>
  );
};
