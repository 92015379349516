import { s2sDatabase } from "src/objectStorage";
import { LiteOrderTypeConfiguration } from "src/typegens";
import { getCompositeStoredCatalogId } from "src/utils";

export const OfflineCatalogService = {
  getOfflineCatalogListMetaData: async (): Promise<Map<string, { updatedAt: string }>> => {
    const offllineCatalogMetaData = new Map<string, { updatedAt: string }>();
    try {
      const availableCatalogData = await s2sDatabase.categoriesByPortId.toArray();

      for (const catalog of availableCatalogData) {
        offllineCatalogMetaData.set(catalog.portId, { updatedAt: catalog.updatedAt });
      }

      return offllineCatalogMetaData;
    } catch (err) {
      return offllineCatalogMetaData;
    }
  },
  getHasOfflineCatalogData: async ({
    portId,
    orderType,
    supplierId,
  }: {
    portId: string;
    orderType: LiteOrderTypeConfiguration["type"];
    supplierId: string;
  }): Promise<boolean> => {
    const categories = await s2sDatabase.categoriesByPortId.get(
      getCompositeStoredCatalogId({ portId, orderType, supplierId })
    );

    return !!(categories && !!categories?.updatedAt);
  },
};
