import { useCallback, useMemo } from "react";

import { useWindowDimensions } from "@web/utils";

import { ChunkedItemsList } from "src/components/ChunkedItemsList";
import { StocktakeFormOldExtraProductTile } from "src/components/product-tile/stocktake/StocktakeFormOldExtraProductTile";
import { LocalStocktakeReportOldExtraItemForm } from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

type Props = {
  extraItems: Array<LocalStocktakeReportOldExtraItemForm>;
};

const XL_BREAKPOINT = 1280;
const CHUNK_SIZE = 10;
// Remember to adjust `ITEMS_GAP` when changing the product tiles spacing.
const ITEMS_GAP = 8;

export const StocktakeOldExtraItemsList = ({ extraItems }: Props) => {
  const chunkedItems = useMemo(
    () => LocalStocktakeService.getChunkedCollection(extraItems, CHUNK_SIZE),
    [extraItems]
  );

  const renderOldExtraItem = useCallback(
    (extraItem: LocalStocktakeReportOldExtraItemForm & { index: number }) => (
      <StocktakeFormOldExtraProductTile
        key={extraItem.id}
        product={extraItem}
        indexInCollection={extraItem.index}
      />
    ),
    []
  );

  // ---------------------------------------------------------------------------

  const { width } = useWindowDimensions();

  // Postpone rendering until width is set to avoid costly rerenders
  if (!width) {
    return null;
  }

  /**
   * Remember to adjust `estimatedItemHeightInPx` when changing the product tiles size.
   * `ChunkedItemsList` does not support window resizing, so the window needs
   * to be refreshed to apply the new values after resizing.
   */
  return (
    <ChunkedItemsList
      className="flex flex-col gap-2"
      chunkWrapperClassName="flex flex-col gap-2"
      chunkedItems={chunkedItems}
      estimatedItemHeightInPx={width < XL_BREAKPOINT ? 99 : 58}
      itemsGapInPx={ITEMS_GAP}
      renderChunkItem={renderOldExtraItem}
    />
  );
};
