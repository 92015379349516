import { useQuery } from "@tanstack/react-query";

import { OfflineCatalogService } from "src/services/OfflineCatalogService";
import { LiteOrderTypeConfiguration } from "src/typegens/models/LiteOrderTypeConfiguration";

export const useHasOfflineCatalogDataQuery = ({
  portId,
  orderType,
  supplierId,
}: {
  portId: string;
  orderType: LiteOrderTypeConfiguration["type"];
  supplierId: string;
}) => {
  return useQuery<boolean>({
    queryKey: ["offlineCatalogDownloadInfo"],
    queryFn: async () => {
      return await OfflineCatalogService.getHasOfflineCatalogData({
        portId,
        orderType,
        supplierId,
      });
    },
    gcTime: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};
