// TODO #11548: Add unit tests

/**
 * @example
 * readFileAsText(blob, "utf-16");
 */
export const readFileAsText = (file: File | Blob, encoding?: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      if (typeof reader.result === "string") {
        resolve(reader.result);
      }
      reject(new Error(`Expected result to be a string, but got ${typeof reader.result}`));
    });

    reader.addEventListener("error", (error) => {
      reject(error);
    });

    reader.readAsText(file, encoding);
  });
};
