import { PropsWithChildren, useCallback, useEffect, useState } from "react";

import { useOfflineCapabilities } from "../OfflineCapabilities";
import { NetworkDetectorContext } from "./NetworkDetectorContext";
import { networkStatePinger } from "./NetworkStatePinger";
import { useNetworkDetectorReducer } from "./reducer";

export const NetworkDetectorProvider = ({ children }: PropsWithChildren) => {
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  // Start with `false`, so the checks don't start if the tab is loading in the background
  const [isDocumentVisible, setIsDocumentVisible] = useState<boolean>(false);
  // Start with `false` so the polling does not start until needed
  const [isCheckingNetworkState, setIsCheckingNetworkState] = useState<boolean>(false);

  const [networkDetectorState, dispatch] = useNetworkDetectorReducer();
  const { currentNetworkState } = networkDetectorState;

  const setOnline = useCallback(() => dispatch({ type: "setOnline" }), [dispatch]);
  const setOffline = useCallback(() => dispatch({ type: "setOffline" }), [dispatch]);
  const setShakyConnection = useCallback(
    () => dispatch({ type: "setShakyConnection" }),
    [dispatch]
  );
  const resetShakyConnection = useCallback(
    () => dispatch({ type: "resetShakyConnection" }),
    [dispatch]
  );

  const setDocumentVisibilityState = useCallback(() => {
    setIsDocumentVisible(document.visibilityState === "visible");
  }, [setIsDocumentVisible]);

  const setNavigatorNetworkState = useCallback(
    () => dispatch({ type: "setNavigatorOnline", value: navigator.onLine }),
    [dispatch]
  );

  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled) {
      return;
    }

    if (!isCheckingNetworkState) {
      return;
    }

    const subscription = networkStatePinger.result$.subscribe(({ isOnline, isShaky }) => {
      if (isOnline) {
        setOnline();
      } else {
        setOffline();
      }

      if (isShaky) {
        setShakyConnection();
      } else {
        resetShakyConnection();
      }
    });
    networkStatePinger.startPinging();

    return () => {
      networkStatePinger.stopPinging();
      subscription.unsubscribe();
    };
  }, [
    areOfflineCapabilitiesEnabled,
    isCheckingNetworkState,
    resetShakyConnection,
    setOffline,
    setOnline,
    setShakyConnection,
  ]);

  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled) {
      return;
    }

    // Only check for connectivity if the Document is visible
    if (isDocumentVisible) {
      setIsCheckingNetworkState(true);

      return;
    }

    // Disable checking if the Document is not visible (e.g. minimized browser tab; other tab in focus)
    setIsCheckingNetworkState(false);
  }, [
    isDocumentVisible,
    setIsCheckingNetworkState,
    currentNetworkState,
    setOffline,
    areOfflineCapabilitiesEnabled,
  ]);

  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled) {
      return;
    }

    document.addEventListener("visibilitychange", setDocumentVisibilityState);
    window.addEventListener("online", setNavigatorNetworkState);
    window.addEventListener("offline", setNavigatorNetworkState);
    return () => {
      document.removeEventListener("visibilitychange", setDocumentVisibilityState);
      window.removeEventListener("online", setNavigatorNetworkState);
      window.removeEventListener("offline", setNavigatorNetworkState);
    };
  }, [setDocumentVisibilityState, setNavigatorNetworkState, areOfflineCapabilitiesEnabled]);

  // Only during first run - set initial visibility and navigator.online states
  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled) {
      return;
    }

    setDocumentVisibilityState();
    setNavigatorNetworkState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areOfflineCapabilitiesEnabled]);

  return (
    <NetworkDetectorContext.Provider value={[networkDetectorState, dispatch]}>
      {children}
    </NetworkDetectorContext.Provider>
  );
};
