import { useFlag } from "@unleash/proxy-client-react";
import { ChangeEvent, FormEvent, useCallback, useState } from "react";

import { useSearchHelpers } from "@web/common/hooks";

import { BrowseBar } from "src/components/BrowseBar";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import useCategories from "src/hooks/useCategories";
import { useOfflineCatalogQuery } from "src/hooks/useOfflineCatalogQuery";

export const BasketHeader: React.FC = () => {
  const [{ configuration, forcePreconfigureOrderSetup, orderType, port, supplier }] =
    useAppStateContext();
  const { isOnline } = useNetworkDetector();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const isOfflineSearchEnabled =
    useFlag("offline-products-search") && areOfflineCapabilitiesEnabled;

  const { query, setQuery } = useSearchHelpers(RoutesConfig.search);
  const [typedQuery, setTypedQuery] = useState(query);

  const handleQueryChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setTypedQuery(e.target.value);
  }, []);

  const handleSearchSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (typedQuery.length > 0) {
        setQuery(typedQuery);
      }
    },
    [setQuery, typedQuery]
  );

  const offlineCatalogQuery = useOfflineCatalogQuery({
    orderType,
    portId: port?.id || "",
    supplierId: supplier?.id,
    disabled: forcePreconfigureOrderSetup,
  });
  const shouldDisableCategoriesQuery =
    forcePreconfigureOrderSetup ||
    offlineCatalogQuery.isFetching ||
    (areOfflineCapabilitiesEnabled && !offlineCatalogQuery.data?.hasCatalogData);
  // We don't need to check for download time, hasNoCachedData etc. because
  // categories list is only used for additional navigation in the page header.
  // It's a nice-to-have addition to the basket, and not a mandatory data set.
  // However, if it errors out, the general network errors and Empty Catalog Error
  // are going to be shown, since without the catalog, user could not proceed
  // with the gather experience anyway.
  const { categoriesQuery } = useCategories({
    disabled: shouldDisableCategoriesQuery,
  });

  if (!configuration) {
    return null;
  }

  return (
    <>
      <TopBarController />
      <BrowseBar
        selectedPort={port}
        categorySelectorSettings={{
          categories: categoriesQuery.data || [],
        }}
        searchBarSettings={{
          placeholder: "Search...",
          onSubmit: handleSearchSubmit,
          onQueryChange: handleQueryChange,
          query: typedQuery,
          isOnline: areOfflineCapabilitiesEnabled ? isOnline : true,
          isOfflineSearchEnabled,
        }}
      />
    </>
  );
};
