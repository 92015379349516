import { memo, useCallback } from "react";

import {
  LocalStocktakeReportOldExtraItemForm,
  StocktakeReportItemEntityQuantityFieldPath,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemRobValueAmountFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";

import { StocktakeExtraProductTileDetails } from "../shared/StocktakeExtraProductTileDetails";
import { StocktakeFormProductTile } from "../shared/StocktakeFormProductTile";
import { StocktakeProductTileRobValueDetails } from "../shared/StocktakeProductRobValueDetails";
import { StocktakeProductTileEntityQuantityWarning } from "../shared/StocktakeProductTileEntityQuantityWarning";
import { StocktakeProductTileFormErrors } from "../shared/StocktakeProductTileFormErrors";
import { StocktakeProductTileEntityQuantityField } from "../shared/StocktakeProductTileQuantityField";
import { useUpdateDependentFormFields } from "../shared/hooks";

type Props = {
  product: LocalStocktakeReportOldExtraItemForm;
  hasLineNumberPlaceholder?: boolean;
  indexInCollection: number;
};

export const StocktakeFormOldExtraProductTile = memo(({ product, indexInCollection }: Props) => {
  const entityQuantityFieldPath: StocktakeReportItemEntityQuantityFieldPath = `oldExtraItems.${indexInCollection}.entityQuantity`;
  const singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath = `oldExtraItems.${indexInCollection}.singleUnitGrossPrice.amount`;
  const measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath = `oldExtraItems.${indexInCollection}.measurementUnit`;
  const robValueAmountFieldPath: StocktakeReportItemRobValueAmountFieldPath = `oldExtraItems.${indexInCollection}.robValue.amount`;
  const itemsFieldPath = "oldExtraItems";

  useUpdateDependentFormFields({
    initialEntityQuantity: product.entityQuantity,
    initialSingleUnitGrossPriceAmount: product.singleUnitGrossPrice.amount,
    initialMeasurementUnit: product.measurementUnit,
    currencyCode: product.robValue.currencyCode,
    entityQuantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
    robValueAmountFieldPath,
  });

  const renderProductDetails = useCallback(
    (product: LocalStocktakeReportOldExtraItemForm, className: string) => (
      <StocktakeExtraProductTileDetails product={product} className={className} />
    ),
    []
  );

  const renderEntityQuantityField = useCallback(
    () => (
      <StocktakeProductTileEntityQuantityField
        initialEntityQuantity={product.entityQuantity}
        initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
        initialMeasurementUnit={product.measurementUnit}
        currencyCode={product.robValue.currencyCode}
        entityQuantityFieldPath={entityQuantityFieldPath}
        singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
        measurementUnitFieldPath={measurementUnitFieldPath}
        hasUnitOfMeasure={true}
      />
    ),
    [
      product.entityQuantity,
      product.singleUnitGrossPrice.amount,
      product.measurementUnit,
      product.robValue.currencyCode,
      entityQuantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    ]
  );

  const renderEntityQuantityWarning = useCallback(
    () => (
      <StocktakeProductTileEntityQuantityWarning
        initialEntityQuantity={product.entityQuantity}
        initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
        initialMeasurementUnit={product.measurementUnit}
        currencyCode={product.robValue.currencyCode}
        entityQuantityOnStock={product.entityQuantityOnStock}
        entityQuantityFieldPath={entityQuantityFieldPath}
        singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
        measurementUnitFieldPath={measurementUnitFieldPath}
      />
    ),
    [
      product.entityQuantity,
      product.singleUnitGrossPrice.amount,
      product.measurementUnit,
      product.robValue.currencyCode,
      product.entityQuantityOnStock,
      entityQuantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    ]
  );

  const renderRobValueDetails = useCallback(
    () => (
      <StocktakeProductTileRobValueDetails
        initialEntityQuantity={product.entityQuantity}
        initialSingleUnitGrossPriceAmount={product.singleUnitGrossPrice.amount}
        initialMeasurementUnit={product.measurementUnit}
        currencyCode={product.robValue.currencyCode}
        entityQuantityFieldPath={entityQuantityFieldPath}
        singleUnitGrossPriceFieldPath={singleUnitGrossPriceFieldPath}
        measurementUnitFieldPath={measurementUnitFieldPath}
      />
    ),
    [
      product.entityQuantity,
      product.singleUnitGrossPrice.amount,
      product.measurementUnit,
      product.robValue.currencyCode,
      entityQuantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    ]
  );

  const renderFormErrors = useCallback(
    () => (
      <StocktakeProductTileFormErrors
        indexInCollection={indexInCollection}
        itemsFieldPath={itemsFieldPath}
      />
    ),
    [indexInCollection]
  );

  return (
    <StocktakeFormProductTile
      product={product}
      hasLineNumberPlaceholder={true}
      renderProductDetails={renderProductDetails}
      renderEntityQuantityField={renderEntityQuantityField}
      renderEntityQuantityWarning={renderEntityQuantityWarning}
      renderRobValueDetails={renderRobValueDetails}
      renderFormErrors={renderFormErrors}
    />
  );
});
StocktakeFormOldExtraProductTile.displayName = "StocktakeFormOldExtraProductTile";
