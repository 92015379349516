import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ActionBar, Heading, Label, Paragraph } from "@web/ui";

import { PlacedOrdersSummary } from "src/components/PlacedOrdersSummary";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";

export const PurchaseOrderSuccess = () => {
  const [{ lastCreatedOrderRequisitions }] = useAppStateContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!lastCreatedOrderRequisitions) {
      navigate(RoutesConfig.order.overview);
    }
  }, [lastCreatedOrderRequisitions, navigate]);

  if (!lastCreatedOrderRequisitions) {
    return null;
  }

  const createNewOrder = () => {
    navigate(RoutesConfig.gatherSetup);
  };

  const requesterEmail = lastCreatedOrderRequisitions[0].requesterInformation?.email;

  return (
    <div className="flex flex-col">
      <TopBarController />
      <ActionBar
        primaryButtonSettings={{
          title: "Go to Order Overview",
          onClick: () => navigate(RoutesConfig.order.overview),
          testId: "goToOrderOverviewButton",
        }}
        helperButtons={[{ title: "Create Another Order", onClick: () => createNewOrder() }]}
      />

      <div className="max-w-2xl mx-auto px-4 mt-3 text-center">
        <Label size="100" className="uppercase" color="text-primaryDefault">
          Order Confirmation
        </Label>
        <Heading size="100" className="mt-2">
          Thank you for submitting your order
        </Heading>
        <Paragraph size="200" className="mt-4">
          {!!requesterEmail && (
            <>
              A confirmation email has been sent to {requesterEmail}.<br />
            </>
          )}
          Go to the order overview page to check on the latest status of your order.
        </Paragraph>
      </div>

      <PlacedOrdersSummary className="mt-6 mb-12" orders={lastCreatedOrderRequisitions} />
    </div>
  );
};
