import { z } from "zod";

export const LiteFleetAllowSchema = z.object({
  createRfq: z.boolean(),
  fmsIntegration: z.boolean(),
  punchOutIntegration: z.boolean(),
  customerOrderIdOptional: z.boolean(),
  offline: z.boolean(),
  reorder: z.boolean(),
  offlineDraft: z.boolean(),
  foIntegration: z.boolean(),
  stocktaking: z.boolean(),
  sortPurchaseOrderLines: z.boolean(),
  orderExtraItems: z.boolean(),
});
