import classnames from "classnames";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { DeleteOrderDraftModal } from "@web/common/components/modals/DeleteOrderDraftModal";
import { ActionBar, Modal, TabItem, Tabs, containerPadding } from "@web/ui";

import { OverviewTabs } from "src/components/OverviewTabs";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { LEGACY_useDeleteOrderDraftMutation } from "src/hooks/LEGACY_useDeleteOrderDraftMutation";
import { useDeleteOrderDraftMutation } from "src/hooks/orderDrafts/useDeleteOrderDraftMutation";
import useBasket from "src/hooks/useBasket";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { isOrderState } from "src/utils";

import { OrdersTable } from "./OrdersTable";

export const OrdersOverview = () => {
  const navigate = useNavigate();
  const [{ configuration }] = useAppStateContext();
  const { clearOrderRequisitionFlow } = useBasket();
  const { mutate: deleteDraftMutation, isPending: isMutationPending } =
    LEGACY_useDeleteOrderDraftMutation({
      onSuccess: () => {
        closeDeleteDraftModal();
      },
    });
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();
  const [isDeleteDraftModalOpen, setIsDeleteDraftModalOpen] = useState(false);
  const [draftToDeleteId, setDraftToDeleteId] = useState<string>("");
  const isStocktakingEnabled = !!configuration?.fleet.allow.stocktaking;

  const [searchParams, setSearchParams] = useSearchParams();
  const ordersStateFromSearchParams = searchParams.get("ordersState") || "";

  useEffect(() => {
    if (!isOrderState(ordersStateFromSearchParams)) {
      setSearchParams({ ordersState: "active" });
    }
  }, [setSearchParams, searchParams, ordersStateFromSearchParams]);

  const initialTabId = useMemo(() => {
    const ordersState = isOrderState(ordersStateFromSearchParams)
      ? ordersStateFromSearchParams
      : "active";

    return ordersState === "active"
      ? "ACTIVE_ORDERS"
      : ordersState === "closed"
      ? "CLOSED_ORDERS"
      : tabItems[0].id;
    // This only needs to be calculated once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChanged = useCallback(
    (selectedTabId?: string) => {
      if (!selectedTabId) {
        return;
      }
      setSearchParams({
        ordersState: selectedTabId === "ACTIVE_ORDERS" ? "active" : "closed",
      });
    },
    [setSearchParams]
  );

  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();

  const { mutate: deleteDraft, isPending: isDeletingDraft } = useDeleteOrderDraftMutation({
    hasSuccessMessage: true,
    hasErrorMessage: true,
    onSuccess: () => {
      closeDeleteDraftModal();
    },
  });

  const createNewOrder = useCallback(() => {
    clearOrderRequisitionFlow();
    navigate(RoutesConfig.gatherSetup);
  }, [clearOrderRequisitionFlow, navigate]);

  const onDeleteDraftConfirm = useCallback(
    (draftId: string) => {
      if (isOfflineDraftEnabled) {
        deleteDraft(draftId);
        return;
      }

      allowOnlineOnly(() => {
        deleteDraftMutation(draftId);
      });
    },
    [allowOnlineOnly, deleteDraft, deleteDraftMutation, isOfflineDraftEnabled]
  );

  const openDeleteDraftModal = useCallback(
    (draftId: string) => {
      if (isOfflineDraftEnabled) {
        setDraftToDeleteId(draftId);
        setIsDeleteDraftModalOpen(true);
        return;
      }

      allowOnlineOnly(() => {
        setDraftToDeleteId(draftId);
        setIsDeleteDraftModalOpen(true);
      });
    },
    [allowOnlineOnly, isOfflineDraftEnabled]
  );

  const closeDeleteDraftModal = useCallback(() => {
    setIsDeleteDraftModalOpen(false);
    setDraftToDeleteId("");
  }, []);

  const actionBarPrimaryButtonSettings = useMemo(() => {
    if (configuration?.canCreateOrder) {
      return { title: "Create Order", onClick: createNewOrder };
    } else if (configuration?.canCreateRequisition) {
      return { title: "Create Request", onClick: createNewOrder };
    }
  }, [configuration?.canCreateOrder, configuration?.canCreateRequisition, createNewOrder]);

  const tabItems: TabItem[] = useMemo(
    () => [
      {
        id: "ACTIVE_ORDERS",
        label: "Active Orders",
        "data-testid": "activeOrdersTab",
        children: <OrdersTable onDeleteDraft={openDeleteDraftModal} ordersState="active" />,
      },
      {
        id: "CLOSED_ORDERS",
        label: "Closed Orders",
        "data-testid": "closedOrdersTab",
        children: <OrdersTable onDeleteDraft={openDeleteDraftModal} ordersState="closed" />,
      },
    ],
    [openDeleteDraftModal]
  );

  return (
    <div className="bg-neutral_100 flex flex-col min-h-full">
      <TopBarController />

      {isStocktakingEnabled && <OverviewTabs initialTabId="ORDERS" />}

      <div className="flex justify-between items-center" data-testid="orderOverview_header">
        <ActionBar
          leftTitle="Order Overview"
          primaryButtonSettings={actionBarPrimaryButtonSettings}
        />
      </div>
      <div className={classnames(containerPadding, "mt-2")}>
        <div className="w-full">
          <Tabs
            size="small"
            variant="folder"
            items={tabItems}
            initialTabId={initialTabId}
            onTabChange={handleTabChanged}
            data-testid="orderOverview_tabs"
          />
        </div>
        <div className="h-5" />
      </div>
      <AllowOnlineOnlyWarningModal />
      <Modal isOpen={isDeleteDraftModalOpen} closeModal={closeDeleteDraftModal}>
        <DeleteOrderDraftModal
          closeModal={closeDeleteDraftModal}
          draftId={draftToDeleteId}
          isLoading={isOfflineDraftEnabled ? isDeletingDraft : isMutationPending}
          onConfirm={() => onDeleteDraftConfirm(draftToDeleteId)}
        />
      </Modal>
    </div>
  );
};
