import * as Sentry from "@sentry/browser";
import { BrowserRouter as Router } from "react-router-dom";

import { OpenAPI as AttachmentsOpenAPI } from "@web/attachments";
import { MapHashLocationToRoute } from "@web/common/contexts/MapHashLocationToRoute";
import { imagorConfig } from "@web/utils";

import setAppLanguage from "./config/i18n";
import AppProviders from "./contexts/Providers";
import { Pages } from "./pages/Pages";
import { OpenAPI } from "./typegens";

const VITE_ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT as string;
const VITE_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN as string;
const VITE_GIT_HASH = import.meta.env.VITE_GIT_HASH as string;
const VITE_API_BASE_URL = import.meta.env.VITE_API_BASE_URL as string;

Sentry.init({
  dsn: VITE_SENTRY_DSN,
  environment: VITE_ENVIRONMENT,
  release: VITE_GIT_HASH,
});

const apiBaseUrl = VITE_API_BASE_URL;

OpenAPI.BASE = apiBaseUrl;
OpenAPI.AUTH_SCHEME = "Vessel-Token";
OpenAPI.ENCODE_PATH = encodeURIComponent;
AttachmentsOpenAPI.BASE = apiBaseUrl;
AttachmentsOpenAPI.ENCODE_PATH = encodeURIComponent;
imagorConfig.baseUrl = apiBaseUrl;

setAppLanguage();

const App: React.FC = () => {
  return (
    <Router>
      <MapHashLocationToRoute>
        <AppProviders>
          <Pages />
        </AppProviders>
      </MapHashLocationToRoute>
    </Router>
  );
};

export default App;
