import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useModalContext } from "@web/common/contexts";

import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import useBasket from "src/hooks/useBasket";

import BackToOrderOverviewConfirmationModal from "../Modal/BackToOrderOverviewConfirmationModal";
import { TopBar } from "./TopBar";

export const TopBarController = () => {
  const [{ configuration, isPunchoutSession, isGatherOutSession }] = useAppStateContext();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isOnline, isOffline, isShakyConnection } = useNetworkDetector();
  const navigate = useNavigate();
  const { openModal } = useModalContext();
  const { draft, totalQuantity, clearOrderRequisitionFlow } = useBasket();

  const handleBackToOverview = useCallback(() => {
    if (totalQuantity > 0 && !draft) {
      openModal(
        <BackToOrderOverviewConfirmationModal
          onBackToOverview={() => {
            navigate(RoutesConfig.mainPage);
            clearOrderRequisitionFlow();
          }}
        />
      );
    } else {
      navigate(RoutesConfig.mainPage);
      clearOrderRequisitionFlow();
    }
  }, [clearOrderRequisitionFlow, draft, navigate, openModal, totalQuantity]);

  return (
    <>
      {areOfflineCapabilitiesEnabled ? (
        <TopBar
          networkStatusSettings={{
            isNetworkOnline: isOnline,
            isNetworkOffline: isOffline,
            isShakyConnection,
          }}
          isBackButtonDisabled={isPunchoutSession || isGatherOutSession}
          vesselName={configuration?.vessel.name}
          vesselId={configuration?.vessel.id}
          onLogoClick={handleBackToOverview}
        />
      ) : (
        <TopBar
          vesselName={configuration?.vessel.name || ""}
          vesselId={configuration?.vessel.id || ""}
          isBackButtonDisabled={isPunchoutSession || isGatherOutSession}
          onLogoClick={handleBackToOverview}
        />
      )}
    </>
  );
};
