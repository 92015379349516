import { WifiIcon } from "@heroicons/react/outline";
import classnames from "classnames";

import { Label } from "../../atoms";

interface Props {
  isNetworkOnline: boolean;
  isNetworkOffline: boolean;
  isShakyConnection?: boolean;
}

export const NetworkStatusIndicator = ({
  isNetworkOnline,
  isNetworkOffline,
  isShakyConnection = false,
}: Props) => {
  return (
    <div className="flex items-center">
      {isNetworkOnline ? (
        <Label
          data-testid="networkToggleSwitch_onlineState"
          size="200"
          color="text-textIcon-blackSecondary"
          className="ml-3 mr-2"
        >
          Online
        </Label>
      ) : (
        <Label
          data-testid="networkToggleSwitch_offlineState"
          size="200"
          color="text-textIcon-blackSecondary"
          className="ml-3 mr-2"
        >
          Offline
        </Label>
      )}
      <div className="inline-block -mt-0.5 relative">
        <WifiIcon
          className={classnames("w-4.5 text-textIcon-blackSecondary", {
            "[&>path]:animate-pulse": isShakyConnection,
          })}
        />
        {isNetworkOffline && (
          <div className="w-4.5 h-4.5 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <div className="relative top-1/2 w-4.5 h-[2px] bg-neutral_700 rotate-45"></div>
          </div>
        )}
      </div>
    </div>
  );
};
