import { z } from "zod";

import { preprocessFormAmountValue } from "@web/utils";

import { ApiMoneySchema } from "../ApiMoneySchema";
import { LiteStocktakeExtraItemSchema } from "./LiteStocktakeExtraItemSchema";

export const StocktakeReportDraftOldExtraItemSchema = LiteStocktakeExtraItemSchema.omit({
  quantity: true,
}).extend({
  // We need separate validation setup for draft, because we want to be able to store empty values for them
  entityQuantity: z.preprocess(preprocessFormAmountValue, z.number().optional()),
  singleUnitGrossPrice: ApiMoneySchema.extend({
    amount: z.number().optional(),
  }),
});

export type LocalStocktakeReportDraftOldExtraItem = z.infer<
  typeof StocktakeReportDraftOldExtraItemSchema
>;
