import { DefaultError, useMutation } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";

import { OFFLINE_HTTP_HEADERS } from "src/config/constants";

import { checkProductsAvailability } from "../api/checkProductsAvailability";
import {
  LiteProductAvailabilityCheckRequest,
  LiteProductAvailabilityCheckResponse,
  ProductsService,
} from "../typegens";

export const useProductVariantsMutation = () => {
  const isMissingItemsReplacementsEnabled = useFlag("va-missing-items-replacements");
  return useMutation<
    LiteProductAvailabilityCheckResponse,
    DefaultError,
    LiteProductAvailabilityCheckRequest
  >({
    mutationFn: async ({
      items,
      portId,
      orderType,
      supplierId,
    }: LiteProductAvailabilityCheckRequest) => {
      if (isMissingItemsReplacementsEnabled) {
        return await checkProductsAvailability({
          items,
          portId,
          orderType,
          supplierId,
        });
      }

      const response = await ProductsService.getProductVariants(
        {
          requestBody: {
            portId,
            variantIds: items.map((item) => item.variantId),
            orderType,
            supplierId,
          },
        },
        { headers: { [OFFLINE_HTTP_HEADERS.SKIP_SW_HANDLER]: true } }
      );
      return {
        availableItems: response.items,
        replacementItems: {},
      };
    },
  });
};
