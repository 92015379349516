import { usePrevious } from "@web/utils";

import { useNetworkDetectorContext } from "./NetworkDetectorContext";
import { UseNetworkDetector } from "./models";

export const useNetworkDetector = (): UseNetworkDetector => {
  const [networkDetectorState] = useNetworkDetectorContext();
  const { currentNetworkState, isNavigatorOnline, isShakyConnection } = networkDetectorState;

  const isOnline = currentNetworkState === "online";
  const isOffline = currentNetworkState === "offline";
  const isUnknown = currentNetworkState === "unknown";

  const prevIsOnline = usePrevious(isOnline);

  const becameOnline = isOnline && !prevIsOnline;
  const isNetworkConnectionDetected = !isUnknown;

  const wentOfflineFromOnline = !!prevIsOnline && isOffline;

  return {
    currentNetworkState,
    isNavigatorOnline,
    isNetworkConnectionDetected,
    isOnline,
    isOffline,
    isShakyConnection,
    becameOnline,
    wentOfflineFromOnline,
  };
};
