import { useCallback, useMemo } from "react";

import { DEFAULT_ORDER_TYPE } from "@web/common";
import { getLineItemEntityQuantity, getSingleEntityPrice } from "@web/common/utils";

import { useAppStateContext } from "src/contexts/AppStateContext";
import { LocalPartialOrderDraft } from "src/models";

import useBasket from "../useBasket";

export type UsePrepareOrderDraft = {
  getPartialOrderDraftData: () => LocalPartialOrderDraft;
};

export const usePrepareOrderDraft = (): UsePrepareOrderDraft => {
  const [
    { configuration, port, dutyFreeDeclaration, deliveryDate, orderType, orderName = "", supplier },
  ] = useAppStateContext();
  const { rfqItems, lineItems, extraItems, getVariantId, grandTotal } = useBasket();

  // LitePurchaseOrderItem[]
  const catalogItems = useMemo(
    () =>
      lineItems.map((lineItem, index) => ({
        // Line numbers are 1-based
        lineNumber: index + 1,
        variantId: getVariantId(lineItem.sku),
        name: lineItem.sku.about?.name || "",
        measurementUnit: lineItem.sku.measurementUnit,
        singleGrossPrice: getSingleEntityPrice(lineItem.sku),
        quantity: lineItem.quantity,
        entityQuantity: getLineItemEntityQuantity(lineItem),
        impaCode: lineItem.sku.about?.generalInformation?.impaCode || "",
        skuDetails: {
          ...lineItem.sku,
          attachments: lineItem.sku.attachments || [],
        },
      })),
    [lineItems, getVariantId]
  );

  const orderSummaryForm = useMemo(
    () => ({
      // Order Summary form
      customerOrderId: "",
      deliveryDate,
      dutyFreeDeclaration,
      orderNotes: "",
      requesterInformation: {
        // Needs to be allowed to be replaced by updated data from the Order Summary form
        email: configuration?.vessel.contactInformation?.email || "",
        name: configuration?.vessel.contactInformation?.name || "",
      },
      // The components expect either meaningful data or the data to be undefined
      // agentInformation: {
      //   firstName: "",
      //   lastName: "",
      //   company: "",
      //   email: "",
      //   phoneNumber: "",
      // },
      storageLabel: "",
      // Consolidated order checkbox in the form
      consolidated: false,
      // Only not empty when consolidated:true; LiteWarehouse
      // The components expect either meaningful data or the data to be undefined
      // warehouse: {
      //   id: "",
      //   line1: "",
      //   line2: "",
      //   line3: "",
      //   line4: "",
      // },
      // LiteInvoiceAccount
      // The components expect either meaningful data or the data to be undefined
      // invoiceAccount: {
      //   id: "",
      //   taxNumber: "",
      //   supplierInvoiceId: "",
      //   registerOwner: "",
      //   billingAddressLine1: "",
      //   billingAddressLine2: "",
      //   billingAddressLine3: "",
      //   country: "",
      //   approvalRequired: false,
      // },
      // LiteDutyFreeDeclaration
      // dutyFreeDeclaration: {
      //   dutyFree: false;
      //   name: "";
      //   position: "";
      // },
      attachments: [],
    }),
    [
      configuration?.vessel.contactInformation?.email,
      configuration?.vessel.contactInformation?.name,
      deliveryDate,
      dutyFreeDeclaration,
    ]
  );

  const getPartialOrderDraftData = useCallback<() => LocalPartialOrderDraft>(() => {
    if (!configuration) {
      throw new Error("No configuration");
    }
    if (!port) {
      throw new Error("No port selected");
    }
    if (!supplier) {
      throw new Error("No supplier selected");
    }

    return {
      port: { ...port },
      vessel: { ...configuration.vessel },
      totalGrossAmount: grandTotal,
      catalogItems: [...catalogItems],
      extraItems: [...extraItems],
      // LiteRfqItem[]
      rfqItems: [...rfqItems],
      // Drafts cannot be closed (at least yet)
      closed: false,
      orderType: orderType ?? DEFAULT_ORDER_TYPE,
      subject: orderName,
      supplierInformation: {
        id: supplier.id,
        name: supplier.name,
      },
      ...orderSummaryForm,
    };
  }, [
    configuration,
    port,
    supplier,
    grandTotal,
    catalogItems,
    extraItems,
    rfqItems,
    orderType,
    orderName,
    orderSummaryForm,
  ]);

  return {
    getPartialOrderDraftData,
  };
};
