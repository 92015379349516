import BusinessPelican from "@web/common/icons/BusinessPelican.svg";
import VesselIcon from "@web/common/icons/VesselIcon.svg";
import { CircledImage, NetworkStatusIndicator, Paragraph, containerPadding } from "@web/ui";

type Props = {
  vesselName?: string;
  vesselId?: string;
  isBackButtonDisabled: boolean;
  onLogoClick: () => void;
  networkStatusSettings?: {
    isNetworkOnline: boolean;
    isNetworkOffline: boolean;
    isShakyConnection?: boolean;
  };
};

export const TopBar: React.FC<Props> = ({
  vesselId,
  vesselName,
  onLogoClick,
  isBackButtonDisabled,
  networkStatusSettings,
}) => {
  return (
    <div className="bg-neutral_0 border-b border-b-neutral_200 w-full z-10" data-testid="topbar">
      <div
        className={`${containerPadding} w-full relative flex justify-between content-center items-center h-[56px] bg-neutral_0`}
      >
        {!isBackButtonDisabled ? (
          <div onClick={onLogoClick} className="self-center cursor-pointer">
            <img src={BusinessPelican} alt="Pelican icon" data-testid="topbar_logo" />
          </div>
        ) : (
          <div className="self-center">
            <img src={BusinessPelican} alt="Pelican icon" data-testid="topbar_logo" />
          </div>
        )}

        {!!vesselName && !!vesselId && (
          <div className="flex m-auto items-center pt-px" data-testid="topbar_vesselInfo">
            <CircledImage
              Icon={VesselIcon}
              size={6}
              hashKey={vesselId}
              data-testid="topbar_vesselIcon"
            />
            <Paragraph size="100" className="pl-1" data-testid="topbar_vesselName">
              {vesselName}
            </Paragraph>
          </div>
        )}

        {networkStatusSettings && (
          <div className="flex items-center justify-end" data-testid="topbar_networkToggle">
            <NetworkStatusIndicator
              isNetworkOnline={networkStatusSettings.isNetworkOnline}
              isNetworkOffline={networkStatusSettings.isNetworkOffline}
              isShakyConnection={networkStatusSettings.isShakyConnection}
            />
          </div>
        )}
      </div>
    </div>
  );
};
