import CheckCircleOutlineSharp from "@mui/icons-material/CheckCircleOutlineSharp";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import HighlightOffOutlined from "@mui/icons-material/HighlightOffOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import classnames from "classnames";
import { PropsWithChildren } from "react";

import AnchorIcon from "@web/common/icons/AnchorIcon.svg";
import { OrderRequisition } from "@web/common/network/model";
import { Heading, Label, LoadingInline, MuiIcon, Paragraph, TextColors } from "@web/ui";

type Props = {
  closeModal: () => void;
  ctaButtons: React.ReactElement;
  isDraft?: boolean;
  loading: boolean;
  isDownloadingOfflineCatalog?: boolean;
  order: OrderRequisition;
  title: string;
  ratio?: [number, number];
  error?: string;
};

export const ItemsAvailabilityModalContainer = ({
  children,
  closeModal,
  ctaButtons,
  error,
  isDraft,
  loading,
  isDownloadingOfflineCatalog = false,
  order,
  ratio,
  title,
}: PropsWithChildren<Props>) => {
  const isMissingItemsReplacementsEnabled = useFlag("va-missing-items-replacements");

  const state = ratio
    ? ratio[0] === 0
      ? "red"
      : ratio[0] !== ratio[1]
      ? "blue"
      : "green"
    : undefined;

  return (
    <div
      className="inline-block bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle w-min"
      data-testid="itemsAvailabilityModal"
    >
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={closeModal}
        >
          <MuiIcon
            Icon={CloseOutlined}
            className="w-5 text-textIcon-whiteDisabled"
            aria-hidden="true"
          />
        </button>
      </div>
      <div className="flex flex-col w-130 m-5" data-testid="itemsAvailabilityModal__header">
        <Heading size="200">{title}</Heading>{" "}
        <div className="flex flex-row mt-2 gap-4">
          <div className="basis-2/3">
            <Label size="200">Review the availability of items</Label>
            <Paragraph size="300" color="text-textIcon-blackSecondary">
              {isMissingItemsReplacementsEnabled
                ? "Items that are not available will not be added to your basket. Their replacements (if available) will be added instead."
                : "Items that are not available will not be added to your basket"}
            </Paragraph>
          </div>
          <div className="basis-1/3">
            <Paragraph size="300" className="mb-2" color="text-textIcon-blackSecondary">
              Original Delivery Port
            </Paragraph>
            <div className="flex">
              <img src={AnchorIcon} alt="Anchor Icon" style={{ paddingLeft: "0.1em" }} />
              <Paragraph size="200" className="pl-3" color="text-neutral_700">
                {order.port.locationCode}{" "}
                <span className="text-textIcon-blackPrimary">{order.port.name}</span>
              </Paragraph>
            </div>
          </div>
        </div>
        <div className="mt-4">
          {(loading || isDownloadingOfflineCatalog) && (
            <>
              <LoadingInline />
              <Label size="200" className="ml-4" color="text-textIcon-whiteDisabled">
                {isDownloadingOfflineCatalog ? (
                  <>
                    We are downloading the catalog data and this may take some time.
                    <br /> Please do not refresh your browser or go to another page.
                  </>
                ) : (
                  "Checking availability of items..."
                )}
              </Label>
            </>
          )}
          {!!ratio && (
            <Label
              size="200"
              color={
                classnames({
                  "text-successDefault": state === "green",
                  "text-primaryDefault": state === "blue",
                  "text-dangerDefault": state === "red",
                }) as TextColors
              }
              className="ml-4 flex"
            >
              {state === "green" ? (
                <MuiIcon Icon={InfoOutlined} className="w-4 mr-2" />
              ) : state === "blue" ? (
                <MuiIcon Icon={HighlightOffOutlined} className="w-4 mr-2" />
              ) : (
                <MuiIcon Icon={CheckCircleOutlineSharp} className="w-4 mr-2" />
              )}
              {`${ratio[0]}/${ratio[1]} ${
                isDraft ? "of your draft Items are available" : "Items available"
              }`}
            </Label>
          )}
          {!!error && (
            <Label size="200" color="text-dangerDefault">
              {error}
            </Label>
          )}
        </div>
      </div>
      <div
        className={`bg-neutral_100 border-t-2 border-b-2 p-5 ${
          typeof children === "undefined" && "h-80"
        }`}
        data-testid="itemsAvailabilityModal__content"
      >
        {children}
      </div>
      <div className="m-5 flex" data-testid="itemsAvailabilityModal__footer">
        {ctaButtons}
      </div>
    </div>
  );
};
