import { useMemo } from "react";

import { IntersectionMonitor } from "@web/common";
import { OrderRequisition } from "@web/common/network/model";
import { Loading } from "@web/ui";

import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useOrderDraftsQuery } from "src/hooks/orderDrafts/useOrderDraftsQuery";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";
import { useOrderRequisitionListQuery } from "src/hooks/useOrderRequisitionListQuery";
import { OrderState } from "src/models";
import { LocalOrderReqService } from "src/services/LocalOrderReqService";

import { OrdersTableUI } from "./OrdersTableUI";

type Props = {
  ordersState: OrderState;
  onDeleteDraft: (draftId: string) => void;
};

export const OrdersTable = ({ ordersState, onDeleteDraft }: Props) => {
  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();

  const [{ configuration }] = useAppStateContext();

  const orderRequisitionsQuery = useOrderRequisitionListQuery(ordersState === "closed");

  const draftsQuery = useOrderDraftsQuery();

  const draftsAsOrderRequisitions = useMemo(
    () => (draftsQuery.data || []).map((draft) => LocalOrderReqService.toOrderRequisition(draft)),
    [draftsQuery.data]
  );

  const orderRequisitionsList = useMemo(() => {
    const pages = orderRequisitionsQuery.data?.pages;
    // Drafts are not closed orders
    const drafts = ordersState === "active" ? draftsAsOrderRequisitions : [];
    return [...drafts, ...(pages || []).flatMap((p) => p.items)];
  }, [orderRequisitionsQuery.data?.pages, ordersState, draftsAsOrderRequisitions]);

  const getOrderDetailsUrl = (order: OrderRequisition) => {
    if (isOfflineDraftEnabled) {
      return order.type === "DRAFT"
        ? `${RoutesConfig.order.draftDetails}/${order.id}`
        : `${RoutesConfig.order.details}/${order.id}`;
    }

    return `${RoutesConfig.order.details}/${order.id}`;
  };

  if (
    orderRequisitionsQuery.isPending ||
    (orderRequisitionsQuery.isFetching && !orderRequisitionsQuery.isFetchingNextPage) ||
    draftsQuery.isPending ||
    draftsQuery.isFetching
  ) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col items-center" data-testid="orderOverview_ordersTable">
      <OrdersTableUI
        orders={orderRequisitionsList}
        getUrl={getOrderDetailsUrl}
        onDeleteDraft={onDeleteDraft}
        orderTypes={configuration?.orderTypeConfigs}
      />
      {orderRequisitionsQuery.hasNextPage && (
        <IntersectionMonitor onEnter={orderRequisitionsQuery.fetchNextPage}>
          <div className="h-10">{orderRequisitionsQuery.isFetchingNextPage && <Loading />}</div>
        </IntersectionMonitor>
      )}
    </div>
  );
};
