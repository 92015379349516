import { zodResolver } from "@hookform/resolvers/zod";
import CheckCircleOutlineSharp from "@mui/icons-material/CheckCircleOutlineSharp";
import ErrorOutlineOutlined from "@mui/icons-material/ErrorOutlineOutlined";
import FileDownloadOutlined from "@mui/icons-material/FileDownloadOutlined";
import classnames from "classnames";
import { useCallback, useMemo, useState } from "react";
import {
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { generatePath, useNavigate } from "react-router-dom";

import {
  ActionBar,
  ActionBarBackButtonSettings,
  ActionBarPrimaryButtonSettings,
  Heading,
  LinkButton,
  Modal,
  MuiIcon,
  Paragraph,
  containerPadding,
} from "@web/ui";

import { FinishStocktakeReportModal } from "src/components/Modal";
import { TopBarController } from "src/components/TopBar";
import { RoutesConfig, RoutesParams } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import {
  useAutosaveStocktakeDraftForm,
  useCreateStocktakeReportMutation,
  useExportStockCountListToExcelMutation,
} from "src/hooks/stocktakes";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useToastMessage } from "src/hooks/useToastMessage";
import {
  LocalStocktakeReportDraft,
  LocalStocktakeReportForm,
  StocktakeReportFormSchema,
} from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

import { StocktakeCategories } from "./StocktakeCategories";
import { StocktakeEditNothingToStocktakeInfo } from "./StocktakeEditNothingToStocktakeInfo";
import { StocktakeExtraItemsSection } from "./StocktakeExtraItemsSection";
import { StocktakeItemsList } from "./StocktakeItemsList";
import { StocktakeTotals } from "./StocktakeTotals";

type Props = {
  editableStocktakeDraftData: LocalStocktakeReportDraft;
};

export const StocktakeEditForm = ({ editableStocktakeDraftData }: Props) => {
  const navigate = useNavigate();
  const [{ configuration }] = useAppStateContext();
  const vesselId = configuration?.vessel.id;
  const availableStocktakeTypes = configuration?.availableStocktakeTypes;
  const unitOfMeasures = configuration?.unitOfMeasures ?? [];
  const { setToastMessage } = useToastMessage();
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();
  const [isFinishStocktakeModalOpen, setIsFinishStocktakeModalOpen] = useState(false);
  const [validatedFormData, setValidatedFormData] = useState<
    LocalStocktakeReportForm | undefined
  >();
  const [areAnyItemsUncounted, setAreAnyItemsUncounted] = useState(false);
  const [activeCategoryId, setActiveCategoryId] = useState<string | undefined>(undefined);
  const [isExtraItemsSectionOpen, setIsExtraItemsSectionOpen] = useState(true);

  const openFinishStocktakeModal = useCallback((validatedFormData: LocalStocktakeReportForm) => {
    setValidatedFormData(validatedFormData);
    setAreAnyItemsUncounted(
      LocalStocktakeService.areAnyItemsUncounted({
        items: validatedFormData.items,
        oldExtraItems: validatedFormData.oldExtraItems,
        newExtraItems: validatedFormData.newExtraItems,
      })
    );
    setIsFinishStocktakeModalOpen(true);
  }, []);
  const closeFinishStocktakeModal = useCallback(() => {
    setIsFinishStocktakeModalOpen(false);
    setValidatedFormData(undefined);
  }, []);

  const draftId = editableStocktakeDraftData.id;
  const currencyCode = editableStocktakeDraftData.robValue.currencyCode;

  const formMethods = useForm<LocalStocktakeReportForm>({
    resolver: zodResolver(StocktakeReportFormSchema),
    // `mode` is set to `onSubmit` due to performance reasons
    mode: "onSubmit",
    // `reValidateMode` is set to `onSubmit` due to performance reasons
    reValidateMode: "onSubmit",
    defaultValues: editableStocktakeDraftData,
  });

  const { control, handleSubmit, reset: resetForm, getValues } = formMethods;

  const { fields: items } = useFieldArray({
    control,
    name: "items",
    // Set custom key where react-hook-form generates its internal ID for each collection element
    // See also: https://spectrum.chat/react-hook-form/help/useformarray-is-overriding-the-id-on-my-data~a976690e-dfdd-4736-81c7-1a740b0b3fc7
    keyName: "key",
  });

  const {
    fields: newExtraItems,
    prepend: prependNewExtraItem,
    remove: removeNewExtraItem,
  } = useFieldArray({
    control,
    name: "newExtraItems",
    // Set custom key where react-hook-form generates its internal ID for each collection element
    // See also: https://spectrum.chat/react-hook-form/help/useformarray-is-overriding-the-id-on-my-data~a976690e-dfdd-4736-81c7-1a740b0b3fc7
    keyName: "key",
  });

  const { fields: oldExtraItems } = useFieldArray({
    control,
    name: "oldExtraItems",
    // Set custom key where react-hook-form generates its internal ID for each collection element
    // See also: https://spectrum.chat/react-hook-form/help/useformarray-is-overriding-the-id-on-my-data~a976690e-dfdd-4736-81c7-1a740b0b3fc7
    keyName: "key",
  });

  const totalNumberOfItems = items.length + newExtraItems.length + oldExtraItems.length;
  const doesAnyCatalogItemExist = items.length > 0;
  const doesAnyItemExist = totalNumberOfItems > 0;
  const doesAnyOldExtraItemExist = oldExtraItems.length > 0;

  const {
    isError: hasDraftAutosavingFailed,
    retry: retrySaveDraft,
    abortAutosave: abortAutosaveDraft,
  } = useAutosaveStocktakeDraftForm({
    control,
    getValues,
    draftId,
    vesselId: vesselId || "",
    defaultValues: editableStocktakeDraftData,
    hasErrorMessage: true,
  });

  const { mutate: createStocktakeReport, isPending: isFormSubmitting } =
    useCreateStocktakeReportMutation({
      vesselId: vesselId || "",
      stocktakeDraftId: draftId,
      hasErrorMessage: true,
      onSuccess: (createdStocktakeReport) => {
        abortAutosaveDraft();
        navigate(
          generatePath(RoutesConfig.stocktake.finishSuccess, {
            [RoutesParams.STOCKTAKE_ID]: createdStocktakeReport.id,
          })
        );
      },
    });

  const handleStocktakeFinishConfirmed = useCallback(() => {
    if (isFormSubmitting || !validatedFormData) {
      return;
    }

    resetForm(validatedFormData);
    createStocktakeReport(validatedFormData);
  }, [createStocktakeReport, isFormSubmitting, resetForm, validatedFormData]);

  const handleValidFormSubmit: SubmitHandler<LocalStocktakeReportForm> = useCallback(
    (validatedFormData) => {
      allowOnlineOnly(() => {
        openFinishStocktakeModal(validatedFormData);
      });
    },
    [allowOnlineOnly, openFinishStocktakeModal]
  );

  const handleInvalidFormSubmit: SubmitErrorHandler<LocalStocktakeReportForm> = useCallback(
    (errors) => {
      setToastMessage({
        type: "failure",
        message: "Your Stocktake Report contains some errors. Please fix them and try again.",
      });
      // May come in handy if user gets stuck
      console.error("Your form contains the following errors:", errors);

      // Show all items
      setActiveCategoryId(undefined);

      // Open the extra items section if any errors in extra items exist
      if (errors.oldExtraItems || errors.newExtraItems) {
        setIsExtraItemsSectionOpen(true);
      }
    },
    [setToastMessage]
  );

  const handleCategoryClick = useCallback((categoryId?: string) => {
    setActiveCategoryId(categoryId);

    // If `categoryId` is `undefined`, then all categories will be shown, and we want to also unfold
    // the extra items section
    if (!categoryId) {
      setIsExtraItemsSectionOpen(true);
    }
  }, []);

  // Stock count list Excel download
  // ---------------------------------------------------------------------------

  const shouldExportFileNameIncludeStocktakeType = LocalStocktakeService.shouldRenderStocktakeType(
    availableStocktakeTypes,
    editableStocktakeDraftData.type
  );

  const { mutate: downloadExcel, isPending: isExcelDownloadPending } =
    useExportStockCountListToExcelMutation({
      fileDownloadNameWithoutExtension: LocalStocktakeService.formatExportFileName(
        "S2S_SCL",
        shouldExportFileNameIncludeStocktakeType,
        editableStocktakeDraftData.type,
        editableStocktakeDraftData.stocktakeDate
      ),
      hasSuccessMessage: true,
      hasErrorMessage: true,
    });

  // ActionBar settings
  // ---------------------------------------------------------------------------

  const headerBackButton = useMemo(
    (): ActionBarBackButtonSettings => ({
      title: "Back to stocktake overview",
      onClick: () => {
        navigate(RoutesConfig.stocktake.overview);
      },
    }),
    [navigate]
  );

  const headerPrimaryButton = useMemo(
    (): ActionBarPrimaryButtonSettings => ({
      title: "Finish Stocktake",
      type: "submit",
    }),
    []
  );

  const headerHelperButtons = useMemo(
    () => [
      {
        title: "Export As Excel",
        isLoading: isExcelDownloadPending,
        LeadingIcon: FileDownloadOutlined,
        onClick: () => {
          allowOnlineOnly(() => {
            const formData = getValues();
            const exportExcelRequestData =
              LocalStocktakeService.convertFromReportFormToApiExportStockCountListRequest(formData);
            downloadExcel(exportExcelRequestData);
          });
        },
      },
    ],
    [allowOnlineOnly, downloadExcel, getValues, isExcelDownloadPending]
  );

  // ---------------------------------------------------------------------------

  return (
    <div className="bg-neutral_100 flex flex-col min-h-full">
      <AllowOnlineOnlyWarningModal />
      <Modal isOpen={isFinishStocktakeModalOpen} closeModal={closeFinishStocktakeModal}>
        <FinishStocktakeReportModal
          onConfirm={handleStocktakeFinishConfirmed}
          closeModal={closeFinishStocktakeModal}
          isLoading={isFormSubmitting}
          isNotCountedItemsWarningShown={areAnyItemsUncounted}
        />
      </Modal>
      <TopBarController />
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleValidFormSubmit, handleInvalidFormSubmit)} noValidate>
          <ActionBar
            primaryButtonSettings={headerPrimaryButton}
            helperButtons={headerHelperButtons}
            backButtonSettings={headerBackButton}
          />
          <div className={containerPadding}>
            <hr />
            <div className="flex justify-between items-center py-5">
              <Heading size="100" className="w-1/3">
                Stocktake
              </Heading>
              <div className="w-1/3 flex justify-center items-center gap-2">
                {hasDraftAutosavingFailed ? (
                  <>
                    <div
                      className="flex justify-center items-center w-5 h-5 rounded-full bg-dangerDefault"
                      aria-hidden="true"
                    >
                      <MuiIcon
                        Icon={ErrorOutlineOutlined}
                        className="w-4.5 text-textIcon-whitePrimary"
                      />
                    </div>
                    <div>
                      <Paragraph size="100">Error saving draft</Paragraph>
                      <LinkButton
                        size="small"
                        variant="primary"
                        label="Retry"
                        onClick={retrySaveDraft}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <Paragraph size="100">Draft saved</Paragraph>
                    <div
                      className="flex justify-center items-center w-5 h-5 rounded-full bg-successDefault"
                      aria-hidden="true"
                    >
                      <MuiIcon
                        Icon={CheckCircleOutlineSharp}
                        className="w-4.5 text-textIcon-whitePrimary"
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="w-1/3 flex flex-col items-end gap-1">
                <StocktakeTotals
                  defaultValues={editableStocktakeDraftData}
                  currencyCode={currencyCode}
                />
              </div>
            </div>
            <hr />
          </div>
          <div className={classnames(containerPadding, "py-6")}>
            <div className="grid grid-cols-7 xl:grid-cols-4 gap-4.5">
              <div className="col-span-2 xl:col-span-1">
                <Heading size="200">PO Items</Heading>
                <Paragraph size="300" color="text-textIcon-blackSecondary" className="mt-3">
                  The item list shown is based on your previous orders and stocktake
                </Paragraph>
                <StocktakeCategories
                  className="mt-3"
                  categories={editableStocktakeDraftData.categories}
                  activeCategoryId={activeCategoryId}
                  onCategoryClick={handleCategoryClick}
                  totalNumberOfItems={totalNumberOfItems}
                />
              </div>
              <div className="col-span-5 xl:col-span-3">
                <StocktakeExtraItemsSection
                  className="mt-3"
                  newExtraItems={newExtraItems}
                  oldExtraItems={oldExtraItems}
                  isToggleAvailable={doesAnyCatalogItemExist}
                  isOpen={isExtraItemsSectionOpen}
                  setIsOpen={setIsExtraItemsSectionOpen}
                  showNoNewExtraItemsInfoPrerequisite={
                    doesAnyCatalogItemExist || doesAnyOldExtraItemExist
                  }
                  prependNewExtraItem={prependNewExtraItem}
                  removeNewExtraItem={removeNewExtraItem}
                  currencyCode={currencyCode}
                  unitOfMeasures={unitOfMeasures}
                />
                {doesAnyCatalogItemExist && <hr className="my-6" />}
                <StocktakeItemsList
                  categories={editableStocktakeDraftData.categories}
                  activeCategoryId={activeCategoryId}
                  items={items}
                />
                {!doesAnyItemExist && <StocktakeEditNothingToStocktakeInfo />}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};
