import ExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";
import classnames from "classnames";
import { ButtonHTMLAttributes, forwardRef } from "react";

import { Dropdown, DropdownItem, MuiIcon } from "@web/ui";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  currentValueLabel: string | undefined;
  placeholder: string;
  options: DropdownItem[];
};

export const ProductTileSelect = forwardRef<HTMLButtonElement, Props>(
  // Destruct `onClick` so it doesn't overwrite the `Dropdown` control
  ({ className, currentValueLabel, placeholder, options, onClick, ...props }, ref) => (
    <Dropdown hAlignTo="start" vAlignTo="bottom" items={options}>
      <button
        {...props}
        ref={ref}
        type="button"
        className={classnames(
          className,
          "flex w-full h-6.5 items-center justify-between px-2 gap-2",
          "border rounded-lg",
          "focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-white focus:ring-primaryDefault",
          "overflow-hidden whitespace-nowrap leading-none",
          currentValueLabel ? "text-textIcon-blackPrimary" : "text-textIcon-blackDisabled"
        )}
      >
        {currentValueLabel || placeholder}
        <MuiIcon Icon={ExpandMoreOutlined} className="w-4" />
      </button>
    </Dropdown>
  )
);
ProductTileSelect.displayName = "ProductTileSelect";
