import { useEffect, useRef, useState } from "react";

import { Modal } from "@web/ui";

import { NetworkConnectionLostModal } from "../../components/Modal/NetworkConnectionLostModal";
import { NetworkConnectionRestoredModal } from "../../components/Modal/NetworkConnectionRestoredModal";
import { useNetworkDetector } from "../NetworkDetector";

export const NetworkChangesLifecycle = () => {
  const { becameOnline, isOffline, wentOfflineFromOnline } = useNetworkDetector();
  const [isNoConnectionMsgShown, setIsNoConnectionMsgShown] = useState(false);
  const [isConnectionRestoredMsgShown, setIsConnectionRestoredMsgShown] = useState(false);

  const firstUpdate = useRef<boolean>(true);
  const isFirstUpdate = firstUpdate.current === true;

  useEffect(() => {
    if (isFirstUpdate) {
      firstUpdate.current = false;
      return;
    }
    const wasDisconnected = wentOfflineFromOnline;

    if (wasDisconnected) {
      // close modal with the opposite message first (in case it's displayed)
      setIsConnectionRestoredMsgShown(false);
      setIsNoConnectionMsgShown(true);
    }

    if (becameOnline) {
      // close modal with the opposite message first (in case it's displayed)
      setIsNoConnectionMsgShown(false);
      setIsConnectionRestoredMsgShown(true);
    }

    // Do not include `isFirstUpdate` in the dependency array - wait for changes in isOffline` flag
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOffline]);

  return (
    <>
      <Modal isOpen={isNoConnectionMsgShown} closeModal={() => setIsNoConnectionMsgShown(false)}>
        <NetworkConnectionLostModal
          hasAutomaticRestorationMsg={true}
          onClose={() => setIsNoConnectionMsgShown(false)}
        />
      </Modal>
      <Modal
        isOpen={isConnectionRestoredMsgShown}
        closeModal={() => setIsConnectionRestoredMsgShown(false)}
      >
        <NetworkConnectionRestoredModal onClose={() => setIsConnectionRestoredMsgShown(false)} />
      </Modal>
    </>
  );
};
