import { useCallback, useEffect, useMemo, useState } from "react";

import { Modal } from "@web/ui";

import { NoNetworkConnectionModal } from "src/components/NoNetworkConnectionModal";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";

export const useNetworkDependentAction = () => {
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isOnline } = useNetworkDetector();
  const [isOfflineWarningModalOpen, setIsOfflineWarningModalOpen] = useState<boolean>(false);
  const { becameOnline } = useNetworkDetector();

  useEffect(() => {
    if (becameOnline) {
      setIsOfflineWarningModalOpen(false);
    }
  }, [becameOnline, setIsOfflineWarningModalOpen]);

  const AllowOnlineOnlyWarningModal = useMemo(() => {
    const AllowOnlineOnlyWarningModalComponent = () => (
      <Modal
        isOpen={isOfflineWarningModalOpen}
        closeModal={() => setIsOfflineWarningModalOpen(false)}
      >
        <NoNetworkConnectionModal onClose={() => setIsOfflineWarningModalOpen(false)} />
      </Modal>
    );
    return AllowOnlineOnlyWarningModalComponent;
  }, [isOfflineWarningModalOpen]);

  const allowOnlineOnly = useCallback(
    (callback: () => void): void => {
      if (areOfflineCapabilitiesEnabled && !isOnline) {
        setIsOfflineWarningModalOpen(true);
        return;
      }

      callback();
    },
    [areOfflineCapabilitiesEnabled, isOnline]
  );

  return {
    allowOnlineOnly,
    AllowOnlineOnlyWarningModal,
  };
};
