import CheckCircle from "@mui/icons-material/CheckCircle";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import ErrorOutlineOutlined from "@mui/icons-material/ErrorOutlineOutlined";

import { OnChooseReplacement, ProductItemsTable } from "@web/common";
import { ProductItem, ProductItemReplacement } from "@web/common/network";
import { Heading, Label, MuiIcon, Paragraph, RegularButton } from "@web/ui";

type Props = {
  areAllItemsUnavailable: boolean;
  hasOnlyItemsWithChangedPrice: boolean;
  closeModal: () => void;
  onSubmit: () => void;
  onUpdateBasketClick: () => void;
  productsWithChangedPrice: ProductItem[];
  productsWithReplacements: ProductItem[];
  replacements: Record<string, Array<ProductItemReplacement>> | undefined;
  onChooseReplacement: OnChooseReplacement;
  numberOfChosenReplacements: number;
  productsWithoutReplacements: ProductItem[];
};

export const BasketVerificationModal = ({
  areAllItemsUnavailable,
  hasOnlyItemsWithChangedPrice,
  closeModal,
  onSubmit,
  onUpdateBasketClick,
  productsWithChangedPrice,
  productsWithReplacements,
  replacements,
  onChooseReplacement,
  productsWithoutReplacements,
  numberOfChosenReplacements,
}: Props) => {
  const unavailableProductsNo =
    productsWithoutReplacements.length + productsWithReplacements.length;
  const productsRemovedFromBasketNo = unavailableProductsNo - numberOfChosenReplacements;
  return (
    <div
      className="inline-block bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle"
      data-testid="basketVerificationModal"
    >
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={closeModal}
        >
          <DeleteOutline className="w-4 text-textIcon-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col w-130 m-5 pr-5" data-testid="basketVerificationModal__header">
        <Heading size="200">
          {hasOnlyItemsWithChangedPrice
            ? "These items have changed their price"
            : productsWithReplacements.length > 0
            ? "Please replace or remove items that are no longer available to submit your order"
            : "Please remove items that are no longer available to submit your order"}
        </Heading>
        <div className="flex flex-row mt-2">
          <div>
            <Paragraph size="100" color="text-textIcon-blackSecondary">
              Changes have occurred due to your supplier making adjustments to their catalog.
            </Paragraph>
          </div>
        </div>
      </div>
      <div
        className="bg-neutral_100 border-t-2 px-5 py-6 flex flex-col gap-4"
        data-testid="basketVerificationModal__content"
      >
        {unavailableProductsNo > 0 && (
          <div
            className="flex flex-col gap-4"
            data-testid="basketVerificationModal__unavailableProducts"
          >
            {areAllItemsUnavailable && productsWithReplacements.length === 0 ? (
              <Paragraph size="100" color="text-dangerDefault" className="flex items-center">
                <MuiIcon
                  Icon={ErrorOutlineOutlined}
                  className="w-4 mr-2 inline text-dangerDefault"
                />
                All your basket items are not available
              </Paragraph>
            ) : productsRemovedFromBasketNo === 0 ? (
              <Paragraph size="100" weight="heavy">
                <MuiIcon Icon={CheckCircle} className="w-4 h-4 inline mr-2 text-successDefault" />
                No products will be removed from your basket
              </Paragraph>
            ) : (
              <Paragraph size="100" weight="heavy">
                <MuiIcon Icon={ErrorOutline} className="w-4 h-4 inline mr-2 text-warningDefault" />
                {productsRemovedFromBasketNo} item{productsRemovedFromBasketNo === 1 ? "" : "s"}{" "}
                will be removed from your basket
              </Paragraph>
            )}

            {productsWithoutReplacements.length > 0 && (
              <>
                <div className="flex justify-between">
                  <Label size="300">Not Available</Label>
                  <Label size="300">
                    {productsWithoutReplacements.length} item
                    {productsWithoutReplacements.length === 1 ? "" : "s"}
                  </Label>
                </div>
                <ProductItemsTable
                  lineThrough={true}
                  productItems={productsWithoutReplacements}
                  preserveRightMargin={productsWithReplacements.length > 0}
                />
              </>
            )}

            {productsWithReplacements.length > 0 && (
              <>
                <div className="flex justify-between">
                  <Label size="300">Not Available - Replacement Found</Label>
                  <Label size="300">
                    {productsWithReplacements.length} item
                    {productsWithReplacements.length === 1 ? "" : "s"}
                  </Label>
                </div>
                <ProductItemsTable
                  lineThrough={true}
                  productItems={productsWithReplacements}
                  replacements={replacements}
                  onChooseReplacement={onChooseReplacement}
                  preserveRightMargin={true}
                />
              </>
            )}
          </div>
        )}
        {productsWithChangedPrice.length > 0 && (
          <div
            className="flex flex-col gap-4"
            data-testid="basketVerificationModal__productsWithChangedPrice"
          >
            <Paragraph size="100" weight="heavy">
              <MuiIcon Icon={ErrorOutline} className="w-4 h-4 inline mr-2 text-warningDefault" />
              {productsWithChangedPrice.length} item
              {productsWithChangedPrice.length > 1 ? "s" : ""}{" "}
              {productsWithChangedPrice.length > 1 ? "have" : "has"} changed price
            </Paragraph>
            <div className="flex justify-between">
              <Label size="300">Price changed</Label>
              <Label size="300">
                {productsWithChangedPrice.length} item
                {productsWithChangedPrice.length > 1 ? "s" : ""}
              </Label>
            </div>

            <ProductItemsTable
              productItems={productsWithChangedPrice}
              preserveRightMargin={productsWithReplacements.length > 0}
            />
          </div>
        )}
      </div>
      <div className="m-5 flex flex-row-reverse" data-testid="basketVerificationModal__footer">
        {hasOnlyItemsWithChangedPrice ? (
          <>
            <RegularButton variant="primary" size="large" label="Submit Order" onClick={onSubmit} />
            <RegularButton
              className="mr-4"
              variant="secondary"
              size="large"
              label="Back To Basket"
              onClick={onUpdateBasketClick}
            />
          </>
        ) : (
          <RegularButton
            variant="primary"
            size="large"
            label={
              productsRemovedFromBasketNo === 0
                ? "Replace items and Go to basket"
                : numberOfChosenReplacements > 0
                ? "Replace/remove items and Go to basket"
                : "Remove items and Go to basket"
            }
            onClick={onUpdateBasketClick}
          />
        )}
      </div>
    </div>
  );
};
