import { z } from "zod";

import { preprocessFormAmountValue } from "@web/utils";

import { ApiMoneySchema } from "../ApiMoneySchema";
import { LiteStocktakeExtraItemSchema } from "./LiteStocktakeExtraItemSchema";

export const StocktakeReportOldExtraItemFormSchema = LiteStocktakeExtraItemSchema.omit({
  quantity: true,
}).extend({
  // We want to have entityQuantity optional because we are filling empty values with zeros *after* form validation happens,
  // but we still want to validate that the input is nonnegative.
  entityQuantity: z.preprocess(
    preprocessFormAmountValue,
    z.number().nonnegative({ message: "Quantity must be 0 or greater" }).optional()
  ),
  singleUnitGrossPrice: ApiMoneySchema.extend({
    amount: z.number().optional(),
  }),
});

export type LocalStocktakeReportOldExtraItemForm = z.infer<
  typeof StocktakeReportOldExtraItemFormSchema
>;
