import { forwardRef, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { BasketExtraItemNameFieldPath, LocalBasketForm } from "src/models";

import { ProductTileTextInput } from "../../shared/ProductTileTextInput";
import { useFormFieldKeyboardEvents } from "../../shared/hooks";

type Props = {
  nameFieldPath: BasketExtraItemNameFieldPath;
};

export const ProductNameField = memo(
  forwardRef<HTMLInputElement, Props>(
    (
      { nameFieldPath },
      // We need to catch `ref`, so we don't get errors in runtime
      ref
    ) => {
      const { control } = useFormContext<LocalBasketForm>();
      const { handleKeyDown } = useFormFieldKeyboardEvents<HTMLInputElement>();

      return (
        <Controller
          name={nameFieldPath}
          control={control}
          render={({ field }) => (
            <ProductTileTextInput
              {...field}
              ref={ref}
              placeholder="Product name"
              onKeyDown={handleKeyDown}
              maxLength={200}
              aria-label="Product Name form field (required)"
            />
          )}
        />
      );
    }
  )
);
ProductNameField.displayName = "ProductNameField";
