import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  CategoriesTree,
  CloseAllDisclosureType,
  findHighestCategoryBySelectedId,
  getNonEmptyCategories,
} from "@web/common";
import { Category as CategoryType } from "@web/models";
import { Heading } from "@web/ui";
import { isDefined } from "@web/utils";

import { RepunchoutModal } from "src/components/Modal/RepunchoutModal";
import { NoCachedData } from "src/components/NoCachedData";
import { RoutesConfig, getCategoryByIdPath } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import useBasket from "src/hooks/useBasket";
import useCategories from "src/hooks/useCategories";
import { useNoCachedQueryGuard } from "src/hooks/useNoCachedQueryGuard";
import { useOfflineCatalogQuery } from "src/hooks/useOfflineCatalogQuery";
import BaseLayout from "src/layouts/Base";
import { LocalLitePunchoutAvailableItem, LocalLitePunchoutBasket } from "src/models";
import { LocalProductService } from "src/services/LocalProductService";

import ProductsList from "./ProductsList";
import { Breadcrumbs } from "./components/Breadcrumbs";

export const Category = () => {
  const categoriesTreeRef = useRef<CloseAllDisclosureType>(null);

  const navigate = useNavigate();

  const [{ isPunchoutSession, forcePreconfigureOrderSetup, orderType, port, supplier }] =
    useAppStateContext();

  useEffect(() => {
    if (forcePreconfigureOrderSetup) {
      navigate(RoutesConfig.gatherSetup);
    }
  }, [forcePreconfigureOrderSetup, navigate]);

  const { categoryId } = useParams() as { categoryId: string };
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const offlineCatalogQuery = useOfflineCatalogQuery({
    orderType,
    categoryId,
    portId: port?.id || "",
    supplierId: supplier?.id,
    disabled: forcePreconfigureOrderSetup,
  });
  const shouldDisableCategoriesQuery =
    forcePreconfigureOrderSetup ||
    offlineCatalogQuery.isFetching ||
    (areOfflineCapabilitiesEnabled && !offlineCatalogQuery.data?.hasCatalogData);
  const encodedCategoryId = encodeURIComponent(categoryId);
  const { categoriesQuery, invalidateQuery, getCategory, getBreadcrumb } = useCategories({
    disabled: shouldDisableCategoriesQuery,
  });
  const { pushCatalogItemToBasket, clearBasket } = useBasket();
  const [isRepunchoutVisible, setRepunchoutVisible] = useState(false);
  const category = getCategory(encodedCategoryId);
  const { hasNoCachedData } = useNoCachedQueryGuard({
    isQueryError:
      categoriesQuery.isError ||
      (isDefined(offlineCatalogQuery.data) && !offlineCatalogQuery.data.hasCatalogData),
    becameOnlineCallback: invalidateQuery,
  });

  const repunchoutBasket: LocalLitePunchoutBasket | undefined = useMemo(() => {
    try {
      const punchoutBasketStoredString = localStorage.getItem("repunchout") || "";
      return LocalProductService.convertFromStoredPunchoutBasketToPunchoutBasket(
        punchoutBasketStoredString
      );
    } catch {
      return undefined;
    }
  }, []);

  const [, dispatch] = useAppStateContext();

  useEffect(() => {
    if (categoryId) {
      dispatch({ type: "setLastGatherExpCategoryId", value: categoryId });
    }
  }, [dispatch, categoryId]);

  useEffect(() => {
    if (repunchoutBasket) {
      setRepunchoutVisible(true);
    }
  }, [repunchoutBasket]);

  const handleRepunchoutContinue = (availableItems: LocalLitePunchoutAvailableItem[]) => {
    localStorage.removeItem("repunchout");
    setRepunchoutVisible(false);
    clearBasket();
    availableItems.forEach((product: LocalLitePunchoutAvailableItem) => {
      pushCatalogItemToBasket(
        LocalProductService.convertPunchoutAvailableItemToBasketEntry(product)
      );
    });
  };

  const topCategories = useMemo(() => {
    return categoriesQuery.data !== undefined ? categoriesQuery.data : [];
  }, [categoriesQuery.data]);

  const nonEmptyCategories = useMemo(() => getNonEmptyCategories(topCategories), [topCategories]);
  const firstNonEmptyCategoryId = nonEmptyCategories[0]?.id;

  const handleCategoryClick = useCallback(
    (category: CategoryType) => {
      navigate(getCategoryByIdPath(category.id));
    },
    [navigate]
  );

  const activeCategoryId = category?.id || firstNonEmptyCategoryId;

  const selectedTopCategory = findHighestCategoryBySelectedId(nonEmptyCategories, activeCategoryId);

  const breadcrumbs = getBreadcrumb(encodedCategoryId);

  /** Closing all disclosures when changing route from a non top level category to top level category */
  const selectedCategory = findHighestCategoryBySelectedId(
    selectedTopCategory?.children ?? [],
    activeCategoryId ?? ""
  );
  const isAnyCategorySelected = !!selectedCategory?.id;
  const location = useLocation();
  const prevLocation = useRef(location);
  useEffect(() => {
    if (location.pathname === prevLocation.current.pathname && !isAnyCategorySelected) {
      categoriesTreeRef?.current?.closeAllDisclosures?.();
    }
    prevLocation.current = location;
  }, [location, prevLocation.current.pathname, isAnyCategorySelected]);
  /** Closing all disclosures when changing route from a non-top level category to top level category */

  if (isRepunchoutVisible && isPunchoutSession && repunchoutBasket) {
    return (
      <RepunchoutModal
        repunchoutBasket={repunchoutBasket}
        handleRepunchoutContinue={handleRepunchoutContinue}
      />
    );
  }

  if (hasNoCachedData) {
    return <NoCachedData hasGoBack={true} />;
  }

  return (
    <div className="h-full bg-neutral_100">
      <BaseLayout>
        {breadcrumbs.length > 1 && (
          <div className="ml-[-5vw] xl:ml-[-8vw] my-4">
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </div>
        )}
        {!!category?.name && (
          <div className="ml-[-5vw] xl:ml-[-8vw]">
            <Heading size="100" className="mr-auto mb-4 mt-4">
              {category.name}
            </Heading>
          </div>
        )}
        <div className="flex">
          <div className="w-[16vw] ml-[-5vw] xl:ml-[-8vw] h-[calc(100vh-54px)] sticky top-[54px] overflow-y-auto pr-[10px]">
            <CategoriesTree
              ref={categoriesTreeRef}
              categories={selectedTopCategory?.children ?? []}
              onCategoryClick={handleCategoryClick}
              openedByDefaultId={category?.id}
              activeCategoryId={activeCategoryId}
            />
          </div>
          <div className="flex flex-col items-center ml-2 xl:ml-6 w-[100%] mt-4">
            <ProductsList />
          </div>
        </div>
      </BaseLayout>
    </div>
  );
};
