import { useQuery, useQueryClient } from "@tanstack/react-query";
import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useFlag } from "@unleash/proxy-client-react";
import { useCallback, useMemo } from "react";

import { checkProductsAvailability } from "src/api/checkProductsAvailability";
import {
  LiteProductAvailabilityCheckRequest,
  LiteProductAvailabilityCheckResponse,
  ProductsService,
} from "src/typegens";

export const AVAILABLE_ITEMS_QUERY_KEY_BASE = "availableItems";

type UseAvailableItemsQuery = {
  query: UseQueryResult<LiteProductAvailabilityCheckResponse>;
  invalidate: () => Promise<void>;
};

export const useAvailableItemsQuery = ({
  items,
  portId,
  orderType,
  supplierId,
  disabled,
}: LiteProductAvailabilityCheckRequest & {
  disabled: boolean;
}): UseAvailableItemsQuery => {
  const isMissingItemsReplacementsEnabled = useFlag("va-missing-items-replacements");
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [
      AVAILABLE_ITEMS_QUERY_KEY_BASE,
      orderType,
      portId,
      supplierId,
      items,
      isMissingItemsReplacementsEnabled,
    ],

    [orderType, portId, supplierId, items, isMissingItemsReplacementsEnabled]
  );

  const query = useQuery<LiteProductAvailabilityCheckResponse>({
    queryKey,
    queryFn: async ({ signal }) => {
      if (isMissingItemsReplacementsEnabled) {
        return await checkProductsAvailability(
          {
            items,
            portId,
            orderType,
            supplierId,
          },
          { signal }
        );
      }

      const response = await ProductsService.getProductVariants(
        {
          requestBody: {
            portId,
            variantIds: items.map((item) => item.variantId),
            orderType,
            supplierId,
          },
        },
        { signal }
      );

      return {
        availableItems: response.items,
        replacementItems: {},
      };
    },
    enabled: !!portId && !!orderType && !!supplierId && !disabled,
    gcTime: 0,
  });

  const invalidate = useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey,
      }),
    [queryClient, queryKey]
  );

  return {
    query,
    invalidate,
  };
};
