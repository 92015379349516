import { XIcon } from "@heroicons/react/solid";
import { SvgIconComponent } from "@mui/icons-material";

import { Paragraph, RegularButton } from "../../../atoms";

interface Props {
  onConfirm: () => void;
  onClose: () => void;
  title: string;
  subtitle: string;
  closeButtonLabel: string;
  confirmButtonLabel: string;
  // TODO #12908: remove old type once all icons are migrated to MUI
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>> | SvgIconComponent;
  testIdPrefix?: string;
}

export const DangerConfirmationModal: React.FC<Props> = ({
  onConfirm,
  onClose,
  title,
  subtitle,
  closeButtonLabel,
  confirmButtonLabel,
  Icon,
  testIdPrefix = "dangerConfirmationModal",
}) => {
  return (
    <div
      className="inline-block align-bottom bg-neutral_0 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      data-testid={testIdPrefix}
    >
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary"
          data-testid={`${testIdPrefix}ModalXIcon`}
          onClick={onClose}
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5 text-textIcon-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start py-4 pl-2 pr-4">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center p-2 rounded-full bg-dangerBackground sm:mx-0">
          <Icon className="w-5 text-dangerDefault" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Paragraph size="100" data-testid={`${testIdPrefix}Title`}>
            {title}
          </Paragraph>
          <div className="mt-2">
            <Paragraph
              size="200"
              color="text-textIcon-blackSecondary"
              data-testid={`${testIdPrefix}Subtitle`}
            >
              {subtitle}
            </Paragraph>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 flex flex-col gap-3 sm:flex-row-reverse">
        <RegularButton
          variant="danger"
          size="large"
          label={confirmButtonLabel}
          onClick={() => {
            onConfirm();
            onClose();
          }}
          data-testid={`${testIdPrefix}ConfirmButton`}
        />
        <RegularButton
          variant="secondary"
          size="large"
          label={closeButtonLabel}
          onClick={onClose}
          data-testid={`${testIdPrefix}CancelButton`}
        />
      </div>
    </div>
  );
};
