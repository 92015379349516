import Dexie from "dexie";

import { isDefined, preprocessFormAmountValue } from "@web/utils";

import {
  LocalStocktakeReportDraft,
  LocalStocktakeReportDraftItem,
  LocalStocktakeReportDraftNewExtraItem,
  LocalStocktakeReportDraftOldExtraItem,
} from "src/models";

import { StoreNames, getStoreName } from "../config";

export class LocalStocktakeDraftsDatabase extends Dexie {
  stocktakeDrafts: Dexie.Table<LocalStocktakeReportDraft, string>;

  constructor() {
    super(getStoreName(StoreNames.STOCKTAKE_DRAFTS));

    this.version(0.1).stores({
      stocktakeDrafts: ",vesselId",
    });

    this.version(0.2)
      .stores({
        stocktakeDrafts: ",vesselId",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalStocktakeReportDraft>("stocktakeDrafts")
          .toCollection()
          .modify((stocktakeDraft) => {
            if (!stocktakeDraft.oldExtraItems) {
              stocktakeDraft.oldExtraItems = [];
            }
            if (!stocktakeDraft.newExtraItems) {
              stocktakeDraft.newExtraItems = [];
            }
          });
      });

    // Due to issues with compiling service files which import service files,
    // which import even more modules, the method of calculating the entity quantity
    // is replicated here - locally - for the purpose of migrating to v0.3
    const getItemEntityQuantityForUpgradeToV03 = (
      salesEntityQuantity: number,
      quantity: string | number | undefined
    ): number => {
      const defaultedQuantity = preprocessFormAmountValue(quantity) || 0;
      const nonnegativeQuantity = defaultedQuantity < 0 ? 0 : defaultedQuantity;
      return Math.round(salesEntityQuantity * nonnegativeQuantity * 100) / 100;
    };
    this.version(0.3)
      .stores({
        stocktakeDrafts: ",vesselId",
      })
      .upgrade((trans) => {
        return trans
          .table<LocalStocktakeReportDraft>("stocktakeDrafts")
          .toCollection()
          .modify((stocktakeDraft) => {
            // Extending the model with `quantity` that no longer exists on the model,
            // but exists on source data that being migrated
            const items: LocalStocktakeReportDraftItem[] = (
              stocktakeDraft.items as Array<LocalStocktakeReportDraftItem & { quantity?: number }>
            ).map(({ quantity, ...item }) =>
              !isDefined(item.entityQuantity)
                ? {
                    ...item,
                    entityQuantity: isDefined(quantity)
                      ? getItemEntityQuantityForUpgradeToV03(
                          item.skuDetails.salesEntityQuantity,
                          quantity
                        )
                      : undefined,
                  }
                : item
            );

            // Extending the model with `quantity` that no longer exists on the model,
            // but exists on source data that being migrated
            const oldExtraItems: LocalStocktakeReportDraftOldExtraItem[] = (
              stocktakeDraft.oldExtraItems as Array<
                LocalStocktakeReportDraftOldExtraItem & { quantity?: number }
              >
            ).map(({ quantity, ...oldExtraItem }) =>
              !isDefined(oldExtraItem.entityQuantity)
                ? {
                    ...oldExtraItem,
                    entityQuantity: quantity,
                  }
                : oldExtraItem
            );

            // Extending the model with `quantity` that no longer exists on the model,
            // but exists on source data that being migrated
            const newExtraItems: LocalStocktakeReportDraftNewExtraItem[] = (
              stocktakeDraft.newExtraItems as Array<
                LocalStocktakeReportDraftNewExtraItem & { quantity?: number }
              >
            ).map(({ quantity, ...newExtraItem }) =>
              !isDefined(newExtraItem.entityQuantity)
                ? {
                    ...newExtraItem,
                    entityQuantity: quantity,
                  }
                : newExtraItem
            );

            stocktakeDraft.items = items;
            stocktakeDraft.oldExtraItems = oldExtraItems;
            stocktakeDraft.newExtraItems = newExtraItems;
          });
      });
  }
}

export const stocktakeDraftsStore = new LocalStocktakeDraftsDatabase().stocktakeDrafts;
