import { PropsWithChildren } from "react";

import { useOfflineCapabilities } from "../OfflineCapabilities";
import { NetworkChangesLifecycle } from "./NetworkChangesLifecycle";

export const NetworkChangesProvider = ({ children }: PropsWithChildren) => {
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();

  return (
    <>
      {areOfflineCapabilitiesEnabled && <NetworkChangesLifecycle />}
      {children}
    </>
  );
};
