import CloseOutlined from "@mui/icons-material/CloseOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { useModalContext } from "@web/common/contexts/ModalContext";
import { MuiIcon, Paragraph, RegularButton } from "@web/ui";

import useBasket from "src/hooks/useBasket";
import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";

import { LEGACY_useCreateOrderDraftMutation } from "../../hooks/LEGACY_useCreateOrderDraftMutation";
import { LEGACY_useOrderDraft } from "../../hooks/LEGACY_useOrderDraft";
import { useCreateOrderDraftMutation } from "../../hooks/orderDrafts/useCreateOrderDraftMutation";
import { usePrepareOrderDraft } from "../../hooks/orderDrafts/usePrepareOrderDraft";
import { useNetworkDependentAction } from "../../hooks/useNetworkDependentAction";

interface Props {
  onBackToOverview: () => void;
}

const BackToOrderOverviewConfirmationModal: React.FC<Props> = ({ onBackToOverview }) => {
  const { closeModal } = useModalContext();
  const { getOrderDraftData: getDraftData } = LEGACY_useOrderDraft();
  const { clearOrderRequisitionFlow } = useBasket();

  const { mutate, isPending } = LEGACY_useCreateOrderDraftMutation();
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();

  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();
  const { getPartialOrderDraftData } = usePrepareOrderDraft();
  const { mutate: createDraft, isPending: isCreatingDraft } = useCreateOrderDraftMutation({
    hasSuccessMessage: true,
    hasErrorMessage: true,
    onSuccess: () => {
      closeModal();
      onBackToOverview();
    },
  });

  const saveDraftAndGoBackToOverview = () => {
    if (isOfflineDraftEnabled) {
      const draftToCreate = getPartialOrderDraftData();
      createDraft(draftToCreate);
      return;
    }

    allowOnlineOnly(() => {
      mutate(getDraftData());
      onBackToOverview();
      closeModal();
    });
  };

  const handleBackToOverviewWithoutSavingDraft = () => {
    clearOrderRequisitionFlow();
    onBackToOverview();
    closeModal();
  };

  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={() => closeModal()}
        >
          <span className="sr-only">Close modal</span>
          <MuiIcon
            Icon={CloseOutlined}
            className="w-5 text-textIcon-whiteDisabled"
            aria-hidden="true"
          />
        </button>
      </div>
      <div className="sm:flex sm:items-start py-4 pl-2 pr-4">
        <div className="mx-auto shrink-0 flex items-center justify-center p-2 rounded-full bg-dangerBackground sm:mx-0">
          <MuiIcon Icon={InfoOutlined} className="w-5 text-dangerDefault" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Paragraph size="100">Save basket as draft for later?</Paragraph>
          <div className="mt-2">
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              Leaving without saving will discard all your items currently in the basket.
            </Paragraph>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 flex flex-col gap-3 sm:flex-row-reverse">
        <RegularButton
          variant="primary"
          size="large"
          label="Save Basket as Draft"
          onClick={saveDraftAndGoBackToOverview}
          loading={isPending || isCreatingDraft}
        />
        <RegularButton
          variant="secondary"
          size="large"
          label="Discard Basket"
          onClick={handleBackToOverviewWithoutSavingDraft}
        />
      </div>
      <AllowOnlineOnlyWarningModal />
    </div>
  );
};

export default BackToOrderOverviewConfirmationModal;
