import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useFlag } from "@unleash/proxy-client-react";
import { useEffect, useState } from "react";

import { OnChooseReplacement } from "@web/common";
import { useModalContext } from "@web/common/contexts/ModalContext";
import { OrderRequisition } from "@web/common/network/model";
import { Paragraph, RegularButton, Strong } from "@web/ui";

import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import S2SLoader from "src/icons/S2SLoader.gif";
import { type LiteProductAvailabilityCheckResponse } from "src/typegens";

import { AllItemsAvailableModal } from "./AllItemsAvailableModal";
import { ItemsAvailabilityModalContainer } from "./ItemsAvailabilityModalContainer";
import { ChosenReplacements, ProductDiffList } from "./ProductDiffList";
import { OrderDraftModalCTAButtons } from "./components";

type Props = {
  draft: OrderRequisition;
  availableItemsQuery: UseQueryResult<LiteProductAvailabilityCheckResponse>;
  isDownloadingOfflineCatalog?: boolean;
};

export const CheckItemsAvailabilityModal = ({
  draft,
  availableItemsQuery,
  isDownloadingOfflineCatalog = false,
}: Props) => {
  const { closeModal } = useModalContext();
  const isMissingItemsReplacementsEnabled = useFlag("va-missing-items-replacements");
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isOffline } = useNetworkDetector();

  const error = availableItemsQuery.isError
    ? "There was a problem checking availability of your items. Please try again."
    : undefined;

  const [chosenReplacements, setChosenReplacements] = useState<ChosenReplacements>([]);

  const handleChooseReplacement: OnChooseReplacement = ({ replacementForId, replacementId }) => {
    setChosenReplacements((prev) => {
      return [
        ...prev.filter((item) => item.replacementForId !== replacementForId),
        ...(replacementId ? [{ replacementForId, replacementId }] : []),
      ];
    });
  };

  useEffect(() => {
    const replacements = availableItemsQuery.data?.replacementItems;
    if (replacements) {
      // Preselect all replacements once when they become available
      setChosenReplacements(
        Object.entries(replacements).map((replacement) => ({
          replacementForId: replacement[0],
          replacementId: replacement[1][0].id,
        }))
      );
    }
  }, [availableItemsQuery.data?.replacementItems]);

  if (availableItemsQuery.isError) {
    return (
      <ItemsAvailabilityModalContainer
        closeModal={closeModal}
        ctaButtons={
          <RegularButton
            className="ml-auto mr-5"
            variant="secondary"
            size="large"
            label="Cancel"
            onClick={() => closeModal()}
          />
        }
        isDraft
        loading={false}
        order={draft}
        error={error}
        title="Reviewing your draft items"
      />
    );
  }

  if (
    availableItemsQuery.isSuccess &&
    !availableItemsQuery.isFetching &&
    !isDownloadingOfflineCatalog
  ) {
    const ratio: [number, number] = [
      availableItemsQuery.data.availableItems.length,
      draft.items.length,
    ];

    if (availableItemsQuery.data.availableItems.length === draft.items.length) {
      return (
        <AllItemsAvailableModal
          draft={draft}
          liteProductList={availableItemsQuery.data.availableItems}
        />
      );
    }

    if (availableItemsQuery.data.availableItems.length < draft.items.length) {
      return (
        <ItemsAvailabilityModalContainer
          closeModal={closeModal}
          ctaButtons={
            <OrderDraftModalCTAButtons
              availableProducts={availableItemsQuery.data.availableItems}
              draft={draft}
              chosenReplacements={chosenReplacements}
              replacementItems={availableItemsQuery.data.replacementItems}
            />
          }
          isDraft
          loading={false}
          order={draft}
          ratio={ratio}
          title="Reviewing your draft items"
        >
          {areOfflineCapabilitiesEnabled &&
            isOffline &&
            isMissingItemsReplacementsEnabled &&
            !availableItemsQuery.isError && (
              <Paragraph size="200" color="text-warningDefault" className="mb-5">
                You are currently Offline and can only continue without unavailable items. In order
                to automatically find replacements for unavailable items, connect to the Internet
                and wait for the results to appear.{" "}
                <Strong>
                  Warning: Continuing now will permanently remove unavailable items from your Draft.
                </Strong>
              </Paragraph>
            )}
          <ProductDiffList
            sourceProducts={draft.items}
            availableItems={availableItemsQuery.data.availableItems}
            replacementItems={availableItemsQuery.data.replacementItems}
            chosenReplacements={chosenReplacements}
            onChooseReplacement={handleChooseReplacement}
          />
        </ItemsAvailabilityModalContainer>
      );
    }
  }

  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg py-5 overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full">
      <div className="absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="sr-only bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
          onClick={() => closeModal()}
        >
          <span className="sr-only">Close modal</span>
        </button>
      </div>
      <div className="flex flex-col items-center">
        <img className="w-8 h-8" src={S2SLoader} />
        <Paragraph size="200" className="mt-5">
          {isDownloadingOfflineCatalog ? (
            <>
              We are downloading the catalog data and this may take some time.
              <br /> Please do not refresh your browser or go to another page.
            </>
          ) : (
            "Checking if items are available..."
          )}
        </Paragraph>
      </div>
    </div>
  );
};
