import { NumericFormat } from "react-number-format";
import { NumericFormatProps } from "react-number-format/types/types";

type Props<T, Y> = {
  className?: string;
  InputComponent: T;
  withFractions: boolean;
} & NumericFormatProps &
  Y;

export function AmountField<T, Y>({
  className = "",
  withFractions,
  InputComponent,
  ...rest
}: Props<T, Y>) {
  return (
    <NumericFormat
      customInput={InputComponent}
      decimalScale={withFractions ? 2 : 0}
      fixedDecimalScale={true}
      thousandSeparator=","
      className={className}
      {...rest}
    />
  );
}
