import { getCategory } from "@web/common/utils/categories";

import { useAppStateContext } from "../contexts/AppStateContext";
import useCategoriesQuery from "./useCategoriesQuery";

const useCategories = ({
  disabled = false,
  portId: portIdProp,
}: {
  disabled?: boolean;
  portId?: string;
} = {}) => {
  const [{ port, orderType, supplier }] = useAppStateContext();

  // Defaulting undefined values to empty strings is to make the compiler happy.
  // Empty string will never be passed in a request because of the `enabled` prop config.
  const { query, invalidate } = useCategoriesQuery(
    {
      portId: portIdProp || port?.id || "",
      supplierId: supplier?.id || "",
      orderType: orderType,
    },
    {
      enabled: !!port?.id && !!orderType && !!supplier?.id && !disabled,
    }
  );

  return {
    categoriesQuery: query,
    invalidateQuery: invalidate,
    getCategory: (categoryId: string) => getCategory(categoryId, query.data || []).reverse()[0],
    getBreadcrumb: (categoryId: string) => getCategory(categoryId, query.data || []),
  };
};

export default useCategories;
