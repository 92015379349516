import {
  LiteProductAvailabilityCheckRequest,
  LiteProductAvailabilityCheckResponse,
  ProductsService,
} from "src/typegens";

import { ApiRequestOptions } from "../typegens/core/ApiRequestOptions";

export const checkProductsAvailability = async (
  requestBody: LiteProductAvailabilityCheckRequest,
  options?: Pick<ApiRequestOptions, "headers" | "signal">
): Promise<LiteProductAvailabilityCheckResponse> => {
  const result = await ProductsService.checkProductsAvailability({ requestBody }, options);
  return {
    ...result,
    // Set a default empty object if none exists in response.
    // This can happen since API does not send empty objects - such keys are removed from responses.
    ...(result.replacementItems ? {} : { replacementItems: {} }),
  };
};
