import { XIcon } from "@heroicons/react/outline";
import { TrashIcon } from "@heroicons/react/solid";

import { Paragraph, RegularButton } from "@web/ui";

interface Props {
  closeModal: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
}

export const DeleteStocktakeReportModal = ({ closeModal, onConfirm, isLoading = false }: Props) => {
  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary"
          onClick={closeModal}
          disabled={isLoading}
        >
          <span className="sr-only">Close modal</span>
          <XIcon className="h-5 w-5 text-textIcon-whiteDisabled" aria-hidden="true" />
        </button>
      </div>
      <div className="sm:flex sm:items-start py-4 pl-2 pr-4">
        <div className="mx-auto flex-shrink-0 flex items-center justify-center p-2 rounded-full bg-dangerBackground sm:mx-0">
          <TrashIcon className="w-5 text-dangerDefault" aria-hidden="true" />
        </div>
        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <Paragraph size="100">Are you sure you want to delete?</Paragraph>
          <div className="mt-2">
            <Paragraph size="200" color="text-textIcon-blackSecondary">
              Once the stocktake report has been deleted, it cannot be restored.
            </Paragraph>
          </div>
        </div>
      </div>
      <div className="mt-5 sm:mt-4 flex flex-col gap-3 sm:flex-row-reverse">
        <RegularButton
          variant="danger"
          size="large"
          label="Yes, delete stocktake"
          onClick={() => onConfirm()}
          loading={isLoading}
        />
        <RegularButton
          variant="secondary"
          size="large"
          label="Cancel"
          disabled={isLoading}
          onClick={closeModal}
        />
      </div>
    </div>
  );
};
