import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { isDefined } from "@web/utils";

import {
  LocalStocktakeReportForm,
  StocktakeReportItemEntityQuantityFieldPath,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemRobValueAmountFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";

import { useItemFormDetails } from "./useItemFormDetails";

type Params = {
  initialEntityQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  currencyCode: string;
  entityQuantityFieldPath: StocktakeReportItemEntityQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
  robValueAmountFieldPath: StocktakeReportItemRobValueAmountFieldPath;
};

// We are providing a way to update dependent values on the form model that change due to the user's
// inputs (while direct user's inputs are changed on the model automatically via connecting the input
// fields to the model)
export const useUpdateDependentFormFields = ({
  initialEntityQuantity,
  initialMeasurementUnit,
  initialSingleUnitGrossPriceAmount,
  currencyCode,
  entityQuantityFieldPath,
  singleUnitGrossPriceFieldPath,
  measurementUnitFieldPath,
  robValueAmountFieldPath,
}: Params): void => {
  const { setValue } = useFormContext<LocalStocktakeReportForm>();

  const { initialProductRobValue, modifiedProductRobValue } = useItemFormDetails({
    initialEntityQuantity,
    initialMeasurementUnit,
    initialSingleUnitGrossPriceAmount,
    currencyCode,
    entityQuantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
  });

  const [prevProductRobValueAmount, setPrevProductRobValueAmount] = useState<number | undefined>(
    initialProductRobValue?.amount
  );

  useEffect(() => {
    if (
      isDefined(modifiedProductRobValue?.amount) &&
      modifiedProductRobValue?.amount !== prevProductRobValueAmount
    ) {
      setValue(robValueAmountFieldPath, modifiedProductRobValue.amount);
      setPrevProductRobValueAmount(modifiedProductRobValue.amount);
    }
  }, [
    prevProductRobValueAmount,
    modifiedProductRobValue?.amount,
    robValueAmountFieldPath,
    setValue,
  ]);
};
