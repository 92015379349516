import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Vessel } from "@web/common";

import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import useBasket from "src/hooks/useBasket";
import { useToastMessage } from "src/hooks/useToastMessage";
import { BasketExtraItemFormSchema, LocalLiteOrderExtraItem, OrderStocktakeType } from "src/models";
import { LocalProductService } from "src/services/LocalProductService";
import { LifeRfqItem, LitePort } from "src/typegens";

type ValidatedOrderData = {
  port: LitePort;
  orderType: OrderStocktakeType;
  catalogItems: Array<{ variantId: string; entityQuantity: number }>;
  extraItems: LocalLiteOrderExtraItem[];
  supplierId: string;
  vessel: Vessel;
  rfqItems: LifeRfqItem[];
};

type UseValidateCheckoutOrder = {
  validateOrderWithSideEffects: () => ValidatedOrderData | undefined;
};

export const useValidateCheckoutOrder = (): UseValidateCheckoutOrder => {
  const navigate = useNavigate();
  const { setToastMessage } = useToastMessage();

  const [{ configuration, port, orderType, supplier }] = useAppStateContext();
  const vessel = configuration?.vessel;
  const hasRfQFeature =
    configuration?.fleet.permissions.includes("CREATE_REQUISITION") &&
    configuration.fleet.allow.createRfq;

  const { getOrderItems, extraItems, rfqItems } = useBasket();

  const supplierId = supplier?.id;

  const validateOrderWithSideEffects = useCallback(() => {
    // These are all edge cases and should never happen.
    if (!configuration) {
      setToastMessage({
        type: "failure",
        message:
          "No configuration, please try refreshing the page. If this does not help, please contact Customer Support.",
      });
      return;
    }
    if (!port) {
      setToastMessage({
        type: "failure",
        message: "No port selected. Please try again or contact Customer Support.",
      });
      return;
    }
    if (!orderType) {
      setToastMessage({
        type: "failure",
        message: "No order type selected. Please try again or contact Customer Support.",
      });
      return;
    }
    if (!vessel) {
      setToastMessage({
        type: "failure",
        message: "No vessel information. Please try again or contact Customer Support.",
      });
      return;
    }

    const requestCatalogItems = getOrderItems();

    // This is an edge case and should never happen.
    if (!hasRfQFeature && requestCatalogItems.length === 0) {
      setToastMessage({
        type: "failure",
        message:
          "There are no catalog items in the Basket. Please add at least 1 catalog item to the Basket to place your order.",
      });
      navigate(RoutesConfig.basket);
      return;
    }

    if (hasRfQFeature && requestCatalogItems.length === 0 && rfqItems.length === 0) {
      setToastMessage({
        type: "failure",
        message:
          "There are no items in the Basket. Please add at least 1 catalog or RFQ item to the Basket to place your requisition.",
      });
      navigate(RoutesConfig.basket);
      return;
    }

    if (!supplierId) {
      setToastMessage({
        type: "failure",
        message: "Unknown supplier. Please try again or contact Customer Support.",
      });
      return;
    }

    const requestExtraItems: LocalLiteOrderExtraItem[] =
      LocalProductService.convertLocalExtraItemsToApiRequest(extraItems);

    // This is an edge case and should never happen.
    const areExtraItemsValid = !requestExtraItems.some(
      (extraItem) => !BasketExtraItemFormSchema.safeParse(extraItem).success
    );
    if (!areExtraItemsValid) {
      setToastMessage({
        type: "failure",
        message: "Extra Items contain errors. Please correct them and go to checkout once again.",
      });
      navigate(RoutesConfig.basket);
      return;
    }

    return {
      port,
      orderType,
      catalogItems: requestCatalogItems,
      extraItems: requestExtraItems,
      supplierId,
      vessel,
      rfqItems,
    };
  }, [
    configuration,
    extraItems,
    getOrderItems,
    hasRfQFeature,
    navigate,
    orderType,
    port,
    rfqItems,
    setToastMessage,
    supplierId,
    vessel,
  ]);

  return {
    validateOrderWithSideEffects,
  };
};
