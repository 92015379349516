export const pingNetworkStateResource: (url: string, timeout: number) => () => Promise<boolean> =
  (url: string, timeout: number) => () => {
    return new Promise((resolve) => {
      const online = () => resolve(true);
      const offline = () => resolve(false);

      const xhr = new XMLHttpRequest();

      xhr.onerror = offline;
      xhr.ontimeout = offline;
      xhr.onreadystatechange = () => {
        if (xhr.readyState === xhr.HEADERS_RECEIVED) {
          if (xhr.status > 0) {
            online();
          } else {
            offline();
          }
        }
      };

      xhr.open("GET", url);
      xhr.setRequestHeader("Cache-Control", "no-cache, max-age=0, must-revalidate");
      xhr.setRequestHeader("Pragma", "no-cache");
      xhr.timeout = timeout;
      xhr.send();
    });
  };
