import {
  BehaviorSubject,
  Subject,
  Subscription,
  bufferCount,
  concat,
  concatMap,
  distinctUntilChanged,
  from,
  interval,
  map,
  switchMap,
  tap,
} from "rxjs";

import { PING_INTERVAL, PING_URL_RESOURCE } from "src/config/constants";
import { pingNetworkStateResource } from "src/utils";

type PingResult = {
  isOnline: boolean;
  isShaky: boolean;
};

export class NetworkStatePinger {
  private url: string;
  private defaultTimeoutAndInterval: number;
  private intervalSubject: BehaviorSubject<number>;
  private pingSubscription: Subscription | null = null;

  private isFirstPing = true;
  private isOnline = true;

  // Track consecutive failures for dynamic interval adjustments
  private consecutiveFails = 0;

  public result$: Subject<PingResult> = new Subject<PingResult>();

  constructor(url: string, timeout: number) {
    this.url = url;
    this.defaultTimeoutAndInterval = timeout;
    this.intervalSubject = new BehaviorSubject<number>(this.defaultTimeoutAndInterval);

    // We won't store a single `ping` function because we may dynamically
    // change the timeout based on network conditions. We'll create it on the fly.
  }

  startPinging() {
    const firstPing = from(this.getPingFunction(this.defaultTimeoutAndInterval)());

    const intervalPings$ = this.intervalSubject.pipe(
      distinctUntilChanged(), // Only re-trigger if interval actually changed
      switchMap((intervalMs) =>
        interval(intervalMs).pipe(concatMap(() => from(this.getPingFunction(intervalMs)())))
      )
    );

    this.pingSubscription = concat(firstPing, intervalPings$)
      .pipe(
        tap((isOnline) => {
          this.handlePingOutcome(isOnline);

          if (this.isFirstPing) {
            this.isFirstPing = false;
            this.isOnline = isOnline;

            // Yield the result of first ping immediately
            this.result$.next({ isOnline, isShaky: false });
          }
        }),
        bufferCount(5, 1), // Buffer the results in a window of 5 last pings
        map((buffer) => {
          const lastThreePings = buffer.slice(2);
          const lastFivePings = buffer;
          const wereLastThreePingsAllSame = lastThreePings.every(
            (value) => value === lastThreePings[0]
          );
          const wereLastFivePingsAllSame = lastFivePings.every(
            (value) => value === lastFivePings[0]
          );
          // Last 3 pings are used to establish network condition state,
          // if all 3 pings yielded the same result, emit that as a `isOnline` value.
          // Otherwise use previous value
          if (wereLastThreePingsAllSame) {
            this.isOnline = lastThreePings[0];
          }

          return {
            isOnline: this.isOnline,
            // Last 5 pings are used to establish "shakiness" of network conditions.
            // If at least of one of six pings yielded different result, then mark connection as shaky.
            isShaky: !wereLastFivePingsAllSame,
          };
        }),
        distinctUntilChanged(
          (prev, curr) => prev.isOnline === curr.isOnline && prev.isShaky === curr.isShaky
        ) // Emit only when the current value is different than the last
      )
      .subscribe((result) => this.result$.next(result));
  }

  stopPinging() {
    if (this.pingSubscription) {
      this.pingSubscription.unsubscribe();
      this.pingSubscription = null;
    }
    this.isFirstPing = true;
    this.consecutiveFails = 0;
    // Reset intervalSubject to default in case we had changed it
    this.intervalSubject.next(this.defaultTimeoutAndInterval);
  }

  /**
   * Dynamically create a ping function with a *timeout equal to the interval*
   */
  private getPingFunction(intervalMs: number) {
    return pingNetworkStateResource(this.url, intervalMs);
  }

  /**
   * Method to check if the ping failed or succeeded.
   * If 3 consecutive fails occur, we revert to the default interval.
   * If 1 or 2 fails occur, we increase the interval.
   */
  private handlePingOutcome(isOnline: boolean) {
    if (!isOnline) {
      this.consecutiveFails += 1;
    } else {
      this.consecutiveFails = 0;
    }

    if (this.consecutiveFails === 1) {
      // First fail => increase interval
      this.intervalSubject.next(this.defaultTimeoutAndInterval * 2);
    } else if (this.consecutiveFails === 2) {
      // Second fail => increase interval more
      this.intervalSubject.next(this.defaultTimeoutAndInterval * 3);
    } else if (this.consecutiveFails >= 3) {
      // After 3 fails => user considered offline, revert interval
      this.intervalSubject.next(this.defaultTimeoutAndInterval);
    }
  }
}

export const networkStatePinger = new NetworkStatePinger(PING_URL_RESOURCE, PING_INTERVAL);
