import { useFlag } from "@unleash/proxy-client-react";
import { useNavigate } from "react-router-dom";

import { useModalContext } from "@web/common/contexts/ModalContext";
import { OrderRequisition } from "@web/common/network/model";
import { RegularButton } from "@web/ui";

import { useOfflineDraftEnabled } from "src/hooks/useOfflineDraftEnabled";

import { RoutesConfig } from "../../../config/routes";
import { LEGACY_useDeleteOrderDraftMutation } from "../../../hooks/LEGACY_useDeleteOrderDraftMutation";
import { useDeleteOrderDraftMutation } from "../../../hooks/orderDrafts/useDeleteOrderDraftMutation";
import { useAddProductsToBasket } from "../../../hooks/useAddProductsToBasket";
import useBasket from "../../../hooks/useBasket";
import { useNetworkDependentAction } from "../../../hooks/useNetworkDependentAction";
import { LiteProductSku } from "../../../typegens";
import { ChosenReplacements } from "../ProductDiffList";

interface Props {
  availableProducts: LiteProductSku[];
  chosenReplacements: ChosenReplacements;
  replacementItems: Record<string, Array<LiteProductSku>>;
  draft: OrderRequisition;
}

export const OrderDraftModalCTAButtons = ({
  availableProducts,
  draft,
  chosenReplacements,
  replacementItems,
}: Props) => {
  const { setDraft } = useBasket();
  const { closeModal } = useModalContext();
  const { addProductsToBasket } = useAddProductsToBasket({
    catalogItems: draft.items,
    deliveryDate: draft.deliveryDate,
    dutyFreeDeclaration: draft.dutyFreeDeclaration,
    port: draft.port,
    rfqItems: draft.rfqItems || [],
    orderType: draft.orderType,
    subject: draft.subject,
    extraItems: draft.extraItems,
    supplierInformation: draft.supplierInformation,
  });
  const noItemsAvailable = availableProducts.length === 0 && chosenReplacements.length === 0;
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();
  const isMissingItemsReplacementsEnabled = useFlag("va-missing-items-replacements");

  const { mutate, isPending } = LEGACY_useDeleteOrderDraftMutation({
    onSuccess: () => {
      closeModal();
    },
    shouldNavigate: true,
  });

  const { isOfflineDraftEnabled } = useOfflineDraftEnabled();
  const navigate = useNavigate();
  const { mutate: deleteDraft, isPending: isDeletingDraft } = useDeleteOrderDraftMutation({
    hasSuccessMessage: true,
    hasErrorMessage: true,
    onSuccess: () => {
      closeModal();
      navigate(RoutesConfig.order.overview);
    },
  });

  const handleAddingProductsToBasket = () => {
    const replacements = chosenReplacements.reduce<
      Array<{ sku: LiteProductSku; quantity: number }>
    >((acc, cur) => {
      const replacement = replacementItems[cur.replacementForId].find(
        (replacement) => replacement.id === cur.replacementId
      );
      if (!replacement) {
        return acc;
      }
      acc.push({
        sku: { ...replacement },
        quantity:
          draft.items.find((item) => item.variantId === cur.replacementForId)?.quantity || 1,
      });
      return acc;
    }, []);
    addProductsToBasket(availableProducts, replacements);
    setDraft({ draftId: draft.id, updatedAt: draft.updatedAt.toISOString() });
    closeModal();
  };

  const handleDeletingDraft = () => {
    if (isOfflineDraftEnabled) {
      deleteDraft(draft.id);
      return;
    }

    allowOnlineOnly(() => {
      mutate(draft.id);
    });
  };

  return (
    <>
      <RegularButton
        className="ml-auto mr-5"
        variant="secondary"
        size="large"
        label="Cancel"
        onClick={() => closeModal()}
      />
      <RegularButton
        variant="primary"
        size="large"
        label={
          !isMissingItemsReplacementsEnabled
            ? noItemsAvailable
              ? "Delete Draft and Continue"
              : "Update Draft with Available Items"
            : noItemsAvailable
            ? "Delete Draft and Continue"
            : chosenReplacements.length > 0
            ? "Update Draft with Chosen Items"
            : "Update Draft with Available Items"
        }
        disabled={isPending || isDeletingDraft}
        onClick={noItemsAvailable ? handleDeletingDraft : handleAddingProductsToBasket}
      />
      <AllowOnlineOnlyWarningModal />
    </>
  );
};
