import { ReactNode, memo } from "react";

import { Money } from "@web/models";
import { Paragraph } from "@web/ui";
import { formatMoney, formatNumber, isDefined, preprocessFormAmountValue } from "@web/utils";

import { LocalStocktakeService } from "src/services/LocalStocktakeService";

import { ProductTileFakeTextInput } from "../../../shared/ProductTileFakeTextInput";

type Props<T> = {
  product: T;
  renderProductDetails: (product: T, className: string) => ReactNode;
  quantity: number;
  salesEntityQuantity: number;
  measurementUnit: string;
  singleUnitGrossPrice: Money | undefined;
  robValue: Money;
};

function _StocktakeDetailsProductTile<T>({
  product,
  renderProductDetails,
  salesEntityQuantity,
  measurementUnit,
  singleUnitGrossPrice,
  quantity,
  robValue,
}: Props<T>) {
  const entityQuantity = LocalStocktakeService.getItemEntityQuantity(
    salesEntityQuantity,
    preprocessFormAmountValue(quantity)
  );
  return (
    <div className="flex flex-col xl:flex-row justify-between items-center px-4 py-2 gap-2 xl:gap-4 bg-neutral_0 border border-neutral_300 rounded-xl">
      {renderProductDetails(product, "self-stretch")}
      <div
        className="flex-none self-stretch border-t-1 border-neutral_300 xl:hidden"
        role="presentation"
      ></div>
      <div className="self-stretch flex-none flex justify-end items-center gap-4">
        <div
          className="flex-none self-stretch border-r-1 border-neutral_300 hidden xl:block"
          role="presentation"
        ></div>
        <div className="flex-none flex items-center gap-1">
          <Paragraph size="200" weight="heavy" color="text-textIcon-blackSecondary">
            ROB:
          </Paragraph>
          <div className="w-11">
            <ProductTileFakeTextInput
              value={`${formatNumber(entityQuantity)} ${measurementUnit}`}
              alignTo="center"
            />
          </div>
        </div>
        <div
          className="flex-none self-stretch border-r-1 border-neutral_300"
          role="presentation"
        ></div>
        <div className="flex-none flex items-center flex-wrap w-[150px] gap-1 overflow-clip">
          <Paragraph size="200" weight="heavy" color="text-textIcon-blackSecondary">
            Value:
          </Paragraph>
          <Paragraph size="200" weight="heavy" className="whitespace-nowrap">
            {isDefined(singleUnitGrossPrice) ? formatMoney(robValue) : "Not Provided"}
          </Paragraph>
        </div>
      </div>
    </div>
  );
}
export const StocktakeDetailsProductTile = memo(
  _StocktakeDetailsProductTile
) as typeof _StocktakeDetailsProductTile & {
  displayName: string;
};
StocktakeDetailsProductTile.displayName = "StocktakeDetailsProductTile";
