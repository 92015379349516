import { useFlag } from "@unleash/proxy-client-react";
import { ChangeEvent, FormEvent, useState } from "react";

import { useSearchHelpers } from "@web/common";

import { BrowseBar } from "src/components/BrowseBar";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import useCategories from "src/hooks/useCategories";
import { useOfflineCatalogQueryHelpers } from "src/hooks/useOfflineCatalogQuery";

type GatherControlBarProps = {
  searchRoute: string;
};

export const GatherControlBar: React.FC<GatherControlBarProps> = ({ searchRoute }) => {
  const { query, setQuery } = useSearchHelpers(searchRoute);
  const [typedQuery, setTypedQuery] = useState(query);
  const [{ port, configuration, orderType, supplier }] = useAppStateContext();
  const { isOnline } = useNetworkDetector();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const isOfflineSearchEnabled =
    useFlag("offline-products-search") && areOfflineCapabilitiesEnabled;

  const { isDownloadingOfflineCatalog, hasOfflineCatalogData } = useOfflineCatalogQueryHelpers({
    portId: port?.id || "",
    orderType,
    categoryId: undefined,
    supplierId: supplier?.id,
  });
  const { categoriesQuery } = useCategories({
    disabled:
      isDownloadingOfflineCatalog || (areOfflineCapabilitiesEnabled && !hasOfflineCatalogData),
  });

  if (categoriesQuery.status !== "success") {
    return null;
  }

  if (!configuration) {
    return null;
  }

  const handleQueryChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTypedQuery(e.target.value);
  };

  const handleSearchSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (typedQuery.length > 0) {
      setQuery(typedQuery);
    }
  };

  return (
    <BrowseBar
      selectedPort={port}
      categorySelectorSettings={{
        categories: categoriesQuery.data,
      }}
      searchBarSettings={{
        placeholder: "Search...",
        onSubmit: handleSearchSubmit,
        onQueryChange: handleQueryChange,
        query: typedQuery,
        isOnline: areOfflineCapabilitiesEnabled ? isOnline : true,
        isOfflineSearchEnabled,
      }}
    />
  );
};
