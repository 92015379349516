import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OnChooseReplacement } from "@web/common";
import { ProductItem, ProductItemReplacement } from "@web/common/network";
import { Modal } from "@web/ui";
import { isDefined, usePrevious } from "@web/utils";

import { BasketVerificationModal as BasketVerificationModalUi } from "src/components/Modal/BasketVerificationModal";
import { RoutesConfig } from "src/config/routes";
import { BasketEntry } from "src/contexts/BasketContext";
import useBasket from "src/hooks/useBasket";
import { LocalProductService } from "src/services/LocalProductService";
import { LiteProductSku } from "src/typegens";
import { pairProductsWithVariants } from "src/utils";

import { ChosenReplacements } from "../components/Modal/ProductDiffList";

export const useBasketVerification = () => {
  const [isBasketVerificationModalOpen, setIsBasketVerificationModalOpen] = useState(false);
  const [originalBasketItems, setOriginalBasketItems] = useState<BasketEntry[]>([]);
  const [unavailableProducts, setUnavailableProducts] = useState<ProductItem[]>([]);
  const [areAllItemsUnavailable, setAreAllItemsUnavailable] = useState(false);
  const [productsWithChangedPrice, setProductsWithChangedPrice] = useState<ProductItem[]>([]);
  const [onSubmit, setOnSubmit] = useState<() => void>(() => {});

  const [chosenReplacements, setChosenReplacements] = useState<ChosenReplacements>([]);
  const handleChooseReplacement: OnChooseReplacement = ({ replacementForId, replacementId }) => {
    setChosenReplacements((prev) => {
      return [
        ...prev.filter((item) => item.replacementForId !== replacementForId),
        ...(replacementId ? [{ replacementForId, replacementId }] : []),
      ];
    });
  };

  const [replacements, setReplacements] = useState<Record<string, Array<LiteProductSku>>>();
  const prevReplacements = usePrevious(replacements);
  useEffect(() => {
    if (!!replacements && prevReplacements !== replacements) {
      // Preselect all replacements
      setChosenReplacements(
        Object.entries(replacements).map((replacement) => ({
          replacementForId: replacement[0],
          replacementId: replacement[1][0].id,
        }))
      );
    }
  }, [replacements, prevReplacements]);

  const replacementProducts: Record<string, Array<ProductItemReplacement>> | undefined = useMemo(
    () =>
      replacements
        ? Object.entries(replacements).reduce<Record<string, Array<ProductItemReplacement>>>(
            (acc, cur) => {
              const replacementFor = unavailableProducts.find(
                (item) => item.skuDetails.id === cur[0]
              );
              acc[cur[0]] = cur[1].map((replacement) => ({
                ...LocalProductService.convertSkuToProductItem(
                  replacement,
                  replacementFor?.entityQuantity || 1
                ),
                isChosen: !!chosenReplacements.find(
                  (info) =>
                    info.replacementForId === cur[0] && info.replacementId === replacement.id
                ),
              }));
              return acc;
            },
            {}
          )
        : undefined,
    [chosenReplacements, replacements, unavailableProducts]
  );

  const unavailableProductsWithReplacements = useMemo(
    () => unavailableProducts.filter((item) => item.skuDetails.id in (replacementProducts ?? {})),
    [replacementProducts, unavailableProducts]
  );
  const unavailableProductsWithoutReplacements = useMemo(
    () =>
      unavailableProducts.filter((item) => !(item.skuDetails.id in (replacementProducts ?? {}))),
    [replacementProducts, unavailableProducts]
  );

  const { addMultipleCatalogItemsToBasket, removeMultipleEntriesFromBasket } = useBasket();
  const navigate = useNavigate();

  const hasOnlyItemsWithChangedPrice =
    unavailableProducts.length === 0 && productsWithChangedPrice.length > 0;

  const updateBasketHandler = useCallback(() => {
    const unavailableProductsIds = new Set(unavailableProducts.map((p) => p.skuDetails.id));
    const replacementBasketItems = replacements
      ? chosenReplacements.reduce<Array<{ sku: LiteProductSku; quantity: number }>>((acc, cur) => {
          const replacement = replacements[cur.replacementForId].find(
            (replacement) => replacement.id === cur.replacementId
          );
          if (!replacement) {
            return acc;
          }
          acc.push({
            sku: { ...replacement },
            quantity:
              originalBasketItems.find((item) => item.sku.id === cur.replacementForId)?.quantity ||
              1,
          });
          return acc;
        }, [])
      : [];
    removeMultipleEntriesFromBasket(unavailableProductsIds);
    addMultipleCatalogItemsToBasket([
      ...productsWithChangedPrice.map((product) =>
        LocalProductService.convertProductItemToBasketEntry(product)
      ),
      ...replacementBasketItems,
    ]);
    setIsBasketVerificationModalOpen(false);
    navigate(RoutesConfig.basket);
  }, [
    addMultipleCatalogItemsToBasket,
    chosenReplacements,
    navigate,
    originalBasketItems,
    productsWithChangedPrice,
    removeMultipleEntriesFromBasket,
    replacements,
    unavailableProducts,
  ]);

  const submitHandler = useCallback(() => {
    // submission may fail, but we want to update basket anyway
    addMultipleCatalogItemsToBasket(
      productsWithChangedPrice.map((product) =>
        LocalProductService.convertProductItemToBasketEntry(product)
      )
    );
    setIsBasketVerificationModalOpen(false);
    onSubmit();
  }, [addMultipleCatalogItemsToBasket, onSubmit, productsWithChangedPrice]);

  const findChangedProducts = useCallback(
    ({
      lineItems,
      availableItems,
      replacementItems,
      submitCallback,
    }: {
      lineItems: BasketEntry[];
      availableItems: LiteProductSku[];
      replacementItems: Record<string, Array<LiteProductSku>>;
      submitCallback: () => void;
    }) => {
      const productsInBasket = lineItems.map((item) => {
        return {
          variantId: item.sku.id,
          ...item,
        };
      });

      const productVariantPairs = pairProductsWithVariants(productsInBasket)(availableItems);
      const unavailableProducts = productVariantPairs
        .filter(([, sku]) => !isDefined(sku))
        .map(([entry]) => LocalProductService.convertBasketEntryToProductItem(entry));

      if (unavailableProducts.length === lineItems.length) {
        setAreAllItemsUnavailable(true);
      }

      const productsWithChangedPrice = productVariantPairs
        .filter(([, sku]) => isDefined(sku))
        .filter(
          ([product, sku]) => sku.price.costPrice.amount !== product.sku.price.costPrice.amount
        )
        .map(([entry, sku]) =>
          LocalProductService.convertBasketEntrySkuPairToProductItem(entry, sku)
        );

      if (unavailableProducts.length > 0) {
        setOriginalBasketItems(lineItems);
        setUnavailableProducts(unavailableProducts);
        setReplacements(replacementItems);
        setProductsWithChangedPrice(productsWithChangedPrice);
        setIsBasketVerificationModalOpen(true);
      } else if (productsWithChangedPrice.length > 0) {
        setProductsWithChangedPrice(productsWithChangedPrice);
        setOnSubmit(() => submitCallback);
        setIsBasketVerificationModalOpen(true);
      }

      return { unavailableProducts, productsWithChangedPrice };
    },
    []
  );

  const BasketVerificationModal = useMemo(() => {
    const BasketVerificationModalComponent = () => (
      <Modal
        isOpen={isBasketVerificationModalOpen}
        closeModal={() => setIsBasketVerificationModalOpen(false)}
      >
        <BasketVerificationModalUi
          areAllItemsUnavailable={areAllItemsUnavailable}
          hasOnlyItemsWithChangedPrice={hasOnlyItemsWithChangedPrice}
          closeModal={() => setIsBasketVerificationModalOpen(false)}
          onSubmit={submitHandler}
          onUpdateBasketClick={updateBasketHandler}
          productsWithChangedPrice={productsWithChangedPrice}
          productsWithoutReplacements={unavailableProductsWithoutReplacements}
          productsWithReplacements={unavailableProductsWithReplacements}
          replacements={replacementProducts}
          onChooseReplacement={handleChooseReplacement}
          numberOfChosenReplacements={chosenReplacements.length}
        />
      </Modal>
    );

    return BasketVerificationModalComponent;
  }, [
    areAllItemsUnavailable,
    chosenReplacements.length,
    hasOnlyItemsWithChangedPrice,
    isBasketVerificationModalOpen,
    productsWithChangedPrice,
    replacementProducts,
    submitHandler,
    unavailableProductsWithReplacements,
    unavailableProductsWithoutReplacements,
    updateBasketHandler,
  ]);

  return { findChangedProducts, BasketVerificationModal };
};
