import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { OrderContextMenuActions } from "@web/common";
import { CancelOrderModal } from "@web/common/components/modals/CancelOrderModal";
import { CancelSuccessModal } from "@web/common/components/modals/CancelSuccessModal";
import { DeleteOrderDraftModal } from "@web/common/components/modals/DeleteOrderDraftModal";
import { useModalContext } from "@web/common/contexts/ModalContext";
import { Loading, Modal } from "@web/ui";
import { triggerFileDownload } from "@web/utils";

import { exportOrderToExcel } from "src/api/exportOrderToExcel";
import { exportOrderToMtml } from "src/api/exportOrderToMtml";
import { CloseOrderModal } from "src/components/Modal";
import { NoCachedData } from "src/components/NoCachedData";
import { LEGACY_useDeleteOrderDraftMutation } from "src/hooks/LEGACY_useDeleteOrderDraftMutation";
import { useNetworkDependentAction } from "src/hooks/useNetworkDependentAction";
import { useNoCachedQueryGuard } from "src/hooks/useNoCachedQueryGuard";
import useOrder from "src/hooks/useOrder";
import { useToastMessage } from "src/hooks/useToastMessage";

import { useOrderRequisitionListQueryHelpers } from "../../hooks/useOrderRequisitionListQuery";
import { OrderDetails } from "./components";

export const LEGACY_OrderDetails: React.FC = () => {
  const { id } = useParams() as { id: string };
  const { orderQuery, invalidateOrderQuery, cancelOrderRequisitionMutation, closeOrderMutation } =
    useOrder(id);
  const { data: orderData } = orderQuery;

  const { invalidateAll: invalidateAllOrderRequisitionsQueries } =
    useOrderRequisitionListQueryHelpers();

  const { closeModal, openModal } = useModalContext();
  const { mutate: deleteDraftMutation, isPending: isDeleteDraftMutationPending } =
    LEGACY_useDeleteOrderDraftMutation({
      onSuccess: () => {
        setIsDeleteDraftModalOpen(false);
      },
      shouldNavigate: true,
    });
  const { allowOnlineOnly, AllowOnlineOnlyWarningModal } = useNetworkDependentAction();
  const { setToastMessage } = useToastMessage();
  const [isCloseOrderModalOpen, setIsCloseOrderModalOpen] = useState(false);
  const [isCancelOrderModalOpen, setIsCancelOrderModalOpen] = useState(false);
  const [isDeleteDraftModalOpen, setIsDeleteDraftModalOpen] = useState(false);

  const { hasNoCachedData } = useNoCachedQueryGuard({
    isQueryError: orderQuery.isError,
    becameOnlineCallback: invalidateOrderQuery,
  });

  const onOrderCancel = () => {
    if (!orderData) {
      return;
    }

    allowOnlineOnly(() => {
      cancelOrderRequisitionMutation.mutate(orderData, {
        onSuccess: async () => {
          await invalidateOrderQuery();
          await invalidateAllOrderRequisitionsQueries();
          setIsCancelOrderModalOpen(false);
          openModal(<CancelSuccessModal closeModal={closeModal} />);
        },
        onError: () => {
          setToastMessage({
            type: "failure",
            message: "There was an issue while cancelling this order. Please try again.",
          });
        },
      });
    });
  };

  const onOrderClose = () => {
    if (!orderData) {
      return;
    }

    allowOnlineOnly(() => {
      closeOrderMutation.mutate(orderData, {
        onSuccess: async () => {
          setToastMessage({
            type: "success",
            message: "The order was closed.",
          });
          await invalidateOrderQuery();
          await invalidateAllOrderRequisitionsQueries();
          setIsCloseOrderModalOpen(false);
        },
        onError: () => {
          setToastMessage({
            type: "failure",
            message: "There was an issue while closing this order. Please try again.",
          });
        },
      });
    });
  };

  const openModalCancelOrder = () => {
    allowOnlineOnly(() => {
      setIsCancelOrderModalOpen(true);
    });
  };

  const openModalCloseOrder = () => {
    allowOnlineOnly(() => {
      setIsCloseOrderModalOpen(true);
    });
  };

  const { mutate: downloadMTMLMutation } = useMutation({
    mutationKey: ["exportOrderToMtml"],
    mutationFn: () =>
      exportOrderToMtml({
        orderId: id,
      }),

    onSuccess: (file) => {
      setToastMessage({
        type: "success",
        message: "The PO will be downloaded soon.",
      });
      triggerFileDownload({
        file,
        fileNameWithExtension: `S2S_${id}.xml`,
      });
    },
    onError: () => {
      setToastMessage({
        type: "failure",
        message: "There was an issue while downloading the PO. Please try again.",
      });
    },
  });

  const downloadMTML = () => {
    allowOnlineOnly(() => {
      downloadMTMLMutation();
    });
  };

  const { mutate: downloadExcelMutation } = useMutation({
    mutationKey: ["exportOrderToExcel"],
    mutationFn: () =>
      exportOrderToExcel({
        orderId: id,
      }),

    onSuccess: (file) => {
      setToastMessage({
        type: "success",
        message: "The PO will be downloaded soon.",
      });
      triggerFileDownload({
        file,
        fileNameWithExtension: `S2S_${id}.xlsx`,
      });
    },
    onError: () => {
      setToastMessage({
        type: "failure",
        message: "There was an issue while downloading the PO. Please try again.",
      });
    },
  });

  const downloadExcel = () => {
    allowOnlineOnly(() => {
      downloadExcelMutation();
    });
  };

  const onDeleteDraftConfirm = (draftId: string) => {
    allowOnlineOnly(() => {
      deleteDraftMutation(draftId);
    });
  };

  const deleteDraft = () => {
    allowOnlineOnly(() => {
      setIsDeleteDraftModalOpen(true);
    });
  };

  const contextMenuActions: OrderContextMenuActions = {
    openModalCancelOrder,
    openModalCloseOrder,
    onMTMLDownload: downloadMTML,
    onExcelDownload: downloadExcel,
    onDeleteDraft: deleteDraft,
  };

  if (hasNoCachedData) {
    return <NoCachedData hasGoBack={true} />;
  }

  if (orderQuery.isPending || orderQuery.isFetching) {
    return <Loading />;
  }

  if (!orderData) {
    return <div>Order not found</div>;
  }

  return (
    <>
      <OrderDetails order={orderData} contextMenuActions={contextMenuActions} />
      <AllowOnlineOnlyWarningModal />
      <Modal isOpen={isCloseOrderModalOpen} closeModal={() => setIsCloseOrderModalOpen(false)}>
        <CloseOrderModal
          closeModal={() => setIsCloseOrderModalOpen(false)}
          onCloseOrder={onOrderClose}
          isLoading={closeOrderMutation.isPending}
        />
      </Modal>
      <Modal isOpen={isCancelOrderModalOpen} closeModal={() => setIsCancelOrderModalOpen(false)}>
        <CancelOrderModal
          closeModal={() => setIsCancelOrderModalOpen(false)}
          onCancel={onOrderCancel}
          isLoading={cancelOrderRequisitionMutation.isPending}
        />
      </Modal>
      <Modal isOpen={isDeleteDraftModalOpen} closeModal={() => setIsDeleteDraftModalOpen(false)}>
        <DeleteOrderDraftModal
          closeModal={() => setIsDeleteDraftModalOpen(false)}
          draftId={orderData.id}
          isLoading={isDeleteDraftMutationPending}
          onConfirm={() => onDeleteDraftConfirm(orderData.id)}
        />
      </Modal>
    </>
  );
};
