import { useQuery } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";

import { checkProductsAvailability } from "../api/checkProductsAvailability";
import {
  LiteProductAvailabilityCheckRequest,
  LiteProductAvailabilityCheckResponse,
  ProductsService,
} from "../typegens";

export const PRODUCT_VARIANTS_QUERY_KEY_BASE = "productVariants";

export const useProductVariantsQuery = ({
  items,
  portId,
  orderType,
  supplierId,
}: LiteProductAvailabilityCheckRequest) => {
  const isMissingItemsReplacementsEnabled = useFlag("va-missing-items-replacements");
  return useQuery<LiteProductAvailabilityCheckResponse>({
    queryKey: [
      PRODUCT_VARIANTS_QUERY_KEY_BASE,
      portId,
      items,
      orderType,
      supplierId,
      isMissingItemsReplacementsEnabled,
    ],
    queryFn: async ({ signal }) => {
      if (isMissingItemsReplacementsEnabled) {
        return await checkProductsAvailability(
          {
            items,
            portId,
            orderType,
            supplierId,
          },
          { signal }
        );
      }

      const response = await ProductsService.getProductVariants(
        {
          requestBody: {
            portId,
            variantIds: items.map((item) => item.variantId),
            orderType,
            supplierId,
          },
        },
        { signal }
      );
      return {
        availableItems: response.items,
        replacementItems: {},
      };
    },
    refetchOnWindowFocus: false,
    gcTime: 0,
    refetchOnReconnect: false,
  });
};
