import _chunk from "lodash/chunk";
import _forEach from "lodash/forEach";
import _groupBy from "lodash/groupBy";
import { KeyboardEvent } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  LocalStocktakeType,
  getBadgeSettingsFromStocktakeType,
  getDefaultedStocktakeTypeAccountingForConfigurationLogic,
  getNonEmptyCategories,
  getSelectOptionFromStocktakeType,
  getStocktakeTypeIcon,
  getStocktakeTypeLabel,
  getTotalEntityQuantity,
  roundEntityQuantity,
  roundMoneyAmount,
  shouldRenderStocktakeType,
  shouldRenderStocktakeTypes,
} from "@web/common";
import { Category, ParsingError } from "@web/models";
import {
  DateFormat,
  convertTimeToISODateString,
  extractFromISODateString,
  formatNumber,
  getNewAmountValueFromKeyboardEvent,
  isDefined,
  preprocessFormAmountValue,
} from "@web/utils";

import {
  ChunkItem,
  LocalLiteStocktakeCategory,
  LocalPartialStocktakeReportDraft,
  LocalStocktakeReportDraft,
  LocalStocktakeReportDraftItem,
  LocalStocktakeReportDraftNewExtraItem,
  LocalStocktakeReportDraftOldExtraItem,
  LocalStocktakeReportForm,
  LocalStocktakeReportItemForm,
  LocalStocktakeReportNewExtraItemForm,
  LocalStocktakeReportOldExtraItemForm,
  LocalStocktakeSetupForm,
  PartialStocktakeReportDraftSchema,
  StocktakeReportDraftSchema,
  UiStocktakeReport,
  UiStocktakeReportListItem,
} from "src/models";
import {
  LiteStocktakeCategory,
  type LiteStocktakeCountListRequest,
  LiteStocktakeExtraItem,
  LiteStocktakeInitialReportData,
  LiteStocktakeItem,
  LiteStocktakePartialExtraItem,
  LiteStocktakeReport,
  LiteStocktakeReportListItem,
  LiteStocktakeReportRequest,
  LiteStocktakeSetupDataRequest,
} from "src/typegens";

export const LocalStocktakeService = {
  /**
   * Constants
   */
  ENTITY_QUANTITY_MIN_VALUE: 0,
  ENTITY_QUANTITY_MAX_VALUE: 9999.99,
  SINGLE_UNIT_GROSS_PRICE_MIN_VALUE: 0,
  SINGLE_UNIT_GROSS_PRICE_MAX_VALUE: 9999.99,

  /**
   * Converters/mappers
   */
  convertFromApiToUiStocktakeListItem: (
    apiStocktakeListItem: LiteStocktakeReportListItem
  ): UiStocktakeReportListItem => ({
    ...apiStocktakeListItem,
    isDraft: false,
    type: {
      type: apiStocktakeListItem.type,
      name: LocalStocktakeService.getStocktakeTypeLabel(apiStocktakeListItem.type),
    },
  }),
  convertFromStoredDraftToUiStocktakeListItem: (
    storedStocktakeReportDraft: LocalStocktakeReportDraft
  ): UiStocktakeReportListItem => ({
    ...storedStocktakeReportDraft,
    isDraft: true,
    type: {
      type: storedStocktakeReportDraft.type,
      name: LocalStocktakeService.getStocktakeTypeLabel(storedStocktakeReportDraft.type),
    },
  }),
  convertFromApiToUiStocktakeReport: (
    apiStocktakeReport: LiteStocktakeReport
  ): UiStocktakeReport => {
    const categoriesSortedByName = LocalStocktakeService.sortStocktakeReportCategories(
      apiStocktakeReport.categories
    );

    const stocktakeReportItemsSortedByCategoryAndSelfName: LiteStocktakeItem[] =
      LocalStocktakeService.sortStocktakeReportItems(
        categoriesSortedByName,
        apiStocktakeReport.items
      );

    const stocktakeReportItemsSortedByName: LiteStocktakeExtraItem[] =
      LocalStocktakeService.sortStocktakeReportExtraItems(apiStocktakeReport.extraItems);

    return {
      ...apiStocktakeReport,
      categories: categoriesSortedByName,
      items: stocktakeReportItemsSortedByCategoryAndSelfName,
      extraItems: stocktakeReportItemsSortedByName,
      isDraft: false,
    };
  },
  convertFromStoredDraftToValidatedReportDraft: (
    storedStocktakeReportDraft: LocalStocktakeReportDraft
  ): LocalStocktakeReportDraft => {
    // Do not shorten this statement to ensure typechecking compile-time
    const parseInput: LocalStocktakeReportDraft = storedStocktakeReportDraft;
    const parseResult = StocktakeReportDraftSchema.safeParse(parseInput);

    if (!parseResult.success) {
      throw new ParsingError("This stocktake draft is missing attributes", parseResult.error);
    }

    return parseResult.data;
  },
  convertFromReportFormToPartialDraft: (
    reportForm: LocalStocktakeReportForm
  ): LocalPartialStocktakeReportDraft => {
    // Do not shorten this statement to ensure typechecking compile-time
    const parseInput: LocalPartialStocktakeReportDraft = reportForm;
    const parseResult = PartialStocktakeReportDraftSchema.safeParse(parseInput);

    if (!parseResult.success) {
      throw new ParsingError("This stocktake draft is missing attributes", parseResult.error);
    }

    return parseResult.data;
  },
  convertFromValidatedReportFormToApiStocktakeReportRequest: (
    validatedReportForm: LocalStocktakeReportForm
  ): LiteStocktakeReportRequest => ({
    vesselId: validatedReportForm.vesselId,
    type: validatedReportForm.type,
    inventoryCount: validatedReportForm.inventoryCount,
    robValue: validatedReportForm.robValue,
    stocktakeDate: validatedReportForm.stocktakeDate,
    stocktakerInformation: validatedReportForm.stocktakerInformation,
    subject: validatedReportForm.subject,
    categories: validatedReportForm.categories,
    // `lineNumber` and `entityQuantity` do not belong to the `LiteStocktakeReportRequest` model
    items: validatedReportForm.items.map(({ lineNumber, entityQuantity, ...item }) => ({
      ...item,
      // Set item's quantity to 0 if quantity is not provided
      quantity: LocalStocktakeService.getItemQuantity(
        item.skuDetails.salesEntityQuantity,
        preprocessFormAmountValue(entityQuantity)
      ),
    })),
    extraItems: [
      // `entityQuantity` does not belong to the `LiteStocktakeReportRequest` model
      ...validatedReportForm.newExtraItems.map(({ entityQuantity, ...extraItem }) => {
        // For an extra item, `quantity === entityQuantity`
        // Set item's quantity to 0 if quantity is not provided
        const quantity = entityQuantity || 0;
        return {
          ...extraItem,
          quantity,
          singleUnitGrossPrice: isDefined(extraItem.singleUnitGrossPrice.amount)
            ? {
                ...extraItem.singleUnitGrossPrice,
                // Satisfy the compiler
                amount: extraItem.singleUnitGrossPrice.amount ?? 0,
              }
            : undefined,
          // Set item's entityQuantityOnStock to quantity value
          entityQuantityOnStock: quantity,
        };
      }),
      // `entityQuantity` does not belong to the `LiteStocktakeReportRequest` model
      ...validatedReportForm.oldExtraItems.map(({ entityQuantity, ...extraItem }) => ({
        ...extraItem,
        // For an extra item, `quantity === entityQuantity`
        // Set item's quantity to 0 if quantity is not provided
        quantity: entityQuantity || 0,
        singleUnitGrossPrice: isDefined(extraItem.singleUnitGrossPrice.amount)
          ? {
              ...extraItem.singleUnitGrossPrice,
              // Satisfy the compiler
              amount: extraItem.singleUnitGrossPrice.amount ?? 0,
            }
          : undefined,
      })),
    ],
  }),
  convertFromReportFormToApiExportStockCountListRequest: (
    reportForm: LocalPartialStocktakeReportDraft
  ): LiteStocktakeCountListRequest => {
    const getExtraItemData = (
      extraItem: LocalStocktakeReportDraftOldExtraItem | LocalStocktakeReportDraftNewExtraItem
    ): LiteStocktakePartialExtraItem => ({
      id: extraItem.id,
      ...(isDefined(extraItem.singleUnitGrossPrice.amount)
        ? {
            ...extraItem.singleUnitGrossPrice,
            // Satisfy the compiler
            amount: extraItem.singleUnitGrossPrice.amount ?? 0,
          }
        : {}),
      ...(extraItem.name ? { name: extraItem.name } : {}),
      ...(extraItem.measurementUnit ? { measurementUnit: extraItem.measurementUnit } : {}),
      ...(extraItem.articleCode ? { articleCode: extraItem.articleCode } : {}),
    });

    return {
      vesselId: reportForm.vesselId,
      type: reportForm.type,
      stocktakeDate: reportForm.stocktakeDate,
      stocktakerInformation: reportForm.stocktakerInformation,
      subject: reportForm.subject,
      items: reportForm.items.map((item) => ({
        id: item.id,
        singleUnitGrossPrice: item.singleUnitGrossPrice,
        skuDetails: item.skuDetails,
      })),
      extraItems: [
        ...reportForm.newExtraItems.map((extraItem) => getExtraItemData(extraItem)),
        ...reportForm.oldExtraItems.map((extraItem) => getExtraItemData(extraItem)),
      ],
    };
  },
  convertFromStocktakeSetupFormToInitialStocktakeReportDataRequest: (
    vesselId: string,
    stocktakeSetupForm: LocalStocktakeSetupForm
  ): LiteStocktakeSetupDataRequest => ({
    vesselId,
    type: stocktakeSetupForm.type,
    stocktakeDate: LocalStocktakeService.convertStocktakeDateInputToIsoString(
      stocktakeSetupForm.stocktakeDate
    ),
  }),
  convertFromStocktakeSetupToPartialDraft: ({
    vesselId,
    stocktakeSetupForm,
    initialStocktakeReportData,
  }: {
    vesselId: string;
    stocktakeSetupForm: LocalStocktakeSetupForm;
    initialStocktakeReportData: LiteStocktakeInitialReportData;
  }): LocalPartialStocktakeReportDraft => {
    /**
     * Assumption - all numerical data used for calculations is rounded to 2 decimal places:
     * - `robValue.amount`
     * - `items[].singleUnitGrossPrice.amount`
     * - `items[].robValue.amount`
     * - `items[].quantity`
     * - `items[].entityQuantityOnStock`
     * - `items[].skuDetails.salesEntityPrice.costPrice.amount`
     * - `items[].skuDetails.salesEntityQuantity`
     * - `extraItems[].singleUnitGrossPrice.amount`
     * - `extraItems[].robValue.amount`
     * - `extraItems[].quantity`
     *
     * If any of these are not rounded to 2 decimal places, the fix needs to happen on API side, not in FE.
     */

    const categoriesSortedByName = LocalStocktakeService.sortStocktakeReportCategories(
      initialStocktakeReportData.categories
    );

    const partialStocktakeReportItemsSortedByCategoryAndSelfName: LocalStocktakeReportDraftItem[] =
      LocalStocktakeService.sortStocktakeReportItems(
        categoriesSortedByName,
        initialStocktakeReportData.items
      ).map(({ quantity, ...item }, index) => ({
        // `quantity` does not belong to the `LocalStocktakeReportDraftItem` model
        ...item,
        entityQuantity: undefined,
        lineNumber: index + 1,
      }));

    const partialStocktakeReportOldExtraItemsSortedByName: LocalStocktakeReportDraftOldExtraItem[] =
      LocalStocktakeService.sortStocktakeReportExtraItems(
        initialStocktakeReportData.extraItems
      ).map(({ quantity, ...extraItem }) => ({
        // `quantity` does not belong to the `LocalStocktakeReportDraftOldExtraItem` model
        ...extraItem,
        entityQuantity: undefined,
        singleUnitGrossPrice: {
          // May be undefined and it's OK to be
          amount: extraItem.singleUnitGrossPrice?.amount,
          currencyCode: initialStocktakeReportData.currencyCode,
        },
        isNew: false,
      }));

    const partialStocktakeReportData: LocalPartialStocktakeReportDraft = {
      vesselId,
      type: stocktakeSetupForm.type,
      inventoryCount: 0,
      robValue: {
        amount: 0,
        currencyCode: initialStocktakeReportData.currencyCode,
      },
      stocktakeDate: LocalStocktakeService.convertStocktakeDateInputToIsoString(
        stocktakeSetupForm.stocktakeDate
      ),
      stocktakerInformation: {
        name: stocktakeSetupForm.stocktakerName,
        position: stocktakeSetupForm.stocktakerPosition,
      },
      subject: stocktakeSetupForm.subject,
      categories: categoriesSortedByName,
      items: partialStocktakeReportItemsSortedByCategoryAndSelfName,
      oldExtraItems: partialStocktakeReportOldExtraItemsSortedByName,
      newExtraItems: [],
    };

    const parseResult = PartialStocktakeReportDraftSchema.safeParse(partialStocktakeReportData);

    if (!parseResult.success) {
      throw new ParsingError(
        "This partial stocktake draft is missing attributes",
        parseResult.error
      );
    }

    return parseResult.data;
  },
  convertCategoryToStocktakeCategory: (category: Category): LocalLiteStocktakeCategory => {
    function convertCategory(cat: Category): LocalLiteStocktakeCategory {
      return {
        ...cat,
        productsNumber: cat.productsNumber || 0,
        children: cat.children.map((child) => convertCategory(child)),
      };
    }
    return convertCategory(category);
  },
  convertStocktakeDateInputToIsoString: (stocktakeDate: string): string =>
    convertTimeToISODateString("00:00", stocktakeDate),

  /**
   * Calculators
   */
  getItemEntityQuantity: (salesEntityQuantity: number, quantity?: number): number => {
    const defaultedQuantity = quantity || 0;
    // This is already rounded to 2 decimal places by the API
    const nonnegativeQuantity = defaultedQuantity < 0 ? 0 : defaultedQuantity;
    return getTotalEntityQuantity(salesEntityQuantity, nonnegativeQuantity);
  },
  getItemQuantity: (salesEntityQuantity: number, entityQuantity?: number): number => {
    const defaultedEntityQuantity = entityQuantity || 0;
    // This is already rounded to 2 decimal places by the form's transform on `entityQuantity` field
    const nonnegativeEntityQuantity = defaultedEntityQuantity < 0 ? 0 : defaultedEntityQuantity;
    // Prevent division by 0
    return salesEntityQuantity > 0
      ? Math.round((nonnegativeEntityQuantity / salesEntityQuantity) * 100) / 100
      : 0;
  },
  // entityQuantity should be already rounded to 2 decimal places
  getItemRobValueAmount: (entityQuantity: number, singleUnitGrossPriceAmount: number): number =>
    roundMoneyAmount((entityQuantity || 0) * singleUnitGrossPriceAmount),
  getTotalRobValueAmount: ({
    items,
    oldExtraItems,
    newExtraItems,
  }: {
    items: LocalStocktakeReportItemForm[];
    oldExtraItems: LocalStocktakeReportOldExtraItemForm[];
    newExtraItems: LocalStocktakeReportNewExtraItemForm[];
  }): number =>
    roundMoneyAmount(
      [...items, ...oldExtraItems, ...newExtraItems].reduce(
        (total, item) => total + item.robValue.amount,
        0
      )
    ),

  /**
   * State checkers
   */
  areAnyItemsUncounted: ({
    items,
    oldExtraItems,
    newExtraItems,
  }: {
    items: LocalStocktakeReportItemForm[];
    oldExtraItems: LocalStocktakeReportOldExtraItemForm[];
    newExtraItems: LocalStocktakeReportNewExtraItemForm[];
  }): boolean =>
    [...items, ...oldExtraItems, ...newExtraItems].some((item) => !isDefined(item.entityQuantity)),

  /**
   * Formatters
   */
  formatStocktakeDateFromIsoString: (isoString: string, type?: DateFormat): string =>
    extractFromISODateString(isoString, type),
  formatEntityQuantity: (entityQuantity: number | undefined): string =>
    formatNumber(entityQuantity || 0, {
      maximumFractionDigits: 2,
    }),
  formatSalesEntityQuantity: (salesEntityQuantity: number | undefined): string =>
    formatNumber(salesEntityQuantity || 0, {
      maximumFractionDigits: 2,
    }),
  formatExportFileName: (
    prefix: string,
    shouldIncludeStocktakeType: boolean,
    stocktakeType: LocalStocktakeType,
    stocktakeDate: string
  ): string => {
    const stocktakeDateFormatted = LocalStocktakeService.formatStocktakeDateFromIsoString(
      stocktakeDate,
      "yearFirst"
    );

    if (shouldIncludeStocktakeType) {
      const stocktakeTypeLabel = LocalStocktakeService.getStocktakeTypeLabel(stocktakeType).replace(
        /\W/g,
        ""
      );
      return `${prefix}_${stocktakeTypeLabel}_${stocktakeDateFormatted}`;
    }

    return `${prefix}_${stocktakeDateFormatted}`;
  },

  /**
   * Other utils
   */
  getNewEntityQuantityFromKeyboardEvent: (
    currentEntityQuantity: number,
    event: KeyboardEvent<HTMLInputElement>
  ) =>
    getNewAmountValueFromKeyboardEvent({
      currentValue: currentEntityQuantity,
      event,
      postProcessValue: roundEntityQuantity,
      enableFractionModifiers: true,
      minValue: LocalStocktakeService.ENTITY_QUANTITY_MIN_VALUE,
      maxValue: LocalStocktakeService.ENTITY_QUANTITY_MAX_VALUE,
    }),
  getNewSingleUnitGrossPriceFromKeyboardEvent: (
    currentSingleUnitGrossPrice: number,
    event: KeyboardEvent<HTMLInputElement>
  ) =>
    getNewAmountValueFromKeyboardEvent({
      currentValue: currentSingleUnitGrossPrice,
      event,
      postProcessValue: roundMoneyAmount,
      enableFractionModifiers: true,
      minValue: LocalStocktakeService.SINGLE_UNIT_GROSS_PRICE_MIN_VALUE,
      maxValue: LocalStocktakeService.SINGLE_UNIT_GROSS_PRICE_MAX_VALUE,
    }),
  shouldRenderStocktakeTypes: shouldRenderStocktakeTypes,
  shouldRenderStocktakeType: shouldRenderStocktakeType,
  getSelectOptionFromStocktakeType: getSelectOptionFromStocktakeType,
  getDefaultedStocktakeType: getDefaultedStocktakeTypeAccountingForConfigurationLogic,
  getBadgeSettingsFromStocktakeType: getBadgeSettingsFromStocktakeType,
  getStocktakeTypeLabel: getStocktakeTypeLabel,
  getStocktakeTypeIcon: getStocktakeTypeIcon,
  getNonEmptyCategories: getNonEmptyCategories,
  getChunkedCollection: <T extends { id: string }>(
    items: Array<T>,
    chunkSize: number
  ): Array<ChunkItem<T & { index: number }>> => {
    const itemsWithIndex = items.map((item, index) => ({ ...item, index }));
    return _chunk(itemsWithIndex, chunkSize).map((chunk) => ({
      // Create chunk key consisting of all contained item ids, so React knows when collection items actually change
      chunkId: chunk.reduce((acc, item) => acc + item.id, ""),
      chunkItems: chunk,
    }));
  },
  getEmptyNewExtraItem: (currencyCode: string): Required<LocalStocktakeReportNewExtraItemForm> => ({
    id: uuidv4(),
    name: "",
    measurementUnit: "",
    articleCode: "",
    entityQuantity: null,
    singleUnitGrossPrice: {
      amount: null,
      currencyCode: currencyCode,
    },
    robValue: {
      amount: 0,
      currencyCode: currencyCode,
    },
  }),
  getChunkedItemsGroupedByCategoryId: <T extends { id: string; categoryId: string }>(
    items: Array<T>,
    chunkSize: number
  ): Record<string, Array<ChunkItem<T & { index: number }>>> => {
    const itemsGroupedByCategoryId = _groupBy(
      items.map((item, index) => ({ ...item, index })),
      ({ categoryId }) => categoryId
    );
    const chunkedItemsInCategories: Record<string, Array<ChunkItem<T & { index: number }>>> = {};
    _forEach(
      itemsGroupedByCategoryId,
      (itemsInGroup, key) =>
        (chunkedItemsInCategories[key] = _chunk(itemsInGroup, chunkSize).map((chunk) => ({
          // Create chunk key consisting of all contained item ids, so React knows when collection items actually change
          chunkId: chunk.reduce((acc, item) => acc + item.id, ""),
          chunkItems: chunk,
        })))
    );
    return chunkedItemsInCategories;
  },
  sortStocktakeReportCategories: (categories: LiteStocktakeCategory[]): LiteStocktakeCategory[] => {
    function sortCategoriesByName(children: LiteStocktakeCategory[]): LiteStocktakeCategory[] {
      return children
        .map((category) => ({
          ...category,
          children: sortCategoriesByName(category.children),
        }))
        .sort((a, b) => a.name.localeCompare(b.name, "en"));
    }
    return sortCategoriesByName(categories);
  },
  // Sorts report items by category name, then self name, then date of purchase, then port name, then supplier name
  sortStocktakeReportItems: (
    categoriesSortedByName: LiteStocktakeCategory[],
    items: LiteStocktakeItem[]
  ): LiteStocktakeItem[] => {
    const sortedFlattenedCategories =
      LocalStocktakeService.flattenCategoriesWithDeepestName(categoriesSortedByName);

    return items
      .map((item) => ({
        ...item,
        lexicalCategory:
          sortedFlattenedCategories.find((category) => category.id === item.categoryId)
            ?.combinedName || "",
      }))
      .sort((a, b) => {
        if (a.lexicalCategory.localeCompare(b.lexicalCategory, "en") === 0) {
          if (a.skuDetails.about.name.localeCompare(b.skuDetails.about.name, "en") === 0) {
            // Since all dates are in ISO-8601 format without TZ adjustment, we can sort them lexicographically
            // and avoid memory allocation in the sorting method
            if (a.orderDate === b.orderDate) {
              if (a.port.locationCode.localeCompare(b.port.locationCode, "en") === 0) {
                return a.skuDetails.supplierInformation.name.localeCompare(
                  b.skuDetails.supplierInformation.name,
                  "en"
                );
              }
              return a.port.locationCode.localeCompare(b.port.locationCode, "en");
            }
            return a.orderDate > b.orderDate ? 1 : -1;
          }
          return a.skuDetails.about.name.localeCompare(b.skuDetails.about.name, "en");
        }
        return a.lexicalCategory.localeCompare(b.lexicalCategory, "en");
      })
      .map(({ lexicalCategory, ...item }) => item);
  },
  flattenCategoriesWithDeepestName: (
    categories: LiteStocktakeCategory[]
  ): Array<{ id: string; combinedName: string }> => {
    function reduceCategoriesToDeepestName(
      children: LiteStocktakeCategory[],
      parentName: string
    ): Array<{ id: string; combinedName: string }> {
      return children.reduce(
        (acc: Array<{ id: string; combinedName: string }>, category, currentIndex) => {
          // Use currentIndex to differentiate categories in an edge cases where category names
          // may be duplicated across the same level of the tree
          const combinedName = `${
            parentName ? `${parentName}-${category.name}` : category.name
          }${currentIndex}`;
          return category.children.length > 0
            ? [
                ...acc,
                { id: category.id, combinedName },
                ...reduceCategoriesToDeepestName(category.children, combinedName),
              ]
            : [...acc, { id: category.id, combinedName }];
        },
        []
      );
    }
    return reduceCategoriesToDeepestName(categories, "");
  },
  // Sorts report items by product name
  sortStocktakeReportExtraItems: (extraItems: LiteStocktakeExtraItem[]): LiteStocktakeExtraItem[] =>
    [...extraItems].sort((a, b) => a.name.localeCompare(b.name, "en")),
};
