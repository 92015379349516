import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { FormEventHandler } from "react";

import { Heading, Label, MuiIcon, Paragraph, RegularButton } from "@web/ui";

type Props = {
  closeModal: () => void;
  title: string;
  comments: string[];
  onSubmit: FormEventHandler<HTMLFormElement>;
  questions: React.ReactNode;
};

export const BasketAnswersFormModal: React.FC<Props> = ({
  closeModal,
  comments,
  onSubmit,
  title,
  questions,
}) => {
  return (
    <div className="inline-block bg-neutral_0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle">
      <form onSubmit={onSubmit}>
        <div className="hidden sm:block absolute top-0 right-0 pt-4 px-4">
          <button
            type="button"
            className="bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primaryDefault"
            onClick={closeModal}
          >
            <MuiIcon
              Icon={CloseOutlined}
              className="w-5 text-textIcon-whiteDisabled"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="flex flex-col w-130 m-5">
          <Heading size="200">{title}</Heading>
          <Paragraph className="mt-2" size="100" color="text-textIcon-blackSecondary">
            Please fill out the form
          </Paragraph>
        </div>
        <div className="bg-neutral_100 border-t-2 border-b-2 px-5 py-6">
          <Paragraph size="100" weight="heavy">
            Please ensure you have understood the following:
          </Paragraph>
          <ul className="list-disc list-inside my-4">
            {comments.map((comment) => {
              return (
                <li key={comment}>
                  <Label size="100" className="font-normal">
                    {comment}
                  </Label>
                </li>
              );
            })}
          </ul>
          {questions}
        </div>
        <div className="m-5 flex">
          <RegularButton
            className="ml-auto mr-5"
            variant="secondary"
            size="large"
            label="Close"
            onClick={closeModal}
            data-testid="basketForm_closeModalButton"
          />
          <RegularButton
            variant="primary"
            size="large"
            label="Continue"
            data-testid="basketForm_submitButton"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
