import { memo } from "react";

import { Badge } from "@web/ui";
import { isDefined, preprocessFormAmountValue } from "@web/utils";

import {
  StocktakeReportItemEntityQuantityFieldPath,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";

import { useItemFormDetails } from "../hooks";

type Props = {
  initialEntityQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  currencyCode: string;
  entityQuantityOnStock: number;
  entityQuantityFieldPath: StocktakeReportItemEntityQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
};

export const StocktakeProductTileEntityQuantityWarning = memo(
  ({
    initialEntityQuantity,
    initialSingleUnitGrossPriceAmount,
    initialMeasurementUnit,
    currencyCode,
    entityQuantityOnStock,
    entityQuantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
  }: Props) => {
    const { modifiedEntityQuantity } = useItemFormDetails({
      initialEntityQuantity,
      initialSingleUnitGrossPriceAmount,
      initialMeasurementUnit,
      currencyCode,
      entityQuantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    });

    const processedModifiedEntityQuantity = preprocessFormAmountValue(modifiedEntityQuantity);

    return isDefined(processedModifiedEntityQuantity) &&
      processedModifiedEntityQuantity > entityQuantityOnStock ? (
      <Badge size="s" color="warning" contrast="low" text="Above PO stock count" />
    ) : null;
  }
);
StocktakeProductTileEntityQuantityWarning.displayName = "StocktakeProductTileEntityQuantityWarning";
