import classnames from "classnames";

import { Paragraph } from "@web/ui";

export type Props = {
  className?: string;
  value: string;
  alignTo?: "start" | "center" | "end";
};

export const ProductTileFakeTextInput = ({ className = "", value, alignTo = "start" }: Props) => (
  <Paragraph
    size="100"
    className={classnames(
      className,
      {
        "px-2": alignTo !== "center",
        "justify-start": alignTo === "start",
        "justify-center": alignTo === "center",
        "justify-end": alignTo === "end",
      },
      "flex w-full h-6.5 bg-neutral_200 border border-neutral_300 rounded-lg items-center whitespace-nowrap overflow-x-clip"
    )}
  >
    {value}
  </Paragraph>
);
