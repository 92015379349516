import { OrderRequisition } from "@web/common/network/model";

import { ReorderModal } from "src/components/Modal/ReorderModal";
import { useProductVariantsQuery } from "src/hooks/useProductVariantsQuery";
import { LiteProductAvailabilityCheckItemInfo } from "src/typegens";

interface Props {
  order: OrderRequisition;
}

export const LEGACY_ReorderModalController = ({ order }: Props) => {
  const itemsToCheck: LiteProductAvailabilityCheckItemInfo[] = order.items.map((item) => ({
    variantId: item.variantId,
    supplierItemId: item.skuDetails.supplierInformation.supplierItemId,
    buyerItemId: item.skuDetails.buyerItemId,
    productName: item.name,
  }));

  const availableItemsQuery = useProductVariantsQuery({
    items: itemsToCheck,
    portId: order.port.id,
    orderType: order.orderType,
    supplierId: order.supplierInformation.id,
  });

  return <ReorderModal order={order} availableItemsQuery={availableItemsQuery} />;
};
