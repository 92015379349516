import classnames from "classnames";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { LinkButton } from "../../atoms";
import { DigitalAsset, DigitalAssetProps } from "./DigitalAsset";

type DigitalAssetsWrapperConditionalProps =
  | {
      showMoreLabel?: never;
      showLessLabel?: never;
      linkedPageLabel?: string;
      linkedPageUrl?: string;
    }
  | {
      showMoreLabel?: string;
      showLessLabel?: string;
      linkedPageLabel?: never;
      linkedPageUrl?: never;
    };

type DigitalAssetsWrapperProps = {
  assets: DigitalAssetProps[];
  className?: string;
  showLimit?: number;
} & DigitalAssetsWrapperConditionalProps;

export const DigitalAssetsWrapper = ({
  assets,
  className,
  showMoreLabel,
  showLessLabel,
  linkedPageLabel,
  linkedPageUrl,
  showLimit = 4,
}: DigitalAssetsWrapperProps) => {
  const [showAll, setShowAll] = useState(false);
  const navigate = useNavigate();

  const renderAssets = () => {
    const assetsToRender = showAll ? assets : assets.slice(0, showLimit);
    return assetsToRender.map((asset, index) => <DigitalAsset key={index} {...asset} />);
  };

  const renderLinks = () => {
    if (linkedPageLabel && linkedPageUrl) {
      return (
        <LinkButton
          label={linkedPageLabel}
          onClick={() => {
            navigate(linkedPageUrl);
          }}
          size="small"
          variant="primary"
        />
      );
    }

    if (showMoreLabel && showLessLabel) {
      return assets.length > showLimit ? (
        <LinkButton
          label={showAll ? showLessLabel : showMoreLabel}
          onClick={() => setShowAll(!showAll)}
          size="small"
          variant="primary"
        />
      ) : null;
    }
  };

  return (
    <div className="w-full">
      <div
        className={classnames("grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2", className)}
        data-testid="digitalAssetsWrapper"
      >
        {renderAssets()}
      </div>
      {renderLinks()}
    </div>
  );
};
