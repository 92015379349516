import { SvgIconComponent } from "@mui/icons-material";
import { SvgIconOwnProps } from "@mui/material/SvgIcon/SvgIcon";
import { AriaAttributes } from "react";

// Keep this in sync with the available Tailwind size helper classes
const tailwindHelperClassSizeToRemMap = new Map<string, string>([
  ["0", "0"],
  ["0.5", "0.125rem"],
  ["1", "0.25rem"],
  ["2", "0.5rem"],
  ["3", "0.75rem"],
  ["4", "1rem"],
  ["4.5", "1.25rem"],
  ["5", "1.5rem"],
  ["5.5", "1.75rem"],
  ["6", "2rem"],
  ["6.5", "2.5rem"],
  ["7", "3rem"],
  ["8", "4rem"],
  ["9", "5rem"],
  ["10", "6rem"],
  ["11", "7rem"],
  ["12", "8rem"],
  ["13", "10rem"],
  ["20", "16rem"],
  ["80", "20rem"],
  ["96", "24rem"],
  ["114", "32rem"],
  ["130", "40rem"],
]);

/**
 * Disallow passing down to the icon:
 * - the `fontSize` prop to the actual Icon component, since we use other method for sizing
 * - the `classes` & `color` props, since we use other method for setting icon's color
 * - the `sx` prop, since we are using it internally to set all custom icon's properties
 */
type Props = Omit<SvgIconOwnProps, "fontSize" | "classes" | "sx" | "color"> &
  AriaAttributes & {
    Icon: SvgIconComponent;
    className?: string;
    focusable?: boolean;
  };

/**
 * @param Icon A Mui Icon component
 * @param className
 *                  - To set an icon size, use Tailwind's helper classes, examples: `w-5.5`,
 *                    `w-[16px]`. It will be applied to both width & height of the icon.
 *                  - For `display` CSS property, the following are supported via Tailwind's
 *                    helper classes: `block`, `inline`, `inline-block`, `flex`, `inline-flex`.
 *                  - Pass colors using Tailwind's text color helper classes, e.g. `text-primaryDefault`
 * @constructor
 */
export const MuiIcon = ({ Icon, className = "", ...rest }: Props) => {
  let finalSize = "";

  // Find first occurrence of an (example) `w-5.5` inside a list of class names, considering line breaks
  const tailwindHelperClassSize =
    className.match(/^(?:[\s\S]*\s)?w-([\d.]+)(?:\s[\s\S]*)?$/)?.[1] || "";
  const tailwindHelperClassSizeToRem = tailwindHelperClassSizeToRemMap.get(tailwindHelperClassSize);

  // Prioritize tailwind helper class over pixel size
  if (tailwindHelperClassSizeToRem) {
    finalSize = tailwindHelperClassSizeToRem;
  } else {
    // Find first occurrence of an (example) `w-[12px]` inside a list of class names, considering line breaks
    finalSize = className.match(/^(?:[\s\S]*\s)?w-\[(\d+px)](?:\s[\s\S]*)?$/)?.[1] || "";
  }

  // Find first occurrence of an (example) `inline-flex` inside a list of class names, considering line breaks
  const tailwindHelperDisplayClass =
    className.match(
      /^(?:[\s\S]*\s)?((?:inline-)?block|(?:inline-)?flex|inline)(?:\s[\s\S]*)?$/
    )?.[1] || "";
  // Default to what the defaults for the whole app for SVG elements are
  const finalDisplay = tailwindHelperDisplayClass || "block";

  if (!finalSize) {
    finalSize = "1rem";
    console.warn("Unknown size for icon: ", Icon);
  }

  return (
    <Icon
      sx={{ width: finalSize, height: finalSize, display: finalDisplay }}
      className={className}
      {...rest}
    />
  );
};
