import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { useSearchInput } from "@web/common/hooks/useSearchHelpers";

import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { OrderStocktakeType } from "src/models";
import { CatalogSearchService, type LiteCategorySearchResult } from "src/typegens";

const CATEGORIES_SEARCH_QUERY_KEY_BASE = "categoriesSearch";

type GetQueryKeyArgs = {
  query: string;
  portId: string;
  supplierId: string;
  orderType: OrderStocktakeType | undefined;
};

const getQueryKey = ({ query, portId, supplierId, orderType }: GetQueryKeyArgs) => [
  CATEGORIES_SEARCH_QUERY_KEY_BASE,
  query,
  portId,
  supplierId,
  orderType,
];

type UseCategoriesSearchQuery = UseQueryResult<LiteCategorySearchResult[]>;

export const useCategoriesSearchQuery = (): UseCategoriesSearchQuery => {
  const [{ port, orderType, supplier }] = useAppStateContext();
  const { query } = useSearchInput();
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isOnline } = useNetworkDetector();

  const portId = port?.id || "";
  const supplierId = supplier?.id || "";

  const queryKey = useMemo(
    () =>
      getQueryKey({
        query,
        portId,
        supplierId,
        orderType,
      }),
    [query, portId, supplierId, orderType]
  );

  return useQuery<LiteCategorySearchResult[]>({
    queryKey,
    queryFn: ({ signal }) => {
      return CatalogSearchService.searchCategories(
        {
          portId,
          query,
          supplierId,
          orderType,
        },
        { signal }
      );
    },
    enabled:
      !!portId && !!orderType && !!supplierId && (areOfflineCapabilitiesEnabled ? isOnline : true),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnReconnect: false,
  });
};
