import { forwardRef, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { BasketExtraItemRefUrlFieldPath, LocalBasketForm } from "src/models";

import { ProductTileTextInput } from "../../shared/ProductTileTextInput";
import { useFormFieldKeyboardEvents } from "../../shared/hooks";

type Props = {
  refUrlFieldPath: BasketExtraItemRefUrlFieldPath;
};

export const RefUrlField = memo(
  forwardRef<HTMLInputElement, Props>(
    (
      { refUrlFieldPath },
      // We need to catch `ref`, so we don't get errors in runtime

      ref
    ) => {
      const { control } = useFormContext<LocalBasketForm>();
      const { handleKeyDown } = useFormFieldKeyboardEvents<HTMLInputElement>();

      return (
        <Controller
          name={refUrlFieldPath}
          control={control}
          render={({ field }) => (
            <ProductTileTextInput
              {...field}
              ref={ref}
              placeholder="Link (e.g. Amazon) to show the product you're looking for"
              maxLength={1000}
              onKeyDown={handleKeyDown}
              aria-label="Reference Link form field (optional)"
            />
          )}
        />
      );
    }
  )
);
RefUrlField.displayName = "RefUrlField";
