import { OnChooseReplacement, ProductItemsTable } from "@web/common/components";
import { OrderItem, ProductItemReplacement } from "@web/common/network/model";
import { applyVariantPriceToProducts } from "@web/common/utils";
import { Label } from "@web/ui";

import { LocalProductService } from "src/services/LocalProductService";
import { LiteProductSku } from "src/typegens";
import { pairProductsWithVariants } from "src/utils";

export type ChosenReplacements = Array<{ replacementForId: string; replacementId: string }>;

type Props = {
  sourceProducts: OrderItem[];
  availableItems: LiteProductSku[];
  replacementItems: Record<string, Array<LiteProductSku>>;
  chosenReplacements: ChosenReplacements;
  onChooseReplacement: OnChooseReplacement;
};

export const ProductDiffList = ({
  sourceProducts,
  availableItems,
  replacementItems,
  chosenReplacements,
  onChooseReplacement,
}: Props) => {
  const pairedProducts = pairProductsWithVariants(sourceProducts)(availableItems);
  const unavailableProducts = pairedProducts
    .filter((e) => !e[1])
    .map((e) => LocalProductService.convertOrderCatalogItemToProductItem(e[0]));

  const availableProducts = (
    pairedProducts.filter((e) => e[1]) as Array<[OrderItem, LiteProductSku]>
  ).map((e) =>
    LocalProductService.convertOrderCatalogItemToProductItem(applyVariantPriceToProducts(e))
  );

  const replacements = Object.entries(replacementItems).reduce<
    Record<string, Array<ProductItemReplacement>>
  >((acc, cur) => {
    const replacementFor = unavailableProducts.find((item) => item.skuDetails.id === cur[0]);
    acc[cur[0]] = cur[1].map((replacement) => ({
      ...LocalProductService.convertSkuToProductItem(
        replacement,
        replacementFor?.entityQuantity || 1
      ),
      isChosen: !!chosenReplacements.find(
        (info) => info.replacementForId === cur[0] && info.replacementId === replacement.id
      ),
    }));
    return acc;
  }, {});

  const unavailableProductsWithReplacements = unavailableProducts.filter(
    (item) => item.skuDetails.id in replacements
  );
  const unavailableProductsWithoutReplacements = unavailableProducts.filter(
    (item) => !(item.skuDetails.id in replacements)
  );

  return (
    <div className="flex flex-col gap-5">
      {unavailableProductsWithoutReplacements.length > 0 && (
        <div data-testid="productDiffList__unavailableProductsWithoutReplacements">
          <div className="flex flex-row justify-between">
            <Label size="300" className="text-textIcon-whiteDisabled uppercase">
              Not Available
            </Label>
            <Label size="300" className="text-textIcon-whiteDisabled">
              {unavailableProductsWithoutReplacements.length} ITEM
              {unavailableProductsWithoutReplacements.length > 1 && "S"}
            </Label>
          </div>
          <ProductItemsTable
            productItems={unavailableProductsWithoutReplacements}
            lineThrough={true}
            sideNote="Not Available"
            sideNoteVariant="danger"
            preserveRightMargin={true}
          />
        </div>
      )}
      {unavailableProductsWithReplacements.length > 0 && (
        <div data-testid="productDiffList__unavailableProductsWithReplacements">
          <div className="flex flex-row justify-between">
            <Label size="300" className="text-textIcon-whiteDisabled uppercase">
              Not Available - Replacement Found
            </Label>
            <Label size="300" className="text-textIcon-whiteDisabled">
              {unavailableProductsWithReplacements.length} ITEM
              {unavailableProductsWithReplacements.length > 1 && "S"}
            </Label>
          </div>
          <ProductItemsTable
            productItems={unavailableProductsWithReplacements}
            lineThrough={true}
            sideNote="Not Available"
            sideNoteVariant="danger"
            replacements={replacements}
            onChooseReplacement={onChooseReplacement}
            preserveRightMargin={true}
          />
        </div>
      )}
      {availableProducts.length > 0 && (
        <div data-testid="productDiffList__availableProducts">
          <div className="flex flex-row justify-between">
            <Label size="300" className="text-textIcon-whiteDisabled uppercase">
              Available
            </Label>
            <Label size="300" className="text-textIcon-whiteDisabled">
              {availableProducts.length} ITEM{availableProducts.length > 1 && "S"}
            </Label>
          </div>
          <ProductItemsTable
            productItems={availableProducts}
            sideNote="Available"
            sideNoteVariant="success"
            preserveRightMargin={true}
          />
        </div>
      )}
    </div>
  );
};
