import CloseOutlined from "@mui/icons-material/CloseOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";

import { Heading, MuiIcon, Paragraph, RegularButton } from "@web/ui";

type Props = {
  onClose: () => void;
};

export const NoCatalogDataModal = ({ onClose }: Props) => {
  return (
    <div className="inline-block align-bottom bg-neutral_0 rounded-lg p-5 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="flex items-center justify-between gap-2">
        <div className="flex-none flex items-center justify-center p-1 rounded-full border bg-primaryBackground">
          <MuiIcon Icon={InfoOutlined} className="w-5 text-primaryDefault" aria-hidden="true" />
        </div>
        <Heading size="200" className="flex-1">
          You need to be online to download the catalog for the first time
        </Heading>
        <button
          type="button"
          className="flex-none self-start bg-neutral_0 rounded-md text-textIcon-blackSecondary hover:text-textIcon-blackPrimary"
          onClick={onClose}
        >
          <span className="sr-only">Close modal</span>
          <MuiIcon Icon={CloseOutlined} className="w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-5">
        <Paragraph size="100" color="text-textIcon-blackSecondary">
          Please try again when the connection to the internet is restored.
        </Paragraph>
      </div>
      <div className="mt-5 flex flex-row-reverse">
        <RegularButton
          variant="primary"
          size="large"
          className="ml-2"
          label="Understood"
          loading={false}
          onClick={onClose}
        />
      </div>
    </div>
  );
};
