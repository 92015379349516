import { useCallback, useMemo, useRef, useState } from "react";
import { Observable, catchError, forkJoin, of } from "rxjs";

import { DebuggerMenuWrapper } from "@web/common";
import { FileSelect, Paragraph, RegularButton } from "@web/ui";
import {
  connectAndExportIdbData,
  deleteIdb,
  readFileAsText,
  triggerFileDownload,
} from "@web/utils";

import { VESSEL_TOKEN_STORAGE_KEY } from "src/config/constants";
import { LocalOrderDraft, LocalStocktakeReportDraft } from "src/models";
import {
  StoreNames,
  getStoreName,
  orderDraftsStore,
  stocktakeDraftsStore,
} from "src/objectStorage";

type Props = {
  errorDescription?: string;
  error?: unknown;
};

const getStoresObservable = (
  storeNames: StoreNames[],
  connectionWrapper: (storeName: string) => Promise<unknown>
): Observable<unknown[]> =>
  forkJoin(
    storeNames.map((storeNameBase) => {
      const storeName = getStoreName(storeNameBase);
      return connectionWrapper(storeName);
    })
  ).pipe(catchError((error) => of(error)));

const getCatalogsObservable = (
  connectionWrapper: (storeName: string) => Promise<unknown>
): Observable<unknown[]> =>
  forkJoin([connectionWrapper("s2s-lite")]).pipe(catchError((error) => of(error)));

const clearStorage = (storage: typeof localStorage | typeof sessionStorage): void => {
  Object.keys(storage).forEach((key) => {
    storage.removeItem(key);
  });
};

const reloadApp = (vesselToken?: string | null): void => {
  if (vesselToken) {
    window.location.href = `${window.location.origin}/?vesselToken=${vesselToken}`;
    return;
  }
  window.location.reload();
};

const appAcronym = "VA";

export const DebuggerMenuController = (props: Props) => {
  const timestamp = useMemo(() => new Date().toISOString(), []);

  const [storesDownloadError, setStoresDownloadError] = useState<unknown>();
  const [catalogsDownloadError, setCatalogsDownloadError] = useState<unknown>();
  const [orderDraftsImportError, setOrderDraftsImportError] = useState<unknown>();
  const [stocktakeDraftsImportError, setStocktakeDraftsImportError] = useState<unknown>();

  const storesData$ = useRef<Observable<unknown[]> | undefined>();
  const catalogsData$ = useRef<Observable<unknown[]> | undefined>();

  const storesDeletion$ = useRef<Observable<unknown[]> | undefined>();
  const catalogsDeletion$ = useRef<Observable<unknown[]> | undefined>();

  const [areStoresDownloading, setAreStoresDownloading] = useState(false);
  const handleDownloadStores = useCallback(() => {
    setAreStoresDownloading(true);
    setStoresDownloadError(undefined);
    const storeNamesExcludedFromExport = [StoreNames.IMAGES];
    const storeNamesToExport = Object.values(StoreNames).filter(
      (storeName) => !storeNamesExcludedFromExport.includes(storeName)
    );
    storesData$.current = getStoresObservable(storeNamesToExport, connectAndExportIdbData);
    storesData$.current.subscribe((data) => {
      setTimeout(() => {
        setAreStoresDownloading(false);
      }, 3000);
      try {
        const stringifiedStoresData = JSON.stringify(data, null, 2);
        const blob = new Blob([stringifiedStoresData], { type: "text/plain" });
        triggerFileDownload({
          file: blob,
          fileNameWithExtension: `S2S_${appAcronym}_stores_${timestamp}.txt`,
        });
      } catch (error) {
        setStoresDownloadError(error);
      }
    });
  }, [timestamp]);

  const [areCatalogsDownloading, setAreCatalogsDownloading] = useState(false);
  const handleDownloadCatalogs = useCallback(() => {
    setAreCatalogsDownloading(true);
    setCatalogsDownloadError(undefined);
    catalogsData$.current = getCatalogsObservable(connectAndExportIdbData);
    catalogsData$.current.subscribe((data) => {
      setTimeout(() => {
        setAreCatalogsDownloading(false);
      }, 3000);
      try {
        const stringifiedCatalogsData = JSON.stringify(data, null, 2);
        const blob = new Blob([stringifiedCatalogsData], { type: "text/plain" });
        triggerFileDownload({
          file: blob,
          fileNameWithExtension: `S2S_${appAcronym}_catalogs_${timestamp}.txt`,
        });
      } catch (error) {
        setCatalogsDownloadError(error);
      }
    });
  }, [timestamp]);

  const [isDangerActionInProgress, setIsDangerActionInProgress] = useState(false);

  const handleDeleteCatalogsData = useCallback(() => {
    if (
      !window.confirm(
        "Are you sure you want to delete catalogs & images data? It cannot be reversed."
      )
    ) {
      return;
    }
    setIsDangerActionInProgress(true);

    // Delete idb catalogs & images cache
    storesDeletion$.current = getStoresObservable([StoreNames.IMAGES], deleteIdb);
    catalogsDeletion$.current = getCatalogsObservable(deleteIdb);
    forkJoin([storesDeletion$.current, catalogsDeletion$.current]).subscribe(() => {
      // IDB deletion actually removes the IDB, so reload can help in avoiding issues
      reloadApp();
    });
  }, []);

  const handleDeleteOtherCaches = useCallback(() => {
    if (
      !window.confirm(
        "Are you sure you want to delete all caches apart from catalogs & images? It cannot be reversed."
      )
    ) {
      return;
    }
    setIsDangerActionInProgress(true);

    // Delete all idb caches except for catalogs, images, order drafts & stocktake drafts
    const storeNamesExcludedFromDeletion = [
      StoreNames.IMAGES,
      StoreNames.ORDER_DRAFTS,
      StoreNames.STOCKTAKE_DRAFTS,
    ];
    const storeNamesToDelete = Object.values(StoreNames).filter(
      (storeName) => !storeNamesExcludedFromDeletion.includes(storeName)
    );
    console.log(storeNamesToDelete);
    storesDeletion$.current = getStoresObservable(storeNamesToDelete, deleteIdb);
    storesDeletion$.current.subscribe(() => {
      // IDB deletion actually removes the IDB, so reload can help in avoiding issues
      reloadApp();
    });
  }, []);

  const handleDeleteOrderDrafts = useCallback(() => {
    if (!window.confirm("Are you sure you want to delete order drafts? It cannot be reversed.")) {
      return;
    }
    setIsDangerActionInProgress(true);

    // Delete idb order drafts
    storesDeletion$.current = getStoresObservable([StoreNames.ORDER_DRAFTS], deleteIdb);
    storesDeletion$.current.subscribe(() => {
      // IDB deletion actually removes the IDB, so reload can help in avoiding issues
      reloadApp();
    });
  }, []);

  const handleDeleteStocktakeDrafts = useCallback(() => {
    if (
      !window.confirm("Are you sure you want to delete stocktake drafts? It cannot be reversed.")
    ) {
      return;
    }
    setIsDangerActionInProgress(true);

    // Delete stocktake drafts
    storesDeletion$.current = getStoresObservable([StoreNames.STOCKTAKE_DRAFTS], deleteIdb);
    storesDeletion$.current.subscribe(() => {
      // IDB deletion actually removes the IDB, so reload can help in avoiding issues
      reloadApp();
    });
  }, []);

  const handleResetAppState = useCallback(() => {
    if (!window.confirm("Are you sure you want to reset app state? It cannot be reversed.")) {
      return;
    }
    setIsDangerActionInProgress(true);

    // Delete all local storage entries + idb configuration cache + idb keyval store
    const vesselToken = localStorage.getItem(VESSEL_TOKEN_STORAGE_KEY);
    clearStorage(localStorage);
    storesDeletion$.current = getStoresObservable(
      [StoreNames.CONFIGURATION, StoreNames.KEYVAL],
      deleteIdb
    );
    storesDeletion$.current.subscribe(() => {
      // Let's try to restore the Vessel's auth after resetting the app, which
      // included removing the vessel token from LS
      reloadApp(vesselToken);
    });
  }, []);

  const handleDeleteEverything = useCallback(() => {
    if (
      !window.confirm(
        "Are you sure you want to delete everything and reset the app state? It cannot be reversed."
      )
    ) {
      return;
    }
    setIsDangerActionInProgress(true);

    // Delete all idbs + local storage + session storage
    const vesselToken = localStorage.getItem(VESSEL_TOKEN_STORAGE_KEY);
    clearStorage(localStorage);
    clearStorage(sessionStorage);
    storesDeletion$.current = getStoresObservable(Object.values(StoreNames), deleteIdb);
    catalogsDeletion$.current = getCatalogsObservable(deleteIdb);
    forkJoin([storesDeletion$.current, catalogsDeletion$.current]).subscribe(() => {
      // Let's try to restore the Vessel's auth after resetting the app, which
      // included removing the vessel token from LS
      reloadApp(vesselToken);
    });
  }, []);

  const handleImportOrderDrafts = useCallback(async (file: File) => {
    if (!window.confirm("Are you sure you want to import order drafts?")) {
      return;
    }
    setOrderDraftsImportError(undefined);
    try {
      const fileContents = await readFileAsText(file);
      const draftsToImport: LocalOrderDraft[] = JSON.parse(fileContents);
      for (const draftToImport of draftsToImport) {
        await orderDraftsStore.add(draftToImport);
      }
      reloadApp();
    } catch (error) {
      setOrderDraftsImportError(error);
    }
  }, []);

  const handleImportStocktakeDrafts = useCallback(async (file: File) => {
    if (!window.confirm("Are you sure you want to import stocktake drafts?")) {
      return;
    }
    setStocktakeDraftsImportError(undefined);
    try {
      const fileContents = await readFileAsText(file);
      const draftsToImport: LocalStocktakeReportDraft[] = JSON.parse(fileContents);
      for (const draftToImport of draftsToImport) {
        await stocktakeDraftsStore.put(draftToImport, draftToImport.id);
      }
      reloadApp();
    } catch (error) {
      setStocktakeDraftsImportError(error);
    }
  }, []);

  const additionalActions = useCallback(
    () => (
      <>
        <div className="flex flex-col items-center gap-3">
          <RegularButton
            variant="secondary"
            size="small"
            label="Download stores data"
            loading={areStoresDownloading}
            disabled={isDangerActionInProgress}
            onClick={handleDownloadStores}
          />
          {!!storesDownloadError && (
            <Paragraph size="300" color="text-textIcon-whitePrimary">
              There was an error when downloading the Stores Data. It was logged in the App Status.
              Please try again.
            </Paragraph>
          )}
          <RegularButton
            variant="secondary"
            size="small"
            label="Download Catalogs Data"
            loading={areCatalogsDownloading}
            disabled={isDangerActionInProgress}
            onClick={handleDownloadCatalogs}
          />
          {!!catalogsDownloadError && (
            <Paragraph size="300" color="text-textIcon-whitePrimary">
              There was an error when downloading the Catalogs Data. It was logged in the App
              Status. Please try again.
            </Paragraph>
          )}
        </div>
        <div className="flex flex-col items-center gap-3 mt-6">
          <FileSelect
            render={(triggerFileSelection) => (
              <RegularButton
                variant="secondary"
                size="small"
                label="Import Order Drafts"
                onClick={triggerFileSelection}
              />
            )}
            onFileSelection={handleImportOrderDrafts}
            accept="text/plain"
          />
          {!!orderDraftsImportError && (
            <Paragraph size="300" color="text-textIcon-whitePrimary">
              There was an error when importing Order Drafts. It was logged in the App Status.
              Please try again.
            </Paragraph>
          )}
          <FileSelect
            render={(triggerFileSelection) => (
              <RegularButton
                variant="secondary"
                size="small"
                label="Import Stocktake Drafts"
                onClick={triggerFileSelection}
              />
            )}
            onFileSelection={handleImportStocktakeDrafts}
            accept="text/plain"
          />
          {!!stocktakeDraftsImportError && (
            <Paragraph size="300" color="text-textIcon-whitePrimary">
              There was an error when importing Stocktake Drafts. It was logged in the App Status.
              Please try again.
            </Paragraph>
          )}
        </div>
        <div className="flex flex-col items-center gap-3 mt-6">
          <RegularButton
            variant="danger"
            size="small"
            label="Delete Catalogs Data"
            loading={isDangerActionInProgress}
            onClick={handleDeleteCatalogsData}
          />
          <RegularButton
            variant="danger"
            size="small"
            label="Delete Other Caches"
            loading={isDangerActionInProgress}
            onClick={handleDeleteOtherCaches}
          />
          <RegularButton
            variant="danger"
            size="small"
            label="Delete Order Drafts"
            loading={isDangerActionInProgress}
            onClick={handleDeleteOrderDrafts}
          />
          <RegularButton
            variant="danger"
            size="small"
            label="Delete Stocktake Drafts"
            loading={isDangerActionInProgress}
            onClick={handleDeleteStocktakeDrafts}
          />
          <RegularButton
            variant="danger"
            size="small"
            label="Reset App State"
            loading={isDangerActionInProgress}
            onClick={handleResetAppState}
          />
          <RegularButton
            variant="danger"
            size="small"
            label="Delete Everything"
            loading={isDangerActionInProgress}
            onClick={handleDeleteEverything}
          />
        </div>
      </>
    ),
    [
      areStoresDownloading,
      isDangerActionInProgress,
      handleDownloadStores,
      storesDownloadError,
      areCatalogsDownloading,
      handleDownloadCatalogs,
      catalogsDownloadError,
      handleImportOrderDrafts,
      orderDraftsImportError,
      handleImportStocktakeDrafts,
      stocktakeDraftsImportError,
      handleDeleteCatalogsData,
      handleDeleteOtherCaches,
      handleDeleteOrderDrafts,
      handleDeleteStocktakeDrafts,
      handleResetAppState,
      handleDeleteEverything,
    ]
  );

  const debuggerMenuErrors = useMemo(
    () => ({
      storesDownloadError,
      catalogsDownloadError,
      orderDraftsImportError,
      stocktakeDraftsImportError,
    }),
    [catalogsDownloadError, orderDraftsImportError, stocktakeDraftsImportError, storesDownloadError]
  );

  return (
    <DebuggerMenuWrapper
      {...props}
      renderAdditionalActions={additionalActions}
      timestamp={timestamp}
      appName="Vessel Application"
      appAcronym={appAcronym}
      debuggerMenuErrors={debuggerMenuErrors}
    />
  );
};
