import { useTranslation } from "react-i18next";

import { Paragraph } from "@web/ui";

const DividerTexts = () => {
  const { t } = useTranslation();

  return (
    <div className="my-4 py-2 pt-7 mt-6 px-4 rounded-md w-full text-center border-t">
      <Paragraph size="200" color="text-textIcon-blackSecondary" className="mb-2">
        {t("pages.search.requestTitle")}
      </Paragraph>
      <Paragraph size="300" color="text-textIcon-whiteDisabled">
        {t("pages.search.requestSubtitle")}
      </Paragraph>
    </div>
  );
};

export default DividerTexts;
