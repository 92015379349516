import { useReducer } from "react";

import { NetworkDetectorState, NetworkDetectorStateAction } from "./models";

export const defaultNetworkState: NetworkDetectorState = {
  currentNetworkState: "unknown",
  isShakyConnection: false,
};

const NetworkDetectorReducer = (
  networkDetectorState: NetworkDetectorState,
  action: NetworkDetectorStateAction
): NetworkDetectorState => {
  switch (action.type) {
    case "setOnline":
      return {
        ...networkDetectorState,
        currentNetworkState: "online",
      };
    case "setOffline":
      return {
        ...networkDetectorState,
        currentNetworkState: "offline",
      };
    case "setShakyConnection":
      return {
        ...networkDetectorState,
        isShakyConnection: true,
      };
    case "resetShakyConnection":
      return {
        ...networkDetectorState,
        isShakyConnection: false,
      };
    case "setNavigatorOnline":
      return {
        ...networkDetectorState,
        isNavigatorOnline: action.value,
      };
    default:
      return networkDetectorState;
  }
};

export const useNetworkDetectorReducer = () =>
  useReducer(NetworkDetectorReducer, defaultNetworkState);
