import { KeyboardEvent } from "react";

import { OrderItem, Product, roundEntityQuantity } from "@web/common";
import { ProductItem } from "@web/common/network";
import {
  getLineItemTotal,
  getSalesEntityQuantity,
  getTotalEntityQuantity,
} from "@web/common/utils";
import { ParsingError } from "@web/models";
import { getNewAmountValueFromKeyboardEvent } from "@web/utils";

import { BasketEntry } from "src/contexts/BasketContext";
import { LocalLiteGatherOutAvailableItem } from "src/domain";
import { LiteProductSku, LitePunchoutAvailableItem } from "src/typegens";

import {
  LitePunchoutBasketSchema,
  LocalBasketExtraItemForm,
  LocalLiteOrderExtraItem,
  LocalLitePunchoutBasket,
} from "../models";

export const LocalProductService = {
  EXTRA_ITEM_MIN_QUANTITY: 0,
  EXTRA_ITEM_MAX_QUANTITY: 9999.99,
  getNewExtraItemQuantityFromKeyboardEvent: (
    currentQuantity: number,
    event: KeyboardEvent<HTMLInputElement>
  ) =>
    getNewAmountValueFromKeyboardEvent({
      currentValue: currentQuantity,
      event,
      postProcessValue: roundEntityQuantity,
      enableFractionModifiers: true,
      minValue: LocalProductService.EXTRA_ITEM_MIN_QUANTITY,
      maxValue: LocalProductService.EXTRA_ITEM_MAX_QUANTITY,
    }),
  convertProductItemToBasketEntry: (productItem: ProductItem): BasketEntry => ({
    sku: productItem.skuDetails,
    quantity: productItem.quantityInBasket || 1,
  }),
  convertBasketEntryToProductItem: (basketEntry: BasketEntry): ProductItem => {
    const salesEntityQuantity = getSalesEntityQuantity(basketEntry.sku);
    const entityQuantity = getTotalEntityQuantity(salesEntityQuantity, basketEntry.quantity);

    return {
      entityQuantity,
      measurementUnit: basketEntry.sku.measurementUnit,
      name: basketEntry.sku.about?.name || "",
      singleGrossPrice: basketEntry.sku.price.costPrice,
      totalAmount: getLineItemTotal(basketEntry),
      impaCode: basketEntry.sku.about?.generalInformation?.impaCode,
      skuDetails: basketEntry.sku,
      quantityInBasket: basketEntry.quantity,
    };
  },
  convertBasketEntrySkuPairToProductItem: (
    basketEntry: BasketEntry,
    sku: LiteProductSku
  ): ProductItem => {
    const salesEntityQuantity = getSalesEntityQuantity(sku);
    const entityQuantity = getTotalEntityQuantity(salesEntityQuantity, basketEntry.quantity);

    return {
      entityQuantity,
      measurementUnit: sku.measurementUnit,
      name: sku.about?.name || "",
      singleGrossPrice: sku.price.costPrice,
      totalAmount: {
        ...getLineItemTotal(basketEntry),
        amount: sku.price.costPrice.amount * entityQuantity,
      },
      impaCode: sku.about?.generalInformation?.impaCode,
      skuDetails: sku,
      originalSingleGrossPrice: basketEntry.sku.price.costPrice,
      quantityInBasket: basketEntry.quantity,
    };
  },
  convertSkuToProductItem: (sku: LiteProductSku, entityQuantity: number): ProductItem => ({
    entityQuantity,
    measurementUnit: sku.measurementUnit,
    name: sku.about?.name || "",
    singleGrossPrice: sku.price.costPrice,
    totalAmount: {
      currencyCode: sku.price.costPrice.currencyCode,
      amount: sku.price.costPrice.amount * entityQuantity,
    },
    impaCode: sku.about?.generalInformation?.impaCode,
    skuDetails: sku,
  }),
  convertGatherOutAvailableItemToBasketEntry: (
    product: LocalLiteGatherOutAvailableItem
  ): BasketEntry => ({
    sku: product.details,
    quantity: product.quantity / product.details.salesEntityQuantity,
  }),
  convertPunchoutAvailableItemToBasketEntry: (product: LitePunchoutAvailableItem): BasketEntry => ({
    sku: product.details,
    quantity: product.quantity / product.details.salesEntityQuantity,
  }),
  convertPunchoutBasketToStorageString: (punchoutBasket: LocalLitePunchoutBasket): string => {
    const parseResult = LitePunchoutBasketSchema.safeParse(punchoutBasket);

    if (!parseResult.success) {
      throw new ParsingError("This punchout basket is missing attributes", parseResult.error);
    }

    return JSON.stringify(parseResult.data);
  },
  convertFromStoredPunchoutBasketToPunchoutBasket: (
    punchoutBasketStoredString: string
  ): LocalLitePunchoutBasket => {
    let jsonParseResult: unknown;

    try {
      jsonParseResult = JSON.parse(punchoutBasketStoredString);
    } catch (error) {
      throw new ParsingError("This punchout basket is not parsable", error);
    }

    const schemaParseResult = LitePunchoutBasketSchema.safeParse(jsonParseResult);

    if (!schemaParseResult.success) {
      throw new ParsingError("This punchout basket is missing attributes", schemaParseResult.error);
    }

    return schemaParseResult.data;
  },
  convertProductToBasketEntry: (product: Product, quantity: number): BasketEntry => ({
    sku: product.skuList[0],
    quantity,
  }),
  convertOrderCatalogItemToProduct: (catalogItem: OrderItem): Product => ({
    id: catalogItem.variantId,
    name: catalogItem.name,
    images: catalogItem.skuDetails.images,
    skuList: [catalogItem.skuDetails],
    categoryTree: [],
    supplierInformation: {
      supplierItemId: catalogItem.skuDetails.supplierInformation.supplierItemId,
      supplierId: catalogItem.skuDetails.supplierInformation.supplierId,
      name: catalogItem.skuDetails.supplierInformation.name,
    },
  }),
  convertOrderCatalogItemToProductItem: (catalogItem: OrderItem): ProductItem => ({
    entityQuantity: catalogItem.entityQuantity,
    measurementUnit: catalogItem.measurementUnit,
    name: catalogItem.name,
    singleGrossPrice: catalogItem.singleGrossPrice,
    totalAmount: catalogItem.totalAmount,
    impaCode: catalogItem.impaCode,
    skuDetails: catalogItem.skuDetails,
    lineNumber: catalogItem.lineNumber,
  }),
  convertLocalExtraItemsToApiRequest: (
    extraItems: LocalLiteOrderExtraItem[]
    // Filter out all items with quantity being zero/empty string/null
  ): LocalLiteOrderExtraItem[] => extraItems.filter((item) => !!item.quantity),
  convertFromLocalExtraItemToBasketExtraItemForm: (
    extraItem: LocalLiteOrderExtraItem
  ): LocalBasketExtraItemForm => ({
    ...extraItem,
    quantity: extraItem.quantity === 0 ? null : extraItem.quantity,
  }),
  convertFromBasketExtraItemFormToLocalExtraItem: (
    extraItemForm: LocalBasketExtraItemForm
  ): LocalLiteOrderExtraItem => ({
    ...extraItemForm,
    quantity: extraItemForm.quantity || 0,
  }),
};
