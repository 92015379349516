import ChevronRightOutlined from "@mui/icons-material/ChevronRightOutlined";
import ExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";
import { useFormContext, useWatch } from "react-hook-form";

import { Heading, IconButton, RegularButton } from "@web/ui";
import { formatNumber } from "@web/utils";

import { LocalStocktakeReportForm } from "src/models";

type Props = {
  isOpen: boolean;
  toggleSectionVisibility: () => void;
  createNewItem: () => void;
  isToggleAvailable: boolean;
};

export const StocktakeExtraItemsSectionHeader = ({
  isOpen,
  toggleSectionVisibility,
  createNewItem,
  isToggleAvailable,
}: Props) => {
  const { control } = useFormContext<LocalStocktakeReportForm>();

  const oldExtraItems = useWatch({
    control,
    name: `oldExtraItems`,
  });
  const newExtraItems = useWatch({
    control,
    name: `newExtraItems`,
  });
  const totalExtraItemsCount = oldExtraItems.length + newExtraItems.length;

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center gap-3">
        <Heading size="300">
          Created Items{" "}
          <span className="text-textIcon-blackSecondary">
            ({formatNumber(totalExtraItemsCount)})
          </span>
        </Heading>
        {isToggleAvailable && (
          <IconButton
            size="large"
            variant="secondary"
            shape="circle"
            label="Expand/Contract Section"
            Icon={isOpen ? ExpandMoreOutlined : ChevronRightOutlined}
            onClick={toggleSectionVisibility}
          />
        )}
      </div>
      <RegularButton size="large" variant="primary" label="Create Item" onClick={createNewItem} />
    </div>
  );
};
