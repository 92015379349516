import { useEffect, useMemo } from "react";

import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";

interface UseNoCachedQueryGuard {
  isQueryError: boolean;
  becameOnlineCallback?: () => void;
}

export const useNoCachedQueryGuard = ({
  isQueryError,
  becameOnlineCallback,
}: UseNoCachedQueryGuard) => {
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { becameOnline, isOffline } = useNetworkDetector();

  useEffect(() => {
    if (!areOfflineCapabilitiesEnabled) {
      return;
    }

    // This is an equivalent of react-query's built-in `refetchOnReconnect: true` flag, but
    // based on our NetworkDetector instead of just navigator.online flag.
    if (becameOnline) {
      becameOnlineCallback && becameOnlineCallback();
    }
  }, [areOfflineCapabilitiesEnabled, becameOnline, becameOnlineCallback]);

  const hasNoCachedData = useMemo(() => {
    return areOfflineCapabilitiesEnabled && isOffline && isQueryError;
  }, [areOfflineCapabilitiesEnabled, isOffline, isQueryError]);

  return {
    hasNoCachedData,
  };
};
