import { KeyboardEvent, memo, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { preprocessFormAmountValue } from "@web/utils";

import {
  LocalStocktakeReportForm,
  StocktakeReportItemEntityQuantityFieldPath,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

import { ProductTileAmountField } from "../../../shared/ProductTileAmountField";
import { useItemFormDetails } from "../hooks";

type Props = {
  initialEntityQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  currencyCode: string;
  entityQuantityFieldPath: StocktakeReportItemEntityQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
  hasUnitOfMeasure: boolean;
};

export const StocktakeProductTileEntityQuantityField = memo(
  ({
    initialEntityQuantity,
    initialSingleUnitGrossPriceAmount,
    initialMeasurementUnit,
    currencyCode,
    entityQuantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
    hasUnitOfMeasure,
  }: Props) => {
    const { control, setValue } = useFormContext<LocalStocktakeReportForm>();

    const { modifiedEntityQuantity, modifiedMeasurementUnit } = useItemFormDetails({
      initialEntityQuantity,
      initialSingleUnitGrossPriceAmount,
      initialMeasurementUnit,
      currencyCode,
      entityQuantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    });

    const calculateNewEntityQuantity = useCallback(
      (event: KeyboardEvent<HTMLInputElement>) =>
        LocalStocktakeService.getNewEntityQuantityFromKeyboardEvent(
          preprocessFormAmountValue(modifiedEntityQuantity) ||
            LocalStocktakeService.ENTITY_QUANTITY_MIN_VALUE,
          event
        ),
      [modifiedEntityQuantity]
    );

    const setFieldValue = useCallback(
      (newEntityQuantity: number | undefined) => {
        setValue(entityQuantityFieldPath, newEntityQuantity);
      },
      [entityQuantityFieldPath, setValue]
    );

    return (
      <Controller
        name={entityQuantityFieldPath}
        control={control}
        render={({ field }) => (
          <ProductTileAmountField
            {...field}
            aria-label="Item quantity form field (optional)"
            calculateNewValue={calculateNewEntityQuantity}
            setNewValue={setFieldValue}
            suffix={hasUnitOfMeasure ? ` ${modifiedMeasurementUnit}` : undefined}
            placeholder={hasUnitOfMeasure ? `0.00 ${modifiedMeasurementUnit}` : "0.00"}
            minValue={LocalStocktakeService.ENTITY_QUANTITY_MIN_VALUE}
            maxValue={LocalStocktakeService.ENTITY_QUANTITY_MAX_VALUE}
            withFractions={true}
            alignTo="center"
          />
        )}
      />
    );
  }
);
StocktakeProductTileEntityQuantityField.displayName = "StocktakeProductTileQuantityField";
