import { OrderRequisition } from "@web/common/network/model";

import { CheckItemsAvailabilityModal } from "src/components/Modal";
import { useProductVariantsQuery } from "src/hooks/useProductVariantsQuery";
import { LiteProductAvailabilityCheckItemInfo } from "src/typegens";

interface Props {
  draft: OrderRequisition;
}

export const LEGACY_CheckItemsAvailabilityModalController = ({ draft }: Props) => {
  const itemsToCheck: LiteProductAvailabilityCheckItemInfo[] = draft.items.map((item) => ({
    variantId: item.variantId,
    supplierItemId: item.skuDetails.supplierInformation.supplierItemId,
    buyerItemId: item.skuDetails.buyerItemId,
    productName: item.name,
  }));

  const availableItemsQuery = useProductVariantsQuery({
    items: itemsToCheck,
    portId: draft.port.id,
    orderType: draft.orderType,
    supplierId: draft.supplierInformation.id,
  });

  return <CheckItemsAvailabilityModal draft={draft} availableItemsQuery={availableItemsQuery} />;
};
