import { QueryClientProvider } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";

import { Environment, FeatureFlagsManagementProvider } from "@web/common";
import { ModalProvider } from "@web/common/contexts/ModalContext";

import { CustomerSupportWidgetWrapper } from "src/components/CustomerSupportWidgetWrapper";
import { ErrorBoundary, RuntimeErrorBoundary } from "src/components/ErrorBoundary";
import { ApiHeadersSetup } from "src/contexts/ApiHeadersSetup";

import { AppAreaWrapper } from "../components/AppAreaWrapper";
import { Authenticated } from "../components/Authenticated";
import { ConfigurationWrapper } from "../components/ConfigurationWrapper";
import { OfflineCapabilitiesGuard } from "../components/OfflineCapabilitiesGuard";
import { OrderDraftStateLifecycle } from "../components/OrderDraftStateLifecycle";
import { AppStateLifecycle, AppStateProvider } from "./AppStateContext";
import { BasketProvider, BasketStateLifecycle } from "./BasketContext";
import { NetworkChangesProvider, NetworkQueryCancellation } from "./NetworkChanges";
import { NetworkDetectorProvider } from "./NetworkDetector";
import { OfflineCapabilitiesProvider } from "./OfflineCapabilities";
import { PendoInitializer } from "./PendoInitializer";
import { QueryClientCreator } from "./QueryClientCreator";
import { SystemMessagesProvider } from "./SystemMessagesProvider";
import { UnleashContextSetup } from "./UnleashContextSetup";

interface AppProvidersProps {
  children: React.ReactNode;
}

const VITE_UNLEASH_PROXY_URL = import.meta.env.VITE_UNLEASH_PROXY_URL as string;
const VITE_UNLEASH_PROXY_CLIENT_KEY = import.meta.env.VITE_UNLEASH_PROXY_CLIENT_KEY as string;
const VITE_UNLEASH_APP_ENVIRONMENT = import.meta.env.VITE_UNLEASH_APP_ENVIRONMENT as Environment;

const UNLEASH_APP_NAME = "lite";
const UNLEASH_REFRESH_INTERVAL = 60;

const AppProviders: React.FC<AppProvidersProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      <RuntimeErrorBoundary pathname={location.pathname}>
        <OfflineCapabilitiesProvider>
          <NetworkDetectorProvider>
            <QueryClientCreator
              render={(queryClient) => (
                <QueryClientProvider client={queryClient}>
                  <FeatureFlagsManagementProvider
                    environment={VITE_UNLEASH_APP_ENVIRONMENT}
                    unleashProxyUrl={VITE_UNLEASH_PROXY_URL}
                    unleashAppName={UNLEASH_APP_NAME}
                    unleashProxyClientKey={VITE_UNLEASH_PROXY_CLIENT_KEY}
                    unleashRefreshInterval={UNLEASH_REFRESH_INTERVAL}
                  >
                    <NetworkChangesProvider>
                      <AppStateProvider>
                        <CustomerSupportWidgetWrapper />
                        <ErrorBoundary navigate={navigate} pathname={location.pathname}>
                          <ApiHeadersSetup />
                          <PendoInitializer />
                          <UnleashContextSetup />
                          <OfflineCapabilitiesGuard>
                            <SystemMessagesProvider>
                              <NetworkQueryCancellation />
                              <BasketProvider>
                                <Authenticated>
                                  <AppStateLifecycle />
                                  <BasketStateLifecycle />
                                  <AppAreaWrapper>
                                    <ConfigurationWrapper>
                                      <ModalProvider>
                                        <OrderDraftStateLifecycle>
                                          {children}
                                        </OrderDraftStateLifecycle>
                                      </ModalProvider>
                                    </ConfigurationWrapper>
                                  </AppAreaWrapper>
                                </Authenticated>
                              </BasketProvider>
                            </SystemMessagesProvider>
                          </OfflineCapabilitiesGuard>
                        </ErrorBoundary>
                      </AppStateProvider>
                    </NetworkChangesProvider>
                  </FeatureFlagsManagementProvider>
                </QueryClientProvider>
              )}
            />
          </NetworkDetectorProvider>
        </OfflineCapabilitiesProvider>
      </RuntimeErrorBoundary>
    </>
  );
};

export default AppProviders;
