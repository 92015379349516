import * as Sentry from "@sentry/browser";
import { Dispatch, PropsWithChildren, createContext, useContext, useEffect, useRef } from "react";

import { BASKET_STORAGE_KEY, VESSEL_STORAGE_KEY } from "src/config/constants";

import { RoutesConfig } from "../config/routes";
import useAppState from "../hooks/useAppState";
import { defaultAppState } from "../state/AppState";
import AppState, { AppStateAction, AppStateStorage } from "../state/models";
import { LiteSupplierInformation } from "../typegens";
import { BasketEntry, BasketStateStorageSchema } from "./BasketContext";

export const AppStateContext = createContext<[state: AppState, action: Dispatch<AppStateAction>]>([
  defaultAppState,
  () => {
    return;
  },
]);

export const useAppStateContext = () => useContext(AppStateContext);

export const AppStateLifecycle = () => {
  const [appState, dispatch] = useAppStateContext();
  const firstUpdate = useRef<boolean>(true);

  useEffect(() => {
    if (firstUpdate.current === true) {
      const data = localStorage.getItem(VESSEL_STORAGE_KEY);
      if (data) {
        const storedState: AppStateStorage = JSON.parse(data as string);
        dispatch({
          type: "setAppStateFromStorage",
          value: storedState,
        });
      }
      firstUpdate.current = false;
      return;
    }
    // NOTE: For now not full state is stored
    // previously this logic was invalid and updating full store should be better tested
    const {
      orderType,
      port,
      deliveryDate,
      dutyFreeDeclaration,
      appArea,
      orderName,
      supplier,
      gatherOutConfig,
    } = appState;

    // TODO #11983: Remove some months after select supplier feature is released
    // This tries to hydrate supplier data in state for a vessel that is in the middle
    // of a gather experience when the feature gets released.
    // Provided that the product IDs in the catalog pointed by the combination of
    // orderType, port & supplier are the same as the ones in the catalog browsed
    // when the feature was released, user will be able to continue shopping
    // both online & offline.
    const gatherExperienceRoutes = [
      RoutesConfig.search,
      RoutesConfig.basket,
      RoutesConfig.category,
      RoutesConfig.orderInfo,
      RoutesConfig.requisitionInfo,
    ];
    const isGatherExperienceRoute = !!gatherExperienceRoutes.find((route) =>
      window.location.pathname.includes(route)
    );

    let isReloadNeeded = false;
    let calculatedSupplier: LiteSupplierInformation | undefined = supplier;

    if (isGatherExperienceRoute && !!orderType && !!port && (!supplier || !supplier.id)) {
      try {
        // Try to get the current basket from LS - basket state is not available at this level
        const storedBasketStateString = localStorage.getItem(BASKET_STORAGE_KEY);
        const parsedStoredBasket = JSON.parse(storedBasketStateString as string);

        if (parsedStoredBasket) {
          const basketState = BasketStateStorageSchema.parse(parsedStoredBasket);
          const firstLineItem: BasketEntry | undefined = (
            basketState.lineItems as unknown as BasketEntry[]
          )[0];
          const supplierId: string =
            firstLineItem?.sku?.supplierInformation.supplierId ||
            firstLineItem?.sku?.supplier?.supplierId ||
            "";
          const supplierName: string = firstLineItem?.sku?.supplierInformation.name || "";

          if (supplierId) {
            calculatedSupplier = {
              id: supplierId,
              name: supplierName,
            };
            isReloadNeeded = true;
          }
        }
      } catch (error) {
        // Only report an error, but do not throw
        Sentry.captureException(error);
      }
    }

    const stateToStore: AppStateStorage = {
      orderType,
      port,
      deliveryDate,
      dutyFreeDeclaration,
      appArea,
      orderName,
      supplier: calculatedSupplier,
      gatherOutConfig,
    };
    localStorage.setItem(VESSEL_STORAGE_KEY, JSON.stringify(stateToStore));

    if (isReloadNeeded) {
      window.location.reload();
    }
  }, [appState, dispatch]);

  return null;
};

export const AppStateProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [appState, dispatch] = useAppState();

  return (
    <AppStateContext.Provider value={[appState, dispatch]}>{children}</AppStateContext.Provider>
  );
};

export default AppStateContext;
