import ChevronRightOutlined from "@mui/icons-material/ChevronRightOutlined";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import { Category } from "@web/models";
import { MuiIcon, Paragraph } from "@web/ui";

import { getCategoryByIdPath } from "src/config/routes";

type Props = {
  breadcrumbs: Category[];
};

export const Breadcrumbs = ({ breadcrumbs }: Props) => {
  return (
    <div className="flex items-center gap-1" aria-label="breadcrumbs">
      {breadcrumbs.map((breadcrumb, index) => {
        const isLast = index === breadcrumbs.length - 1;
        return (
          <Fragment key={breadcrumb.id}>
            <Paragraph size="200">
              {isLast ? (
                breadcrumb.name
              ) : (
                <Link
                  className="hover:text-primaryDefault active:text-primaryDefault focus:outline-none focus-visible:ring-1 ring-primaryDefault active:ring-primaryPressed"
                  to={getCategoryByIdPath(breadcrumb.id)}
                >
                  {breadcrumb.name}
                </Link>
              )}
            </Paragraph>
            {!isLast && (
              <MuiIcon
                Icon={ChevronRightOutlined}
                className="text-textIcon-blackSecondary w-4"
                aria-hidden="true"
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
