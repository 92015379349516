import AddOutlined from "@mui/icons-material/AddOutlined";
import RemoveOutlined from "@mui/icons-material/RemoveOutlined";
import { emulateTab } from "emulate-tab";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { LeadTime, TotalUnitOfMeasure, useModalContext } from "@web/common";
import ErrorLabel from "@web/common/components/ErrorLabel";
import { PriceTypeFlag } from "@web/common/components/PriceTypeFlag";
import WarningLabel from "@web/common/components/WarningLabel";
import {
  getLowestPriceSku,
  getMinimumOrderQuantity,
  getMinimumQuantityNumber,
  getSalesEntityPrice,
  getSalesEntityQuantity,
} from "@web/common/utils";
import { IconButton, Label, Paragraph } from "@web/ui";
import { formatMoney, imagor } from "@web/utils";

import { LazyImageWrapper } from "src/components/LazyImageWrapper";
import { ProductDetailsModal } from "src/components/ProductDetailsModal";
import ProductImageSVG from "src/icons/ProductImage.svg";
import { LiteProduct } from "src/typegens";

interface Props {
  product: LiteProduct;
  index: number;
  quantity: number;
  addToBasket: (product: LiteProduct, quantity: number) => void;
  maxLength?: number;
}

export const ProductBox = memo(
  ({ product, index, quantity, addToBasket, maxLength = 4 }: Props) => {
    const { t } = useTranslation();
    const initialQuantity = quantity;
    const [inputValue, setInputValue] = useState(initialQuantity);
    const [selectedInput, setSelectedInput] = useState(0);
    const [minQuantityError, setMinQuantityError] = useState(false);

    const productSku = product.skuList[0];

    const minimumOrderQuantity = productSku ? getMinimumOrderQuantity(productSku) : undefined;
    const salesEntityQuantity = productSku ? getSalesEntityQuantity(productSku) : 0;
    const minimumQuantityNumber = productSku ? getMinimumQuantityNumber(productSku) : 0;

    const sku = getLowestPriceSku(product.skuList);

    const costFormatted = sku ? formatMoney(sku.price.costPrice) : "0";
    const salesEntityPriceFormatted = sku ? formatMoney(getSalesEntityPrice(sku)) : "0";
    const unitFormatted = sku ? sku.measurementUnit : "-";
    const costPerUnitFormatted = `${costFormatted}/${unitFormatted}`;
    const imageUrl = product.images[0]?.imageUrl;
    const leadTime = sku?.leadTime;

    const { openModal, closeModal } = useModalContext();

    const handleUpdateBasket = () => {
      addToBasket(product, Number(inputValue));
    };

    useEffect(() => {
      handleUpdateBasket();
      // DO NOT change the dependency array below, unless you know what you are doing.
      // Make sure to test any change to ensure that change won't cause inifite loop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue]);

    if (!Array.isArray(product.skuList) || product.skuList.length === 0) {
      console.error(
        `Catalog doesn't provide enough information for product ${product.id}:${product.name}`
      );
      return null;
    }

    const filterInt = (value: string) => {
      if (/^[-+]?\d+$/.test(value)) {
        return Number(value);
      } else {
        return NaN;
      }
    };

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      if (!isNaN(filterInt(value)) || value === "") {
        setInputValue(Number(value));
      }
    };

    const handleBlur = () => {
      if (
        minimumOrderQuantity !== undefined &&
        minimumOrderQuantity > 1 &&
        inputValue < minimumQuantityNumber
      ) {
        if (quantity === 0) {
          setInputValue(0);
        } else {
          setInputValue(minimumQuantityNumber);
          setMinQuantityError(true);
          setTimeout(() => setMinQuantityError(false), 5000);
        }
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value;
      if (e.key === "Enter") {
        if (!isNaN(filterInt(value)) || value === "") {
          setInputValue(Number(value));
        }
        setSelectedInput(selectedInput + 1);

        emulateTab();
      }
      if (e.key === "ArrowUp") {
        emulateTab.backwards();
      }
      if (e.key === "ArrowDown") {
        emulateTab();
      }
      if (e.key === "ArrowRight") {
        handleUpdateBasket();
        if (
          minimumOrderQuantity !== undefined &&
          minimumOrderQuantity > 1 &&
          quantity < minimumOrderQuantity
        ) {
          setInputValue(quantity + minimumQuantityNumber);
        } else if (quantity === 9999) {
          null;
        } else {
          setInputValue(quantity + 1);
        }
      }
      if (e.key === "ArrowLeft" && quantity > 0) {
        handleUpdateBasket();
        if (
          minimumOrderQuantity !== undefined &&
          minimumOrderQuantity > 1 &&
          quantity < minimumQuantityNumber + 1
        ) {
          setInputValue(0);
        } else {
          setInputValue(quantity - 1);
        }
      }
    };

    const isPlusButtonDisabled = (quantity + 1).toString().length > maxLength;

    const handlePlus = () => {
      if (isPlusButtonDisabled) {
        return;
      }
      handleUpdateBasket();
      if (
        minimumOrderQuantity !== undefined &&
        minimumOrderQuantity > 1 &&
        quantity < minimumOrderQuantity
      ) {
        setInputValue(quantity + minimumQuantityNumber);
      } else if (quantity === 0) {
        setInputValue(1);
      } else {
        setInputValue(quantity + 1);
      }
    };

    const handleMinus = () => {
      handleUpdateBasket();
      if (quantity === 0) {
        null;
      } else if (
        minimumOrderQuantity !== undefined &&
        minimumOrderQuantity > 1 &&
        quantity < minimumQuantityNumber + 1
      ) {
        setInputValue(0);
      } else {
        setInputValue(quantity - 1);
      }
    };

    const handleOpenProductDetailsModal = () => {
      openModal(
        <ProductDetailsModal
          closeModal={closeModal}
          quantity={quantity}
          addToBasket={addToBasket}
          product={product}
          withSidebar
        />
      );
    };

    return (
      <div data-testid="product-box" className="flex">
        <div className="bg-neutral_0 px-2 xl:px-4 py-3 flex flex-grow rounded-lg border-neutral-200 border">
          <div className="flex flex-col flex-none">
            <div
              data-testid="product-card"
              className="flex cursor-pointer"
              tabIndex={-1}
              onClick={() => handleOpenProductDetailsModal()}
            >
              <LazyImageWrapper>
                <img
                  data-src={(imageUrl && imagor(imageUrl)) || ProductImageSVG}
                  loading="lazy"
                  className="whitespace-nowrap h-9 w-9"
                />
              </LazyImageWrapper>
            </div>
          </div>
          <div className="flex flex-col ml-2">
            <div className="break-normal">
              <div
                data-testid="product-card"
                className="flex cursor-pointer"
                tabIndex={-1}
                onClick={() => handleOpenProductDetailsModal()}
              >
                <Label size="200" className="cursor-pointer">
                  {product.name}
                </Label>
              </div>
              {product?.supplierInformation?.name && (
                <Paragraph
                  size="300"
                  color="text-textIcon-blackSecondary"
                  className="mt-1 font-medium"
                >
                  {t("common.components.productCard.suppliedBy")}{" "}
                  <span className="capitalize text-primaryDefault">
                    {product.supplierInformation.name}
                  </span>
                </Paragraph>
              )}
              <div className="flex mt-4 space-x-2">
                <LeadTime customStyling="w-13" leadTime={leadTime} />
                <div
                  className={`bg-neutral_100 rounded-md my-1 px-2 py-1 flex flex-row justify-center`}
                >
                  <Paragraph size="300" className="text-neutral_700">
                    {salesEntityQuantity} {unitFormatted}
                  </Paragraph>
                </div>
              </div>
            </div>
          </div>
          {minQuantityError ? (
            <div className="flex flex-col w-12">
              <ErrorLabel
                errorMessage={t("common.cards.addToCart.minimumOrderQuantityError")}
                minimumOrderQuantity={minimumOrderQuantity}
                measurementUnit={unitFormatted}
              />
            </div>
          ) : null}
          <div className="flex ml-auto justify-center items-center">
            <div className="flex flex-end">
              <div>
                <div className="flex flex-end flex-row-reverse mb-[6px] mr-1">
                  <div className="ml-1">
                    <PriceTypeFlag priceType={productSku.priceType} />
                  </div>
                  <div className="flex flex-col pl-3">
                    <div className="flex flex-col items-end">
                      <div className="flex items-baseline text-right">
                        <Label size="100">{salesEntityPriceFormatted}</Label>
                      </div>
                      <div className="flex items-baseline text-right">
                        <Paragraph size="300" color="text-textIcon-blackSecondary">
                          {costPerUnitFormatted}
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  {minimumOrderQuantity !== undefined &&
                  minimumOrderQuantity > 1 &&
                  quantity < minimumQuantityNumber &&
                  minimumQuantityNumber > 1 ? (
                    <div className="min-w-[140px] text-center">
                      <WarningLabel
                        warningMessage={t("common.views.basket.minimum")}
                        minimumQuantityNumber={minimumQuantityNumber}
                        minimumOrderQuantity={minimumOrderQuantity}
                        measurementUnit={unitFormatted}
                      />
                    </div>
                  ) : (
                    <TotalUnitOfMeasure
                      className="w-12 justify-end min-w-[140px] text-center"
                      quantityInBasket={quantity}
                      salesEntityQuantity={salesEntityQuantity}
                      measurementUnit={unitFormatted}
                      variant={quantity > 0 ? "positive" : undefined}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="flex ml-2 items-center">
              <IconButton
                size="large"
                variant="secondary"
                shape="circle"
                label="Decrease"
                Icon={RemoveOutlined}
                onClick={handleMinus}
                tabIndex={-1}
                data-testid="decrease-button"
              />
              <input
                value={quantity}
                autoFocus={index === selectedInput}
                maxLength={maxLength}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                className="flex-none w-8 h-7 mx-2 border rounded-lg text-center"
                aria-label="Item Quantity"
                data-testid="itemQuantity"
              />
              <IconButton
                size="large"
                variant="secondary"
                shape="circle"
                label="Increase"
                Icon={AddOutlined}
                onClick={handlePlus}
                disabled={isPlusButtonDisabled}
                tabIndex={-1}
                data-testid="increase-button"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

ProductBox.displayName = "ProductBox";
