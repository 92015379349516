import { z } from "zod";

import { preprocessFormAmountValue } from "@web/utils";

import { LiteStocktakeItemSchema } from "./LiteStocktakeItemSchema";

export const StocktakeReportDraftItemSchema = LiteStocktakeItemSchema.omit({
  quantity: true,
}).extend({
  // We need separate validation setup for draft, because we want to be able to store empty values for them
  entityQuantity: z.preprocess(preprocessFormAmountValue, z.number().optional()),
  lineNumber: z.number().positive(),
});

export type LocalStocktakeReportDraftItem = z.infer<typeof StocktakeReportDraftItemSchema>;
