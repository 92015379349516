import { UseQueryResult } from "@tanstack/react-query/src/types";
import { useFlag } from "@unleash/proxy-client-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OnChooseReplacement } from "@web/common";
import { useModalContext } from "@web/common/contexts";
import { OrderRequisition } from "@web/common/network/model";
import { Paragraph, RegularButton } from "@web/ui";

import { RoutesConfig } from "src/config/routes";
import { useAppStateContext } from "src/contexts/AppStateContext";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useOfflineCapabilities } from "src/contexts/OfflineCapabilities";
import { useAddProductsToBasket } from "src/hooks/useAddProductsToBasket";
import useBasket from "src/hooks/useBasket";
import { type LiteProductAvailabilityCheckResponse, LiteProductSku } from "src/typegens";

import { ItemsAvailabilityModalContainer } from "../ItemsAvailabilityModalContainer";
import { ChosenReplacements, ProductDiffList } from "../ProductDiffList";

type Props = {
  order: OrderRequisition;
  availableItemsQuery: UseQueryResult<LiteProductAvailabilityCheckResponse>;
  isDownloadingOfflineCatalog?: boolean;
};

export const ReorderModal = ({
  order,
  availableItemsQuery,
  isDownloadingOfflineCatalog = false,
}: Props) => {
  const isMissingItemsReplacementsEnabled = useFlag("va-missing-items-replacements");
  const { areOfflineCapabilitiesEnabled } = useOfflineCapabilities();
  const { isOffline } = useNetworkDetector();
  const { closeModal } = useModalContext();
  const [, dispatch] = useAppStateContext();
  const { clearBasket } = useBasket();
  const navigate = useNavigate();
  const { addProductsToBasket } = useAddProductsToBasket({
    catalogItems: order.items,
    rfqItems: order.rfqItems || [],
    extraItems: order.extraItems,
    port: order.port,
    // Duty Free needs to be passed, because the redirection is to Basket and not Gather Experience,
    // and Order Summary Form does not have Duty Free fields, where the user could set it.
    dutyFreeDeclaration: order.dutyFreeDeclaration,
    orderType: order.orderType,
    subject: `Reorder: ${order.subject}`,
    supplierInformation: order.supplierInformation,
    deliveryDate: undefined,
  });

  const handleCreateNewOrder = () => {
    if (availableItemsQuery.isSuccess) {
      clearBasket();
      dispatch({
        type: "setNewOrderInReorderSetup",
        value: {
          orderType: order.orderType,
          portId: order.port.id,
          supplierId: order.supplierInformation.id,
        },
      });
      navigate(RoutesConfig.gatherSetup);
      closeModal();
    }
  };

  const [chosenReplacements, setChosenReplacements] = useState<ChosenReplacements>([]);

  const handleChooseReplacement: OnChooseReplacement = ({ replacementForId, replacementId }) => {
    setChosenReplacements((prev) => {
      return [
        ...prev.filter((item) => item.replacementForId !== replacementForId),
        ...(replacementId ? [{ replacementForId, replacementId }] : []),
      ];
    });
  };

  const handleAddToBasket = () => {
    if (availableItemsQuery.isSuccess) {
      const replacements = chosenReplacements.reduce<
        Array<{ sku: LiteProductSku; quantity: number }>
      >((acc, cur) => {
        const replacement = availableItemsQuery.data.replacementItems[cur.replacementForId].find(
          (replacement) => replacement.id === cur.replacementId
        );
        if (!replacement) {
          return acc;
        }
        acc.push({
          sku: { ...replacement },
          quantity:
            order.items.find((item) => item.variantId === cur.replacementForId)?.quantity || 1,
        });
        return acc;
      }, []);
      addProductsToBasket(availableItemsQuery.data.availableItems, replacements);
      closeModal();
    }
  };

  const isPending =
    availableItemsQuery.isPending || availableItemsQuery.isFetching || isDownloadingOfflineCatalog;
  const error = availableItemsQuery.isError
    ? "There was a problem checking availability of your items. Please try again."
    : undefined;
  const isBlocked = isPending || !!error;
  const ratio: [number, number] | undefined = availableItemsQuery.isSuccess
    ? [availableItemsQuery.data.availableItems.length, order.items.length]
    : undefined;

  useEffect(() => {
    const replacements = availableItemsQuery.data?.replacementItems;
    if (replacements) {
      // Preselect all replacements once when they become available
      setChosenReplacements(
        Object.entries(replacements).map((replacement) => ({
          replacementForId: replacement[0],
          replacementId: replacement[1][0].id,
        }))
      );
    }
  }, [availableItemsQuery.data?.replacementItems]);

  const noAvailableItems = availableItemsQuery.data?.availableItems.length ?? 0;
  const noChosenReplacements = chosenReplacements.length;
  const areAnyItemsAvailable = noAvailableItems > 0 || noChosenReplacements > 0;

  return (
    <ItemsAvailabilityModalContainer
      closeModal={closeModal}
      ctaButtons={
        <>
          <RegularButton
            className="ml-auto mr-5"
            variant="secondary"
            size="large"
            label="Cancel"
            onClick={() => closeModal()}
          />
          <RegularButton
            variant="primary"
            size="large"
            label={
              !isBlocked && areAnyItemsAvailable
                ? `Add ${noAvailableItems + noChosenReplacements} Item${
                    noAvailableItems + noChosenReplacements > 1 ? "s" : ""
                  } to your basket`
                : "Create a New Order"
            }
            disabled={isBlocked}
            onClick={() => (areAnyItemsAvailable ? handleAddToBasket() : handleCreateNewOrder())}
            data-testid="reorder-modal-cta"
          />
        </>
      }
      order={order}
      loading={isPending}
      isDownloadingOfflineCatalog={isDownloadingOfflineCatalog}
      error={error}
      ratio={ratio}
      title="Reorder Items"
    >
      {areOfflineCapabilitiesEnabled &&
        isOffline &&
        isMissingItemsReplacementsEnabled &&
        noAvailableItems < order.items.length &&
        !availableItemsQuery.isError && (
          <Paragraph size="200" color="text-warningDefault" className="mb-5">
            You are currently Offline and can only continue without unavailable items. In order to
            automatically find replacements for unavailable items, connect to the Internet and wait
            for the results to appear.
          </Paragraph>
        )}
      {availableItemsQuery.isSuccess ? (
        <ProductDiffList
          sourceProducts={order.items}
          availableItems={availableItemsQuery.data.availableItems}
          replacementItems={availableItemsQuery.data.replacementItems}
          chosenReplacements={chosenReplacements}
          onChooseReplacement={handleChooseReplacement}
        />
      ) : undefined}
    </ItemsAvailabilityModalContainer>
  );
};
