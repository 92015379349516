import classnames from "classnames";
import { InputHTMLAttributes, forwardRef } from "react";

export type ProductTileTextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  alignTo?: "start" | "center" | "end";
};

export const ProductTileTextInput = forwardRef<HTMLInputElement, ProductTileTextInputProps>(
  ({ className, alignTo = "start", ...props }, ref) => (
    <input
      {...props}
      ref={ref}
      type="text"
      className={classnames(
        className,
        {
          "px-2": alignTo !== "center",
          "text-left": alignTo === "start",
          "text-center": alignTo === "center",
          "text-right": alignTo === "end",
        },
        "block w-full h-6.5 border rounded-lg focus:outline-none focus-visible:ring-2 ring-offset-2 ring-offset-white focus:ring-primaryDefault"
      )}
    />
  )
);
ProductTileTextInput.displayName = "ProductTileTextInput";
