import { memo, useCallback } from "react";

import { LocalLiteStocktakeExtraItem } from "src/models";

import { StocktakeDetailsProductTile } from "../shared/StocktakeDetailsProductTile";
import { StocktakeExtraProductTileDetails } from "../shared/StocktakeExtraProductTileDetails";

type Props = {
  product: LocalLiteStocktakeExtraItem;
};

export const StocktakeDetailsExtraProductTile = memo(({ product }: Props) => {
  // All extra items have `salesEntityQuantity` equal to `1`
  const salesEntityQuantity = 1;

  const renderProductDetails = useCallback(
    (product: LocalLiteStocktakeExtraItem, className: string) => (
      <StocktakeExtraProductTileDetails product={product} className={className} />
    ),
    []
  );

  return (
    <StocktakeDetailsProductTile
      product={product}
      renderProductDetails={renderProductDetails}
      salesEntityQuantity={salesEntityQuantity}
      measurementUnit={product.measurementUnit}
      singleUnitGrossPrice={product.singleUnitGrossPrice}
      quantity={product.quantity}
      robValue={product.robValue}
    />
  );
});
StocktakeDetailsExtraProductTile.displayName = "StocktakeDetailsExtraProductTile";
