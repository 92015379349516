import classnames from "classnames";
import { matchSorter } from "match-sorter";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  DEFAULT_DELIVERY_TIME,
  getDefaultOrderName,
  getIconFromOrderOrStocktakeType,
  shouldRenderOrderTypes,
} from "@web/common";
import AnchorWhite from "@web/common/icons/AnchorWhite.svg";
import {
  Autocomplete,
  CircledImage,
  DatePicker,
  Heading,
  Input,
  Label,
  OptionType,
  Paragraph,
  RegularButton,
  Select,
  TimePicker,
  Tooltip,
  containerPadding,
} from "@web/ui";
import { convertTimeToISODateString, usePrevious } from "@web/utils";

import { AttentionInfo } from "src/components/AttentionInfo";
import { ORDER_NAME_MAX_LENGTH } from "src/config/constants";
import { LocalConfigurationService } from "src/services/LocalConfigurationService";
import { OrderTypeConfiguration } from "src/state/models";

import { useToastMessage } from "../../hooks/useToastMessage";
import { DutyFreeDeclaration } from "./DutyFreeDeclaration";
import { FormValues, ValidatedForm, ValidatedFormValues } from "./models";

type Props = {
  onSubmitForm: (form: ValidatedForm) => void;
  orderTypeConfigs: OrderTypeConfiguration[];
  isPunchOutIntegration: boolean;
  preselectedOrderType: FormValues["orderType"] | undefined;
  preselectedPort: FormValues["port"] | undefined;
  preselectedSupplier: FormValues["supplier"] | undefined;
  isPending: boolean;
};

type PortSelectorResultProps = {
  port: ValidatedFormValues["port"];
  className?: string;
};

const PortSelectorResult = ({ port }: PortSelectorResultProps) => {
  return (
    <div className="flex items-center">
      <CircledImage Icon={AnchorWhite} size={5} hashKey={port.id} />
      <div className="flex items-center text-left gap-2">
        <Paragraph size="200" weight="heavy" className="flex-none">
          {port.locationCode}
        </Paragraph>
        <Paragraph color="text-textIcon-blackSecondary" size="200" weight="heavy">
          {port.name}
        </Paragraph>
      </div>
    </div>
  );
};

const OrderTypeSelect = Select<ValidatedFormValues["orderType"]>;
const SupplierSelect = Select<ValidatedFormValues["supplier"]>;
const PortSelector = Autocomplete<ValidatedFormValues["port"]>;

const searchPorts = (ports: ValidatedFormValues["port"][], query: string) =>
  matchSorter(ports, query, {
    keys: ["_search"],
  });

export const PreconfigureOrderForm = ({
  onSubmitForm,
  orderTypeConfigs,
  preselectedOrderType,
  preselectedPort,
  preselectedSupplier,
  isPunchOutIntegration,
  isPending,
}: Props) => {
  const { setToastMessage } = useToastMessage();
  const canRenderOrderTypes = shouldRenderOrderTypes({
    orderTypes: orderTypeConfigs,
    isPunchOutIntegration,
  });
  const form = useForm<FormValues>({
    defaultValues: {
      port: preselectedPort,
      deliveryDate: {
        date: "",
        time: DEFAULT_DELIVERY_TIME,
      },
      dutyFreeDeclaration: {
        dutyFree: undefined,
        name: "",
        position: "",
      },
      orderType: preselectedOrderType,
      orderName: "",
      supplier: preselectedSupplier,
    },
    mode: "onBlur",
    shouldUnregister: true,
  });

  const [portSelectorInputValue, setPortSelectorInputValue] = useState("");

  const { register, control, handleSubmit, formState, setValue, watch } = form;
  const { errors } = formState;

  const selectedOrderType = watch("orderType");
  const prevSelectedOrderType = usePrevious<FormValues["orderType"]>(selectedOrderType);

  const selectedPort = watch("port");
  const prevSelectedPort = usePrevious<FormValues["port"]>(selectedPort);

  const selectedSupplier = watch("supplier");

  const isDutyFree = watch("dutyFreeDeclaration.dutyFree");

  const isDutyFreeEnabled: boolean = useMemo(
    () => !!selectedSupplier?.dutyFree,
    [selectedSupplier?.dutyFree]
  );

  const orderTypeOptions: OptionType<ValidatedFormValues["orderType"]>[] = useMemo(
    () =>
      orderTypeConfigs.map((orderTypeConfig) => ({
        key: orderTypeConfig.type,
        value: orderTypeConfig,
        label: orderTypeConfig.name,
        LeadingIcon: getIconFromOrderOrStocktakeType(orderTypeConfig.type),
      })),
    [orderTypeConfigs]
  );

  const portOptions: ValidatedFormValues["port"][] = useMemo(
    () => selectedOrderType?.ports || [],
    [selectedOrderType?.ports]
  );

  const supplierOptions: OptionType<ValidatedFormValues["supplier"]>[] = useMemo(
    () =>
      (selectedPort?.suppliers || []).map((supplier) => ({
        key: supplier.id,
        value: supplier,
        label: `${supplier.name}${supplier.freeDelivery ? "" : " - Delivery charges may apply"}`,
      })),
    [selectedPort?.suppliers]
  );

  useEffect(() => {
    // Reset dependent field when value changes
    if (selectedOrderType?.type !== prevSelectedOrderType?.type) {
      if (selectedOrderType?.ports.length === 1) {
        setValue("port", selectedOrderType?.ports[0], { shouldDirty: true, shouldTouch: true });
      } else {
        setValue("port", undefined, { shouldDirty: true, shouldTouch: true });
      }
    }
  }, [selectedOrderType?.type, prevSelectedOrderType?.type, setValue, selectedOrderType?.ports]);

  useEffect(() => {
    // Reset dependent field when value changes
    if (selectedPort?.id !== prevSelectedPort?.id) {
      if (selectedPort?.suppliers.length === 1) {
        setValue("supplier", selectedPort?.suppliers[0], { shouldDirty: true, shouldTouch: true });
      } else {
        setValue("supplier", undefined, { shouldDirty: true, shouldTouch: true });
      }

      // Update autocomplete controlled input value to be the exact name of the selected port.
      // Clear the input value in cases where the port selection has been removed because e.g.
      // the order type selector value changed.
      setPortSelectorInputValue(selectedPort?.name || "");
    }
  }, [
    selectedPort?.id,
    prevSelectedPort?.id,
    setValue,
    selectedPort?.suppliers,
    selectedPort?.name,
  ]);

  const handlePortSelectorInputValueChange = useCallback(
    (value: string) => {
      if (selectedPort) {
        // When value of port text input changes by manual user's field edit,
        // remove any port that may have been already selected.
        // This will trigger reactions:
        // - supplier select will be cleared, and thus disabled
        // - port input field will be cleared in respective useEffect,
        //   so in order to keep the user's input in this case, re-set
        //   it after minimal timeout
        setValue("port", undefined, { shouldDirty: true, shouldTouch: true });
        setTimeout(() => setPortSelectorInputValue(value), 0);
      } else {
        // In normal flow there's no need for any timeouts when setting the value
        // of the controlled input
        setPortSelectorInputValue(value);
      }
    },
    [selectedPort, setValue]
  );

  const onSubmit = ({
    orderType,
    port,
    supplier,
    deliveryDate,
    dutyFreeDeclaration,
    orderName,
  }: FormValues) => {
    // These fields should never come empty since field validation exists, however it's not schema-based,
    // so the hook form does not know that.
    if (!orderType || !port || !supplier) {
      setToastMessage({
        type: "failure",
        message:
          "There was a problem with starting the order - missing Order Type, Port or Supplier. Please contact Customer Support.",
      });
      return;
    }

    const formValues: ValidatedForm = {
      orderType,
      port,
      supplier,
      ...(!isPunchOutIntegration
        ? {
            deliveryDate: convertTimeToISODateString(deliveryDate?.time, deliveryDate?.date),
          }
        : {}),
      ...(isDutyFreeEnabled
        ? {
            dutyFreeDeclaration: {
              dutyFree: dutyFreeDeclaration.dutyFree === "yes",
              name: dutyFreeDeclaration.name,
              position: dutyFreeDeclaration.position,
            },
          }
        : {}),
      orderName: !isPunchOutIntegration
        ? orderName || getDefaultOrderName(port, deliveryDate?.date)
        : undefined,
    };

    onSubmitForm(formValues);
  };

  const attentionInfoItems = useMemo(() => {
    const supplierConfig = LocalConfigurationService.getSelectedSupplierConfig({
      orderTypeConfigs,
      portId: selectedPort?.id,
      orderType: selectedOrderType?.type,
      supplierId: selectedSupplier?.id,
    });

    return supplierConfig?.attentionInfo || [];
  }, [orderTypeConfigs, selectedOrderType?.type, selectedPort?.id, selectedSupplier?.id]);

  const isPortFieldDisabled = !selectedOrderType || (!!selectedPort && portOptions.length === 1);
  const isSupplierFieldDisabled =
    !selectedOrderType || !selectedPort || (!!selectedSupplier && supplierOptions.length === 1);

  const hasPortFieldOnlyOneOption = portOptions.length === 1;
  const hasSupplierFieldOnlyOneOption = supplierOptions.length === 1;

  return (
    <div className={classnames(containerPadding, "mt-4.5 mb-8 flex flex-col items-center")}>
      <form
        className="flex flex-col gap-6.5 w-full sm:w-[28rem]"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {!isPunchOutIntegration && (
          <>
            <div className="flex flex-col gap-4">
              <Heading size="300">Order Name</Heading>
              <Controller
                name="orderName"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Order name"
                    maxLength={ORDER_NAME_MAX_LENGTH}
                    withBorder
                    testId="orderName"
                  />
                )}
              />
            </div>
            <hr />
          </>
        )}

        <div className="flex flex-col gap-5">
          <Heading size="300">
            {canRenderOrderTypes
              ? "Please select order type, port of delivery and supplier to view the available products"
              : "Please select port of delivery and supplier to view the available products"}
          </Heading>
          {/* The section is hidden via CSS instead of conditional rendering due to the form having
           * the `shouldUnregister` option set to `true`, which would remove the `orderType` value
           * from the form if the input with its controller were not rendered at all.
           */}
          <div className={classnames("flex flex-col gap-2", { hidden: !canRenderOrderTypes })}>
            <Label size="100">1. Order Type</Label>
            <Controller
              name="orderType"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please select an order type",
                },
              }}
              render={({ field }) => (
                <OrderTypeSelect
                  {...field}
                  onChange={(option) => field.onChange(option.value)}
                  value={orderTypeOptions.find((option) => option.value.type === field.value?.type)}
                  options={orderTypeOptions}
                  allowMultilineOptions={true}
                  placeholder="Select order type..."
                  errorMessage={errors.orderType?.message}
                  testId="orderType"
                />
              )}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label size="100">{canRenderOrderTypes ? "2" : "1"}. Port of Delivery</Label>
            <Controller
              name="port"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please select a port",
                },
              }}
              render={({ field }) => (
                <Tooltip
                  tooltipText={
                    !selectedOrderType
                      ? "First select the Order Type"
                      : hasPortFieldOnlyOneOption
                      ? "The Port of Delivery is selected for you if there is only one option available"
                      : undefined
                  }
                >
                  <PortSelector
                    items={portOptions}
                    searchItems={searchPorts}
                    renderResult={(port) => <PortSelectorResult port={port} />}
                    onSelect={(port) => {
                      setValue(field.name, port, { shouldDirty: true, shouldTouch: true });
                    }}
                    onChange={(value) => handlePortSelectorInputValueChange(value)}
                    value={portSelectorInputValue}
                    hasError={!!errors?.port?.message}
                    placeholder="Which port will you arrive at?"
                    disabled={isPortFieldDisabled}
                    data-testid="portSelector"
                  />
                  {!!errors?.port?.message && (
                    <Paragraph size="200" color="text-dangerDefault">
                      {errors?.port?.message}
                    </Paragraph>
                  )}
                </Tooltip>
              )}
            />
          </div>
          <div className="flex flex-col gap-2">
            <Label size="100">{canRenderOrderTypes ? "3" : "2"}. Supplier</Label>
            <Controller
              name="supplier"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Please select a supplier",
                },
              }}
              render={({ field }) => (
                <Tooltip
                  tooltipText={
                    !selectedPort
                      ? "First select the Order Type and Port of Delivery"
                      : hasSupplierFieldOnlyOneOption
                      ? "The Supplier is selected for you if there is only one option available"
                      : undefined
                  }
                >
                  <SupplierSelect
                    {...field}
                    onChange={(option) => field.onChange(option.value)}
                    value={supplierOptions.find((option) => option.value.id === field.value?.id)}
                    options={supplierOptions}
                    allowMultilineOptions={true}
                    placeholder="Select supplier..."
                    disabled={isSupplierFieldDisabled}
                    errorMessage={errors.supplier?.message}
                    testId="supplierSelector"
                  />
                </Tooltip>
              )}
            />
          </div>
          {attentionInfoItems.length > 0 && <AttentionInfo items={attentionInfoItems} />}
        </div>
        <hr />
        {!isPunchOutIntegration && (
          <>
            {/* TODO: #11699 - refactor DeliveryDate and use it here */}
            <div className="flex flex-col gap-4">
              <Heading size="300">Desired Delivery Date</Heading>
              <div className="flex gap-4">
                <Controller
                  name="deliveryDate.date"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter a delivery date",
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        label="Date"
                        onChange={field.onChange}
                        dataTestId="deliveryDate_datePicker"
                        error={
                          errors?.deliveryDate?.date?.message && (
                            <Paragraph size="200" color="text-dangerDefault">
                              {errors?.deliveryDate?.date?.message}
                            </Paragraph>
                          )
                        }
                        placeholder="Set an arrival date"
                      />
                    );
                  }}
                />
                <Controller
                  name="deliveryDate.time"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter a delivery time",
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <TimePicker
                        {...field}
                        label="Time"
                        initialValue={DEFAULT_DELIVERY_TIME}
                        testId="deliveryDate_timePicker"
                        errorMessage={errors?.deliveryDate?.time?.message}
                        vAlignTo="top"
                      />
                    );
                  }}
                />
              </div>
              <Paragraph size="200" color="text-textIcon-blackSecondary">
                The selected time is only a suggestion forwarded to the Supplier.
                <br />
                Please contact the Supplier to confirm the final delivery time.
              </Paragraph>
            </div>
            <hr />
          </>
        )}
        {isDutyFreeEnabled && (
          <>
            <DutyFreeDeclaration isDutyFree={isDutyFree} errors={errors} register={register} />
            <hr />
          </>
        )}
        <div className="flex justify-end">
          <RegularButton
            variant="primary"
            size="large"
            label="Start shopping"
            type="submit"
            loading={isPending}
            data-testid="preconfigureOrderSetup_submit"
          />
        </div>
      </form>
    </div>
  );
};
