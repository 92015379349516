import { memo } from "react";

import { Badge, Paragraph } from "@web/ui";
import { preprocessFormAmountValue } from "@web/utils";

import {
  StocktakeReportItemEntityQuantityFieldPath,
  StocktakeReportItemMeasurementUnitFieldPath,
  StocktakeReportItemSingleUnitGrossPriceFieldPath,
} from "src/models";
import { LocalStocktakeService } from "src/services/LocalStocktakeService";

import { useItemFormDetails } from "../shared/hooks";

type Props = {
  initialEntityQuantity: number | null | undefined;
  initialSingleUnitGrossPriceAmount: number | null | undefined;
  initialMeasurementUnit: string;
  currencyCode: string;
  entityQuantityFieldPath: StocktakeReportItemEntityQuantityFieldPath;
  singleUnitGrossPriceFieldPath: StocktakeReportItemSingleUnitGrossPriceFieldPath;
  measurementUnitFieldPath: StocktakeReportItemMeasurementUnitFieldPath;
};

export const RobDetails = memo(
  ({
    initialEntityQuantity,
    initialSingleUnitGrossPriceAmount,
    initialMeasurementUnit,
    currencyCode,
    entityQuantityFieldPath,
    singleUnitGrossPriceFieldPath,
    measurementUnitFieldPath,
  }: Props) => {
    const { modifiedEntityQuantity, modifiedMeasurementUnit } = useItemFormDetails({
      initialEntityQuantity,
      initialSingleUnitGrossPriceAmount,
      initialMeasurementUnit,
      currencyCode,
      entityQuantityFieldPath,
      singleUnitGrossPriceFieldPath,
      measurementUnitFieldPath,
    });

    const processedModifiedEntityQuantity = preprocessFormAmountValue(modifiedEntityQuantity);

    return (
      <>
        <Paragraph size="200" weight="heavy" color="text-textIcon-blackSecondary">
          ROB:
        </Paragraph>
        <Badge
          size="m"
          color="success"
          contrast="low"
          text={`${LocalStocktakeService.formatEntityQuantity(processedModifiedEntityQuantity)}${
            modifiedMeasurementUnit ? ` ${modifiedMeasurementUnit}` : ""
          }`}
        />
      </>
    );
  }
);
RobDetails.displayName = "StocktakeProductTileRobDetails";
