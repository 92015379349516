import { useNavigate } from "react-router-dom";

import { Heading, Paragraph, RegularButton } from "@web/ui";

import S2SLogo from "src/icons/S2SLogo.svg?react";

interface Props {
  displayMode?: "view" | "inline";
  hasGoBack?: boolean;
  onGoBack?: () => void;
}

const useGoBack = (onGoBack?: () => void) => {
  // TODO #5386: Check previous history state, and if there is none, or if it's from another origin,
  // replace going back function with navigating to Home.
  const navigate = useNavigate();
  const goBack = onGoBack ? onGoBack : () => navigate(-1);
  return { goBack };
};

const NoCachedDataInline = ({ hasGoBack = false, onGoBack }: Props) => {
  const { goBack } = useGoBack(onGoBack);

  return (
    <div className="flex flex-col justify-center items-center" data-testid="noCachedDataInline">
      <Heading size="100" className="mb-4" data-testid="noCachedDataInline_title">
        You are currently offline
      </Heading>
      <Paragraph size="200">
        The data you have requested cannot be shown as it has not been cached yet.
      </Paragraph>
      <div className="flex justify-start gap-4 pt-6">
        {!!(hasGoBack || onGoBack) && (
          <RegularButton
            variant="secondary"
            size="large"
            label="Go back"
            onClick={goBack}
            data-testid="noCachedDataInline_backButton"
          />
        )}
      </div>
    </div>
  );
};

const NoCachedDataView = ({ hasGoBack = false, onGoBack }: Props) => {
  const { goBack } = useGoBack(onGoBack);

  return (
    <div className="h-screen flex justify-center items-center" data-testid="NoCachedData">
      <S2SLogo />
      <div className="w-0.5 h-7 mx-7 bg-neutral_200" role="presentation" />
      <div className="flex flex-col">
        <Heading size="100" className="mb-4" data-testid="NoCachedDataView_title">
          You are currently offline
        </Heading>
        <Paragraph size="200" className="w-96">
          The data you have requested cannot be shown as it has not been cached yet.
        </Paragraph>
        <div className="flex justify-start gap-4 pt-6">
          {!!(hasGoBack || onGoBack) && (
            <RegularButton
              variant="secondary"
              size="large"
              label="Go back"
              onClick={goBack}
              data-testid="NoCachedDataView_backButton"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const NoCachedData = ({ displayMode = "view", ...rest }: Props) => {
  switch (displayMode) {
    case "view":
      return <NoCachedDataView {...rest} />;
    case "inline":
      return <NoCachedDataInline {...rest} />;
    default:
      return null;
  }
};
