import CloseOutlined from "@mui/icons-material/CloseOutlined";
import { Fragment } from "react";

import { getAttachmentDownloadUrl, getAttachmentInlineUrl } from "@web/attachments";
import { Heading, MuiIcon, Paragraph, RegularAnchor, RegularButton } from "@web/ui";

import { AttentionInfoItemType, AttentionInfoItems } from "src/state/models";

type Props = {
  items: AttentionInfoItems;
  onClose: () => void;
};

const attentionInfoItemTypeToTitle: Readonly<{
  [key in AttentionInfoItemType]: string;
}> = {
  DELIVERY_CHARGES: "Delivery Charges",
  ORDER_EXTRA_ITEMS_TERMS: "You have added unpriced extra items to your basket",
};

const attentionInfoItemTypeToDescription: Readonly<{
  [key in AttentionInfoItemType]: string;
}> = {
  DELIVERY_CHARGES:
    "You can download the excel file to get a closer view of the delivery charges table.",
  ORDER_EXTRA_ITEMS_TERMS:
    "Please be aware that this could increase order processing time and trigger a quoting process.",
};

const attentionInfoItemTypeToButtonLabel: Readonly<{
  [key in AttentionInfoItemType]: string;
}> = {
  DELIVERY_CHARGES: "Download Delivery Charges Excel",
  ORDER_EXTRA_ITEMS_TERMS: "",
};

export const AttentionInfoModal = ({ items, onClose }: Props) => {
  return (
    <div
      className="inline-block bg-neutral_0 rounded-lg text-left shadow-xl transform max-w-2xl w-full align-middle p-5"
      data-testid="attentionInfoModal"
    >
      <div className="absolute top-0 right-0 pt-5 px-5">
        <button
          type="button"
          className="bg-neutral_0 rounded-md text-textIcon-whiteDisabled hover:text-textIcon-blackSecondary"
          onClick={onClose}
          data-testid="attentionInfoModal_closeButton"
        >
          <span className="sr-only">Close modal</span>
          <MuiIcon
            Icon={CloseOutlined}
            className="w-5 text-textIcon-whiteDisabled"
            aria-hidden="true"
          />
        </button>
      </div>
      <Heading size="200">Attention Info</Heading>
      <Paragraph size="100" className="mt-2">
        Please pay attention to the following information
      </Paragraph>
      <hr className="mt-4" />

      <div className="py-2 mt-2" data-testid="attentionInfoModal_content">
        {items.map((item) => (
          <div key={item.type} className="flex flex-col gap-3">
            <Heading size="300">{attentionInfoItemTypeToTitle[item.type]}</Heading>
            <Paragraph size="100">{attentionInfoItemTypeToDescription[item.type]}</Paragraph>
            {item.entries.map((entry, index) => (
              <Fragment key={index}>
                {entry.imageMetadata && (
                  <img
                    src={getAttachmentInlineUrl(entry.imageMetadata.attachmentId)}
                    className="inline-block object-contain max-h-20"
                  />
                )}
                {entry.fileMetadata && (
                  <RegularAnchor
                    variant="primary"
                    size="large"
                    width="content"
                    label={attentionInfoItemTypeToButtonLabel[item.type]}
                    href={getAttachmentDownloadUrl(entry.fileMetadata.attachmentId)}
                    target="_blank"
                    download={entry.fileMetadata.name}
                  />
                )}
              </Fragment>
            ))}
          </div>
        ))}
      </div>

      <div className="flex justify-end mt-6">
        <RegularButton
          variant="secondary"
          size="large"
          label="Close"
          onClick={onClose}
          data-testid="attentionInfoModal_cancelButton"
        />
      </div>
    </div>
  );
};
