import { useFlag } from "@unleash/proxy-client-react";
import { useEffect, useState } from "react";

import { OrderRequisition } from "@web/common/network/model";

import { CheckItemsAvailabilityModal } from "src/components/Modal";
import { useNetworkDetector } from "src/contexts/NetworkDetector";
import { useAvailableItemsQuery } from "src/hooks/catalog/useAvailableItemsQuery";
import {
  useOfflineCatalogQuery,
  useOfflineCatalogQueryHelpers,
} from "src/hooks/useOfflineCatalogQuery";
import { LiteProductAvailabilityCheckItemInfo } from "src/typegens";

interface Props {
  draft: OrderRequisition;
}

export const CheckItemsAvailabilityModalController = ({ draft }: Props) => {
  const isMissingItemsReplacementsEnabled = useFlag("va-missing-items-replacements");
  const { becameOnline, wentOfflineFromOnline } = useNetworkDetector();
  const [wereQueriesInvalidated, setWereQueriesInvalidated] = useState<boolean>(false);

  const itemsToCheck: LiteProductAvailabilityCheckItemInfo[] = draft.items.map((item) => ({
    variantId: item.variantId,
    supplierItemId: item.skuDetails.supplierInformation.supplierItemId,
    buyerItemId: item.skuDetails.buyerItemId,
    productName: item.name,
  }));

  const { invalidate: invalidateOfflineCatalogQuery } = useOfflineCatalogQueryHelpers({
    portId: draft.port.id,
    orderType: draft.orderType,
    supplierId: draft.supplierInformation.id,
    categoryId: undefined,
  });

  const offlineCatalogQuery = useOfflineCatalogQuery({
    portId: draft.port.id,
    orderType: draft.orderType,
    supplierId: draft.supplierInformation.id,
  });

  const { query: availableItemsQuery, invalidate: invalidateAvailableItemsQuery } =
    useAvailableItemsQuery({
      items: itemsToCheck,
      portId: draft.port.id,
      orderType: draft.orderType,
      supplierId: draft.supplierInformation.id,
      disabled: offlineCatalogQuery.isFetching,
    });

  useEffect(() => {
    // Refetch when app gets online from offline state, but only once per "becameOnline" event
    // This is to satisfy AC 3.b.ii from #12861
    if (
      isMissingItemsReplacementsEnabled &&
      becameOnline &&
      !wereQueriesInvalidated &&
      (availableItemsQuery.data?.availableItems.length ?? 0) < draft.items.length
    ) {
      setWereQueriesInvalidated(true);
      invalidateOfflineCatalogQuery().then(() => invalidateAvailableItemsQuery());
    }
  }, [
    availableItemsQuery.data?.availableItems.length,
    becameOnline,
    draft.items.length,
    invalidateAvailableItemsQuery,
    invalidateOfflineCatalogQuery,
    isMissingItemsReplacementsEnabled,
    wereQueriesInvalidated,
  ]);

  useEffect(() => {
    if (wentOfflineFromOnline) {
      setWereQueriesInvalidated(false);
    }
  }, [wentOfflineFromOnline]);

  return (
    <CheckItemsAvailabilityModal
      draft={draft}
      availableItemsQuery={availableItemsQuery}
      isDownloadingOfflineCatalog={offlineCatalogQuery.isFetching}
    />
  );
};
