import classnames from "classnames";
import { ReactNode, memo } from "react";

import { Paragraph } from "@web/ui";
import { formatNumber, isDefined } from "@web/utils";

type Props<T> = {
  product: T;
  lineNumber?: number;
  hasLineNumberPlaceholder?: boolean;
  renderProductDetails: (product: T, className: string) => ReactNode;
  renderEntityQuantityField: () => ReactNode;
  renderEntityQuantityWarning: () => ReactNode;
  renderRobValueDetails: () => ReactNode;
  renderFormErrors: () => ReactNode;
};

function _StocktakeFormProductTile<T>({
  product,
  lineNumber,
  hasLineNumberPlaceholder = false,
  renderProductDetails,
  renderEntityQuantityField,
  renderEntityQuantityWarning,
  renderRobValueDetails,
  renderFormErrors,
}: Props<T>) {
  return (
    <div>
      <div className="flex justify-between items-center">
        {isDefined(lineNumber) && (
          <Paragraph size="200" className="flex-none w-6.5 text-center">
            {formatNumber(lineNumber)}
          </Paragraph>
        )}
        <div
          className={classnames(
            "flex-auto flex flex-col xl:flex-row justify-between items-center px-4 py-2 gap-2 xl:gap-4 bg-neutral_0 border border-neutral_300 rounded-xl",
            { "ml-6.5": !isDefined(lineNumber) && hasLineNumberPlaceholder }
          )}
        >
          {renderProductDetails(product, "self-stretch")}
          <div
            className="flex-none self-stretch border-t-1 border-neutral_300 xl:hidden"
            role="presentation"
          ></div>
          <div className="self-stretch flex-none flex justify-end items-center gap-4">
            <div
              className="flex-none self-stretch border-r-1 border-neutral_300 hidden xl:block"
              role="presentation"
            ></div>
            <div className="flex-none flex flex-col justify-end items-center gap-1">
              <div className="flex items-center gap-1">
                <Paragraph size="200" weight="heavy" color="text-textIcon-blackSecondary">
                  ROB:
                </Paragraph>
                <div className="w-11">{renderEntityQuantityField()}</div>
              </div>
              {renderEntityQuantityWarning()}
            </div>
            <div
              className="flex-none self-stretch border-r-1 border-neutral_300"
              role="presentation"
            ></div>
            <div className="flex-none flex items-center flex-wrap w-[150px] gap-1 overflow-clip">
              {renderRobValueDetails()}
            </div>
          </div>
        </div>
      </div>
      {renderFormErrors()}
    </div>
  );
}
export const StocktakeFormProductTile = memo(
  _StocktakeFormProductTile
) as typeof _StocktakeFormProductTile & {
  displayName: string;
};
StocktakeFormProductTile.displayName = "StocktakeFormProductTile";
