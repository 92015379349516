// TODO #11548: Add unit tests

/**
 * @example
 * deleteIdb('db-name');
 */
export const deleteIdb = (storeName: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    indexedDB
      .databases()
      .then((dbs) => {
        const dbToRemove = dbs.find((db) => db.name === storeName);
        if (!dbToRemove) {
          resolve();
        }

        const connection = indexedDB.deleteDatabase(storeName);
        connection.onsuccess = () => {
          resolve();
        };
        connection.onerror = (event) => reject(event);
      })
      .catch((err) => reject(err));
  });
};
